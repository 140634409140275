
export default {
  props: {
    perPage: {
      type: Number,
      required: false,
      default: 10,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    ariaControls: {
      type: String,
      required: false,
      default: '',
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    limit: {
      type: [Number, String],
      required: false,
      default: 10,
    },
    stayLastPage: {
      type: Boolean,
      required: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    hideDblButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      iconScale: '0.8',
    };
  },
  computed: {
    visibleLimit() {
      return Number(this.limit) - 1;
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    visiblePages() {
      const pages = [];
      if (this.totalPages > 0) {
        let minPage = 1;
        if (this.currentPage > 1) {
          if (this.currentPage - this.visibleLimit / 2 > 0) {
            minPage = Math.ceil(this.currentPage - this.visibleLimit / 2);
          }
        }
        if (this.currentPage + this.visibleLimit > this.totalPages) {
          minPage = this.totalPages - this.visibleLimit;
        }
        if (minPage < 1) {
          minPage = 1;
        }
        const maxPage = minPage + this.visibleLimit < this.totalPages ? minPage + this.visibleLimit : this.totalPages;

        for (let page = minPage; page <= maxPage; page += 1) {
          pages.push(page);
        }
      }
      return pages;
    },
    showFirstPage() {
      return this.visiblePages[0] > 1;
    },
    showLastPage() {
      return [...this.visiblePages].pop() < this.totalPages;
    },
    lastPage() {
      return Math.ceil(this.totalRows / this.perPage);
    },
  },
  watch: {
    page(val) {
      this.currentPage = val;
    },
    currentPage(val) {
      this.$emit('change-page', this.currentPage);
    },
  },
  mounted() {
    if (this.page && this.page !== 1) this.currentPage = this.page;
  },
  methods: {
    onClickPrevLimit() {
      if (this.currentPage - Number(this.limit) > 0) {
        this.currentPage -= Number(this.limit);
      } else {
        this.currentPage = 1;
      }
    },
    onClickPrev() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    onClickNext() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    onClickNextLimit() {
      if (this.currentPage + Number(this.limit) <= this.totalPages) {
        this.currentPage += Number(this.limit);
      } else {
        this.currentPage = this.totalPages;
      }
    },
  },
};
