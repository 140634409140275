export default {
  language: {
    ko: '한국어',
    en: '영어',
  },
  //공통
  pri: 'LG전자 PRI 제조AI솔루션팀',
  // 컴포넌트 markup-loading <<>>
  loading: '데이터를 불러오고 있습니다.',

  // 공통 - 숫자 관련
  count: '개',
  num: '장',

  // 공통 - breadcrumb
  breadcrumb: {
    top: '최상위폴더',
  },

  // flashmessage
  fm: {
    nameValidator: {
      title: '입력이 불가능한 문자입니다.',
      msg: "0~9, 알파벳 대소문자, '_' 또는 '-'만 입력 가능합니다.",
      // 추가
      moreMsg: "0~9, 알파벳 대소문자, '@', '_' 또는 '.'만 입력 가능합니다.",
    },
    validateFolderName: '입력할 수 없는 폴더 이름입니다. 다른 폴더 이름을 입력해주세요.',
    duplicateFolderName: '이미 존재하는 폴더 이름입니다. 다른 폴더 이름을 입력해주세요.',
  },
  // 상단바
  noti: '공지사항',
  csCenter: '고객센터',
  // 사이드 하단
  privacy: '개인정보처리방침',
  // auth/login <<>> --
  login: {
    title: 'AI 이미지 검사 플랫폼',
    subTitle: 'AI 비전문가도 쉽게 학습 가능한 클라우드기반의 AI 비전검사 플랫폼',
    id: '아이디',
    inputId: '아이디를 입력해주세요.',
    pw: '비밀번호',
    inputPw: '비밀번호를 입력해주세요.',
    login: '로그인',
    rememberId: '아이디 저장',
    newId: '신규 ID 신청방법',
    forgetPw: '비밀번호를 잊으셨나요?',
    resetPw: '비밀번호 초기화',
    resetPwMsg: '관리자에게 비밀번호 초기화를 요청했습니다.',
    resetPwInfo: ' 비밀번호 초기화 요청 필요시, ',
    resetPwInfo2: 'PRI-AIT-Platform@lge.com으로 문의 부탁드립니다.',
    pwAlarm: '비밀번호 안내',
    pwAlarmMsg1: '관리자에 의해 비밀번호가 초기화 되었습니다.',
    pwAlarmMsg2: '비밀번호 변경 페이지로 이동합니다.',
    pwAlarmMsg3: '최근 3개월 동안 비밀번호를 변경하지 않았습니다.',
    pwAlarmMsg4: '새로운 비밀번호로 변경해 주세요.',
    info: 'MAVIN-Cloud는 크롬(Chrome) 웹브라우저에 최적화되어있습니다.',
    info2: 'Chrome 웹브라우저가 없으신 경우, Chrome을 설치하신 후 사용 부탁드립니다.',
    fm: {
      id: '아이디를 입력해 주세요',
      pw: '비밀번호를 입력해 주세요',
    },
    changePw: '비밀번호를 변경해주세요.',
  },
  // index  <<>>
  home: {
    noIncluded: '참여된 프로젝트가 없습니다.',
    askAdmin: '관리자에게 문의해주세요.',
    noPermission: '선택한 프로젝트에 대한 권한이 없습니다.',
    aiModel: 'AI 모형학습',
    aiField: 'AI 현장검사',
    serviceAdmin: '서비스 관리자',
    error: {
      projectDeniedTitle: '프로젝트 사용 기간이 만료되어 접근할 수 없습니다.',
      projectDeniedContent: '기간 연장을 원하시면 pri-aiplatform-cs@lge.com 으로 연락 부탁드립니다.',
    },
  },

  // /train --
  dataset: '데이터 관리',
  processing: '데이터 가공',
  learning: '학습 수행',
  inference: '성능 평가',
  line: '검사 현황',
  lineReport: '검사 리포트',
  inspection: '성능 검수',
  inspectReport: '성능 리포트',
  modelHistory: '모형 이력',

  // /train/dataset 데이터관리 <<>> --
  td: {
    breadCrumbTop: '최상위폴더',
    breadCrumbCurr: '데이터가공',
    folder: '폴더',
    noFolder: '폴더가 없습니다.',
    file: '파일',
    noFile: '파일이 없습니다.',
    placeHolderFile: '파일 이름',
    search: '검색',
    learning: '학습',
    upload: '파일 업로드',
    downloadFile: '파일 다운로드',
    fileDownloadList: '파일 다운로드 목록',
    finalChanged: '최종 변경 일시',
    inputImage: '입력 이미지',
    renameFolder: '폴더 수정',
    remove: '삭제',
    removeFolder: '폴더 삭제',
    downloadFolder: '폴더 다운로드',
    download: '다운로드',
    condition: {
      name: '이름',
      date: '날짜',
      asc: '오름차순',
      desc: '내림차순',
    },
    lastUploadedDate: '최종 업로드 일시',
    uploadFail: 'Dataset 업로드 실패',
    confirmRemove: '{name} 폴더를 삭제하시겠습니까?',
    uploadFailMsg: '전체 {total}개 중 {count}개의 파일이 업로드에 실패했습니다.',
    nthFile: '{index}번째 파일',
    checkImage: '이미지 보기',
    // 추가
    items: '{count}개', // items
    selectedImages: '선택한 이미지 {count}개',
    deselectAll: '전체 선택 해제',
  },
  // 데이터관리 flashmessage <<>> --
  tdFm: {
    fileUploadSuc: '파일 업로드가 완료되었습니다',
    chooseDownload: '다운로드할 폴더 또는 파일을 선택해주세요.',
    chooseDownloadFolder: '다운로드할 폴더를 선택해주세요.',
    chooseDownloadFile: '다운로드할 파일을 선택해주세요.',
    selectedDownload: {
      title: '다운로드 요청 성공',
      msg: '선택한 파일 및 폴더의 다운로드가 요청되었습니다.',
    },
    validate: {
      renameTitle: '수정 불가',
      removetitle: '삭제 불가',
      renameMsg: '수정할 수 없는 폴더입니다.',
      removeMsg: '삭제할 수 없는 폴더입니다.',
    },
    renameSuc: '폴더명이 변경되었습니다.',
    removeSuc: '폴더가 삭제되었습니다.',
  },
  // /train/processing <<>> --
  tp: {
    externalLabeling: '외부 Labeling 작업 업로드',
    newLabeling: '신규 Labeling',
    aiLabeling: 'AI 라벨링',
    checkCond: '조건 보기',
    inputError: '이미지들의 가로/세로 크기가 모두 동일한 데이터셋을 사용해야 합니다.',
    working: '현재 작업중',
    myWorking: '내가 작업중',
    aiWaiting: 'AI 라벨링 대기중',
    hasHistory: '사용 이력 존재',
    workable: '작업 가능',
    result: '결과보기',
    checkResult: '결과보기',
    aug: '증강',
    augMsg: '데이터를 증강할 수 있습니다.',
    copyWork: '가공 작업 복사',
    mergeWork: '가공 작업 병합',
    removeData: '데이터 삭제',
    remove: '삭제',
    confirmRemove: '선택한 데이터를 삭제하시겠습니까?',
    fields: {
      typeProcessing: '가공 유형',
      typeWork: '작업 유형',
      usageTypeId: '용도',
      updatedAt: '작업일',
      title: 'Work 명',
      status: '진행상태',
      inputLabelings: '사용 데이터 경로',
      result: '결과 보기',
    },
    workType: 'Work 유형',
    copying: '복사 중입니다.',
    moving: '진입 중입니다.',
    nowWorking: '작업 진행 중입니다.',
    fm: {
      waiting: '생성 중인 데이터 가공 건입니다.',
      progressing: '저장 중인 데이터 가공 건입니다.',
      error: 'Error 상태의 데이터 가공 건입니다.',
      busyUser: '{name}이(가) 작업 중입니다.',
      descSuc: 'Description이 변경되었습니다.',
      historyWarning: '학습 혹은 성능평가에 사용된 이력이 있는 데이터 가공은 삭제할 수 없습니다.',
      progressWarning: '진행 중인 AI라벨링입니다. 진행이 완료된 후 다시 시도해주세요.',
      remove: '삭제되었습니다.',
      // 추가
      selectData: '다운로드할 Work 명을 선택해주세요',
      downloadTitle: '다운로드 요청 성공',
      downloadMsg: '선택한 파일 및 폴더의 다운로드가 요청되었습니다.',
    },
    // 추가
    labelingDownload: '라벨링 정보 파일 다운로드',
    classDownload: '클래스 단위 파일 다운로드',
  },
  // /train/processing/add/new, index <<>> --
  tpAdd: {
    info: 'Labeling 작업 기본 정보',
    processType: '가공 유형',
    workType: '작업 유형',
    workName: '작업 이름',
    dataset: '데이터 선택',
    chooseData: '데이터를 선택하세요.',
    sampleImage: '샘플 이미지 보기',
    folderImageCnt: '폴더 내 이미지 개수 {count}개',
    resultFolderName: 'Labeling 결과 저장 폴더이름',
    sameWorkName: '작업이름과 동일하게 설정',
    folderName: '폴더 이름',
    mergeList: '병합할 데이터 목록',
    chooseMerge: '작업데이터 선택',
    chooseMergeData: '병합할 데이터를 선택하세요',
    classInfo: 'Class 정보',
    classInput: 'Class 정보 입력',
    model: '학습모형',
    chooseModel: '학습모형을 선택하세요',
    textarea: '메모 작성',
    setDetail: '상세 설정',
    inferenceParams: '추론 파라미터',
    runLabeling: 'Labeling 수행',
    runningLabeling: 'Labeling을 수행중입니다.',
    confirmLabeling: '입력하신 정보로 Labeling 작업을 진행하시겠습니까?',
    breadcrumb: {
      first: '신규 Labeling',
      second: '작업 정보 입력',
    },
    fm: {
      trialWarning: 'Trial 계정에서는 사용하실 수 없는 기능입니다.',
      chooseName: '작업 이름을 입력해주세요.',
      workNameLimit: '작업 이름은 30자 이내로 입력해주세요.',
      chooseProcessType: '가공 유형을 선택해주세요.',
      chooseWorkType: '작업 유형을 선택해주세요.',
      chooseMerge: '두개 이상의 병합할 데이터를 선택해주세요',
      chooseData: '데이터를 선택해주세요.',
      chooseModel: '학습 모형을 선택해주세요.',
      inputResultName: 'Labeling 결과 저장 폴더 이름을 입력해주세요.',
      startMerge: '데이터 병합이 시작되었습니다.',
      processSuc: '데이터 가공이 생성되었습니다.',
      moreClass: '2개 이상의 Class 정보를 추가해주세요.',
      addClass: 'Class 정보를 추가해주세요.',
      checkedOk: 'OK여부가 체크된 Class가 최소 1개 이상 필요합니다.',
      uncheckedOk: 'OK여부가 체크 해제된 Class가 최소 1개 이상 필요합니다.',
    },
  },
  // /train/processing/add/ai <<>> --
  tpAddAi: {
    info: 'Labeling 작업 기본 정보',
    processType: '가공 유형',
    manualDownload: '비지도 AI 라벨링 사용 매뉴얼을 다운로드 합니다.',
    manualName: '매뉴얼_v1_230828',
    workType: '작업 유형',
    workName: '작업 이름',
    dataset: '데이터 선택',
    chooseData: '데이터를 선택하세요.',
    sampleImage: '샘플 이미지 보기',
    folderImageCnt: '폴더 내 이미지 개수 {num}개',
    resultFolderName: 'Labeling 결과 저장 폴더이름',
    sameWorkName: '작업이름과 동일하게 설정',
    folderName: '폴더 이름',
    algorithmsInfo: '알고리즘 정보',
    model: '학습모형',
    chooseModel: '학습모형을 선택하세요',
    classInfo: 'Class 정보',
    setDetail: '상세 설정',
    inferenceParams: '추론 파라미터',
    selectAlgorithms: '알고리즘 선택',
    algorithmDesc: '알고리즘 설명 보기',
    trainParams: '학습 파라미터',
    augParams: 'augmentation 설정',
    textarea: '메모 작성',
    runLabeling: 'Labeling 수행',
    runningLabeling: 'Labeling을 수행중입니다.',
    confirmLabeling: '입력하신 정보로 Labeling 작업을 진행하시겠습니까?',
    confirmClassCnt: 'Class 수 확인',
    confirmClassCntMsg: 'Class 수가 {numCluster}로 설정되었습니다. 그대로 Labeling을 실행하시겠습니까?',
    confirmClassCntCf: '[참고]',
    confirmClassCntDesc1: 'Class 수 : 분류하고자 하는 Class의 개수',
    confirmClassCntDesc2: '단순 OK/NG 분류라고 2를 입력하는 것이 아니며, 이미지 유형의 개수를 입력해야 하는 것에 유의',
    confirmClassCntDesc3: '2 ~ 34 사이의 정수만 입력 가능',
    back: '뒤로',
    save: '저장',
    chooseAlgorithms: '알고리즘을 선택하세요',
    breadCrumb: '작업 정보 입력',
    labelingType: {
      pretrained: '지도 학습(Pre-trained 모델 사용)',
      unsupervised: '비지도 학습',
    },
    fm: {
      trialWarning: 'Trial 계정에서는 사용하실 수 없는 기능입니다.',
      chooseWorkType: '작업 유형을 선택해주세요.',
      chooseData: '데이터를 선택해주세요.',
      chooseProcessType: '가공 유형을 선택해주세요.',
      chooseName: '작업 이름을 입력해주세요.',
      inputResultName: 'Labeling 결과 저장 폴더 이름을 입력해주세요.',
      chooseModel: '학습 모형을 선택해주세요.',
      chooseAlgorithms: '알고리즘을 선택해주세요.',
      inputParams: '{item} 파라미터 값을 입력해주세요.',
      inputParamsScoped: '{item} 파라미터 값을 범위에 맞게 입력해주세요.',
      noAlgorithmsImage: '알고리즘 설명 이미지가 없습니다.',
      addTarget: 'Target Region을 추가해주세요.',
      processSuc: '데이터 가공이 생성되었습니다.',
      // 추가
      minImage: '수동 Labeling 이미지는 최소 100장 이상이여야 합니다.',
      min: '100장 미만의 수동 Labeling 이미지는 사용불가합니다.',
      selectStdImgFolder: '기준 이미지 폴더를 선택해주세요.',
      selectStdImg: '기준 이미지를 선택해주세요.',
      inputPatchBbox: '모든 이미지에 최소 1개 이상의 클래스 축가 및 추가한 모든 클래스에 Patch, Bbox를 입력해주세요.',
    },
    // 추가2
    existingModel: '기존 모델 사용',
    useExisted: '사용자가 기존에 생성한 모델을 사용하여 데이터 라벨링을 진행합니다.',
    algorithm: '알고리즘 선택',
    success: '성공 {count}개',
    fail: '실패 {count}개',
    manualLabeling: '수동 Labeling 수행',
    stdLabeling: '기준 이미지 Labeling 수행',
    aiLabeling: 'AI Labeling 진행',
    manualProcess: '수동 Labeling 진행',
    manualRatio: '수동 Labeling 비율',
    manualRatioDesc:
      'AI Labeling에 사용될 모델을 학습시키기 위해 사용자가 직접 Labeling하는 데이터셋의 비율입니다.(랜덤)',
    uploadStdImage: '기준 이미지 업로드',
    existingStd: '기존 이미지 사용',
    selectExStd: '기존 기준 이미지 선택',
    uploadFile: '파일을 업로드하세요.',
    fileNameValidator: '파일명에 한글과 특수문자(_ 제외)가 있는 경우, 해당 문자들은 생략되어 업로드됩니다.',
    images: '장',
  },
  // /train/processing/add/augmentation <<>> --
  tpAddAug: {
    info: 'Labeling 작업 기본 정보',
    workName: '작업 이름',
    resultFolderName: '데이터 증강 결과 저장 폴더 이름',
    sameWorkName: '작업이름과 동일하게 설정',
    folderName: '폴더 이름',
    chooseData: '데이터를 선택하세요.',
    targetData: '대상 데이터',
    dataset: '데이터 선택',
    sampleImage: '샘플 이미지 보기',
    type: '유형',
    augMethod: '증강 비율 및 증강 생성 기법 설정',
    augAll: '일괄 적용',
    originCnt: '원본 {count}장',
    totalCnt: '증강 결과 총 {count}장',
    augRatio: '증강 비율',
    imageAugMethod: '이미지 증강 생성 기법',
    previewAug: '증강 이미지 미리보기',
    preview: '미리보기',
    originImage: '원본 이미지',
    resultImage: '증강 결과 이미지',
    noPreviewImage: '표시할 미리보기 결과가 없습니다.',
    textarea: '메모 작성',
    runAug: '증강 실행',
    runLabeling: 'Labeling 수행',
    runningLabeling: 'Labeling을 수행중입니다.',
    confirmLabeling: '입력하신 정보로 Labeling 작업을 진행하시겠습니까?',
    resultFolderName: 'Labeling 결과 저장 폴더이름',
    breadCrumb: {
      aug: '데이터 증강',
      info: '작업 정보 입력',
    },
    fm: {
      chooseName: '작업 이름을 입력해주세요.',
      inputFolderName: '데이터 증강 결과 저장 폴더 이름을 입력해주세요.',
      chooseData: '대상 데이터를 선택해주세요.',
      augSuccess: '데이터 증강이 생성되었습니다.',
    },
    // 추가
    count: '{count}개',
  },
  // /train/processing/_id/cf <<>> --
  tpIdCf: {
    autoSaving: '자동 저장 중...',
    lastSavedTime: '마지막 저장',
    extendWork: '작업 시간 연장',
    saveAndOut: '저장 후 나가기',
    labelingResult: '결과 확인',
    save: 'Labeling 결과 최종 저장',
    list: '목록 보기',
    saveLabeling: 'Labeling 저장',
    saveLabelingDesc1: '진행하신 Labeling으로 폴더별 저장을 실행하시겠습니까?',
    saveLabelingDesc2: '이후 Labeling 수정이 불가능 하며 라벨링 복사를 이용해주세요.',
    saveLabelingDesc3: '분류 시 원본 파일이 이동되며 작업 취소가 불가능 합니다.',
    saveUsage: '{name}으로 저장',
    confirmMsg1: '1분 뒤 작업이 자동으로 종료됩니다.',
    confirmMsg2: '작업 내용을 임시 저장하고 작업을 계속 진행하시겠습니까?',
    confirmMsg3: '(응답이 없을 시 1분 뒤 작업 내용은 자동으로 임시 저장되고, 현재 작업 화면은 종료됩니다.',
    breadCrumb: {
      processing: '데이터 Labeling',
      labeling: 'Labeling 수행',
    },
    min: '분',
    sec: '초',
    fm: {
      moreClass: '2개 이상의 Class 정보를 추가해주세요.',
      addClass: 'Class 정보를 추가해주세요.',
      checkedOk: 'OK여부가 체크된 Class가 최소 1개 이상 필요합니다.',
      uncheckedOk: 'OK여부가 체크 해제된 Class가 최소 1개 이상 필요합니다.',
      categoryMinImage: 'Class 별 최소 1장의 이미지를 가져야합니다.',
      noDuplicated: '중복된 Class명은 저장할 수 없습니다.',
      chooseUsage: '데이터 가공의 용도를 선택해주세요.',
      success: '저장 되었습니다.',
      // 추가
      creating: '데이터 가공 생성이 진행 중 입니다. 잠시 후 라벨링을 시작해주세요.',
      manualMinImage: '수동 Labeling 이미지는 최소 100장 이상이여야 합니다.',
      unclassifiedWarning: '분류되지 않은 데이터가 있습니다. 분류가 완료되어야 AI 라벨링이 최종저장됩니다.',
      isNotMultiple: '모든 클래스에 최소 한 장 이상의 이미지가 있어야 합니다.',
    },
  },
  // /train/processing/_id/cf/result <<>> --
  tpIdCfResult: {
    info: '작업 기본 정보',
    workName: '작업 이름',
    workType: '작업 유형',
    updatedAt: '작업 일시',
    inputData: '분류 대상 데이터 경로',
    folderImageCnt: '폴더 내 이미지 {count}개',
    resultRoute: 'Labeling 결과 저장 경로',
    preModel: 'Pre-trained 모델',
    resultLabeling: 'Labeling 작업 결과',
    labeling: 'Labeling 비율',
    distribution: 'Class 분포',
    edit: '수정하기',
    list: '목록으로',
    breadCrumb: {
      labeling: '데이터 Labeling',
      result: 'Labeling 결과',
    },
  },
  // /train/processing/_id/od <<>> --
  tpIdOd: {
    labelingResult: '결과 확인',
    temtSave: '임시저장',
    saveAndOut: '저장 후 나가기',
    save: 'Labeling 결과 최종 저장',
    list: '목록 보기',
    saveLabeling: 'Labeling 저장',
    saveLabelingDesc1: '진행하신 Labeling으로 폴더별 저장을 실행하시겠습니까?',
    saveLabelingDesc2: '분류 시 원본 파일이 이동되며 작업 취소가 불가능 합니다.',
    saveUsage: '{usage}으로 저장',
    extendWork: '작업 시간 연장',
    confirmMsg1: '1분 뒤 작업이 자동으로 종료됩니다.',
    confirmMsg2: '작업 내용을 임시 저장하고 작업을 계속 진행하시겠습니까?',
    confirmMsg3: '(응답이 없을 시 1분 뒤 작업 내용은 자동으로 임시 저장되고, 현재 작업 화면은 종료됩니다.',
    fm: {
      tempSuc: '임시저장 되었습니다.',
      noDuplicated: '중복된 Class명은 저장할 수 없습니다.',
      chooseUsage: '데이터 가공의 용도를 선택해주세요.',
      unTrial: '해당기능은 비교성능 평가를 위한 기능으로, Trial 버전에서는 사용불가합니다.',
      success: '저장 되었습니다.',
      // 추가
      noCocoMsg: '데이터 가공 생성이 진행 중 입니다. 잠시 후 라벨링을 시작해주세요.',
      minImage: '수동 Labeling 이미지는 최소 100장 이상이여야 합니다.',
      unDrawnImages: '분류되지 않은 데이터가 있습니다. 분류가 완료되어야 AI 라벨링이 최종저장됩니다.',
    },
    breadCrumb: {
      processing: '데이터 Labeling',
      labeling: 'Labeling 수행',
    },
    min: '분',
    sec: '초',
    // 추가
    labeling: 'AI 라벨링 진행',
  },
  // /train/processing/_id/od/result <<>> --
  tpIdOdResult: {
    info: '작업 기본 정보',
    workName: '작업 이름',
    workType: '작업 유형',
    updatedAt: '작업 일시',
    inputData: '분류 대상 데이터 경로',
    folderImageCnt: '폴더 내 이미지 {count}개',
    resultRoute: 'Labeling 결과 저장 경로',
    preModel: 'Pre-trained 모델',
    resultLabeling: 'Labeling 결과',
    annotationRatio: 'Annotation 비율',
    distribution: 'Class 분포',
    annotationAvg: 'Annotation 넓이 - 평균',
    annotationDist: 'Annotation 넓이 - 분산',
    annotationMin: 'Annotation 넓이 - 최소',
    annotationMax: 'Annotation 넓이 - 최대',
    annotationCnt: 'Annotation 개수 당 이미지 개수',
    edit: '수정하기',
    list: '목록으로',
    breadCrumb: {
      labeling: '데이터 Labeling',
      result: 'Labeling 결과',
    },
    // 추가
    count: '{count}개',
    positionInfo: '라벨링 위치 정보',
  },
  // /train/learning <<>> --
  tl: {
    new: '신규 학습',
    retrain: '재학습',
    filter: {
      status: '진행 상태',
    },
    condition: '조건 보기',
    progressAlert1: '현재 진행중인 학습이 {count}건 있습니다. (이용자별 최대 3건 동시학습 가능)',
    progressAlert2: '진행 중인 학습이 완료된 후 다시 시도 해주세요.',
    cancelTrain: '학습 취소',
    removeTrain: '학습 수행 삭제',
    remove: '삭제',
    removeTrainMsg: '선택한 학습 수행을 삭제하시겠습니까?',
    trainInfo: '학습 수행 안내',
    trainInfoMsg1: '선택하신 학습 작업은 {status}상태로 학습 결과는 확인할 수 없습니다.',
    trainInfoMsg2: '학습 기본 정보 및 파라미터 설정 정보는 아래와 같습니다.',
    trainInfoDetail: {
      name: '모형 이름',
      type: '유형',
      algorithm: '알고리즘',
      createdAt: '학습 시작 일시',
      canceledAt: '학습 취소 일시',
      detail: '상세 설정',
      detailInfo: '상세 설정 정보',
    },
    cancelTrainModel: '학습 수행 취소',
    confirmCancel: '취소하기',
    no: '아니오',
    cancelTrainMsg: '선택한 학습을 취소하시겠습니까?',
    algorithm: '알고리즘',
    workType: '작업 유형',
    cancelConfirm: '진행 중인 학습을 취소하시겠습니까? 학습 취소된 모델의 정보는 불러 올 수 없게 됩니다.',
    return: '돌아가기',
    fields: {
      workType: '작업 유형',
      createdAt: '일시',
      name: '학습모형명',
      algorithm: '알고리즘',
      data: '학습데이터',
      status: '진행상태',
      options: '진행옵션',
      progress: '진행률',
    },
    fm: {
      undo: '학습 수행이 완료되지 않았습니다.',
      unremovableMsg: '진행 중인 성능 학습 수행 건은 삭제 할 수 없습니다.',
      removeSuc: '삭제되었습니다',
      descSuc: 'Description이 변경되었습니다.',
      cancel: '학습을 취소했습니다.',
      cancelErrorMsg: '취소 가능한 학습이 아닙니다. ({status})',
    },
  },
  // /train/learning/add <<>> --
  tlAdd: {
    info: '모형 학습을 위한 기본 정보',
    modelName: '학습 모형 이름',
    checkDuplicate: '중복 확인',
    notDuplicated: '사용 가능한 학습 모형 이름입니다.',
    duplicated: '이미 등록된 학습 모형 이름입니다.',
    data: '데이터 선택',
    chooseData: '데이터를 선택하세요.',
    sampleImage: '샘플 이미지 보기',
    folderImageCnt: '폴더 내 이미지 개수 {count}개',
    type: '유형',
    classCnt: 'Class 종류 ({count}개)',
    algorithmInfo: '알고리즘 정보',
    chooseAlgo: '알고리즘 선택',
    hpoToggle: '파라미터 자동 최적화 설정',
    checkAlgoInfo: '알고리즘 설명 보기',
    detail: '상세설정',
    params: '학습 파라미터',
    aug: 'augmentation 설정',
    memo: '메모 작성',
    runTrain: '학습 수행',
    loadingTrain: '모형 학습 수행을 요청중입니다.',
    progressAlert1: '현재 진행중인 학습이 {count}건 있습니다. (이용자별 최대 3건 동시학습 가능)',
    progressAlert2: '진행 중인 학습이 완료된 후 다시 시도 해주세요.',
    y: '예',
    n: '아니오',
    confirmMsg1:
      '현재 {name} 학습이 GPU 상에서 {count}건 진행되고 있어, 먼저 요청된 학습이 종료된 이후에 학습이 가능합니다.',
    confirmMsg2: '학습 요청을 하더라도, 먼저 요청된 학습이 종료될때 까지 대기시간이 길어질 수 있습니다.',
    confirmMsg3: '학습을 진행하시겠습니까?',
    trainModel: '모형 학습 수행',
    trainModelMsg1: '모형학습을 진행하시겠습니까?',
    trainModelMsg2: '학습이 진행되는 동안 다른 작업을 진행하실 수 있습니다.',
    trainModelMsg3: '학습 진행상황은 학습 Work List 페이지나, 상단의 알림기능을 통해 확인할 수 있습니다.',
    algorithmDesc: '알고리즘 설명',
    chooseAlgorithms: '알고리즘을 선택하세요',
    limitLearning: '학습 가능 횟수 {limit}회를 모두 소진하여 학습이 불가합니다.',
    overLimit: '최대 학습 횟수 초과',
    breadcrumb: {
      learning: '학습 수행',
      new: '신규 학습',
    },
    fm: {
      inputModelName: '학습 모형 이름을 입력해주세요.',
      noAlgoDesc: '알고리즘 설명 이미지가 없습니다.',
      checkDuplicate: '학습 모형 이름 중복 확인을 진행해주세요.',
      duplicate: '중복된 학습 모형 이름입니다. 다른 학습 모형 이름을 입력해주세요.',
      chooseData: '데이터를 선택해주세요.',
      chooseAlgo: '알고리즘을 선택해주세요.',
      inputRatio: '알맞은 샘플링 비율을 입력해주세요.',
      inputParams: '{name} 학습 파라미터 값을 입력해주세요.',
      inputRange: '{name} 학습 파라미터 값을 범위에 맞게 입력해주세요.',
      inputAugParams: '{name} Augmentation 파라미터 값을 입력해주세요.`',
      inputAugRange: '{name} Augmentation 파라미터 값을 범위에 맞게 입력해주세요',
      success: '학습 수행이 생성되었습니다.',
    },
  },
  // /train/learning/_id/cf <<>> --
  tlIdCf: {
    info: '모형 학습 기본 정보',
    modelName: '모형 이름',
    work: '유형',
    algorithm: '알고리즘',
    createdAt: '학습 시작 일시',
    endedAt: '학습 완료 일시',
    duration: '학습 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    validationResult: 'Validation 검증 결과',
    recommendedParameters: '추천 파라미터',
    accuracy: '정확도',
    threshold: '최적 임계값',
    classPrecision: 'Class별 Precision',
    classRecall: 'Class별 Recall',
    acGraph: 'Accuracy 그래프',
    checkOrigin: '원본보기',
    lossGraph: 'LOSS 그래프',
    stoppingNoti:
      '참고: Early Stopping 기능이 적용되어 있어 입력한 epoch보다 더 이른 시점에 학습이 종료될 수 있습니다.',
    resultSampling: '모형 학습 결과 이미지 샘플링',
    resultMsg: '원본 / 결과 이미지',
    curClass: '현재 Class',
    resampling: '샘플링 다시하기',
    origin: '원본',
    result: '결과',
    resultNoti: '학습된 모형의 성능이 낮아, 표시할 객체 검출 결과가 없습니다. 학습 성능 개선을 진행해주시기 바랍니다.',
    list: '목록으로',
    hour: '시간',
    min: '분',
    sec: '초',
    sort: {
      low: '낮음',
      mid: '중간',
      high: '높음',
    },
    breadcrumb: {
      training: '학습 수행',
      result: 'Labeling 결과',
    },
  },
  // /train/learning/_id/od <<>> --
  tlIdOd: {
    info: '모형 학습 기본 정보',
    modelName: '모형 이름',
    work: '유형',
    algorithm: '알고리즘',
    createdAt: '학습 시작 일시',
    endedAt: '학습 완료 일시',
    duration: '학습 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    validationResult: 'Validation 검증 결과',
    recommendedParameters: '추천 파라미터',
    classMAP: 'Class별 mAP',
    resultSampling: '모형 학습 결과 이미지 샘플링',
    resultMsg: '원본 / 결과 이미지',
    curClass: '현재 Class',
    resampling: '샘플링 다시하기',
    origin: '원본',
    result: '결과',
    resultNoti: '학습된 모형의 성능이 낮아, 표시할 객체 검출 결과가 없습니다. 학습 성능 개선을 진행해주시기 바랍니다.',
    list: '목록으로',
    sort: {
      asc: '오름차순',
      desc: '내림차순',
    },
    breadcrumb: {
      training: '학습 수행',
      result: '학습 수행 결과',
    },
  },
  // /train/learning/_id/sg <<>> --
  tlIdSg: {
    info: '모형 학습 기본 정보',
    modelName: '모형 이름',
    work: '유형',
    algorithm: '알고리즘',
    createdAt: '학습 시작 일시',
    endedAt: '학습 완료 일시',
    duration: '학습 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    validationResult: 'Validation 검증 결과',
    recommendedParameters: '추천 파라미터',
    classMAP: 'Class별 mAP',
    resultSampling: '모형 학습 결과 이미지 샘플링',
    resultMsg: '원본 / 결과 이미지',
    curClass: '현재 Class',
    resampling: '샘플링 다시하기',
    origin: '원본',
    result: '결과',
    resultNoti: '학습된 모형의 성능이 낮아, 표시할 객체 검출 결과가 없습니다. 학습 성능 개선을 진행해주시기 바랍니다.',
    list: '목록으로',
    sort: {
      asc: '오름차순',
      desc: '내림차순',
    },
    breadcrumb: {
      training: '학습 수행',
      result: '학습 수행 결과',
    },
  },
  // /train/learning/relearning <<>> --
  tlRelearning: {
    info: '모형 학습을 위한 기본 정보',
    modelName: '학습 모형 이름',
    checkDuplicate: '중복 확인',
    notDuplicated: '사용 가능한 학습 모형 이름입니다.',
    duplicated: '이미 등록된 학습 모형 이름입니다.',
    trainingModel: '학습모형',
    chooseModel: '학습모형을 선택하세요.',
    detail: '상세설정',
    params: '학습 파라미터',
    aug: 'augmentation 설정',
    data: '데이터 선택',
    chooseData: '데이터를 선택하세요.',
    sampleImage: '샘플 이미지 보기',
    folderImageCnt: '폴더 내 이미지 개수 {count}개',
    type: '유형',
    classCnt: 'Class 종류 ({count}개)',
    memo: '메모 작성',
    runRetrain: '재학습 수행',
    loadingTrain: '모형 학습 수행을 요청중입니다.',
    progressAlert1: '현재 진행중인 학습이 {count}건 있습니다. (이용자별 최대 3건 동시학습 가능)',
    progressAlert2: '진행 중인 학습이 완료된 후 다시 시도 해주세요.',
    y: '예',
    n: '아니오',
    confirmMsg1:
      '현재 {name} 학습이 GPU 상에서 {count}건 진행되고 있어, 먼저 요청된 학습이 종료된 이후에 학습이 가능합니다.',
    confirmMsg2: '학습 요청을 하더라도, 먼저 요청된 학습이 종료될때 까지 대기시간이 길어질 수 있습니다.',
    confirmMsg3: '학습을 진행하시겠습니까?',
    trainModel: '모형 학습 수행',
    trainModelMsg1: '모형학습을 진행하시겠습니까?',
    trainModelMsg2: '학습이 진행되는 동안 다른 작업을 진행하실 수 있습니다.',
    trainModelMsg3: '학습 진행상황은 학습 Work List 페이지나, 상단의 알림기능을 통해 확인할 수 있습니다.',
    breadcrumb: {
      retraining: '재학습',
      info: '기본 정보 입력',
    },
    fm: {
      inputModelName: '학습 모형 이름을 입력해주세요.',
      chooseModel: '재학습할 학습 모형을 선택해주세요.',
      checkDuplicate: '학습 모형 이름 중복 확인을 진행해주세요.',
      duplicate: '중복된 학습 모형 이름입니다. 다른 학습 모형 이름을 입력해주세요.',
      chooseData: '데이터를 선택해주세요.',
      inputRatio: '알맞은 샘플링 비율을 입력해주세요.',
      success: '재학습 수행이 생성되었습니다.',
    },
  },
  // /train/inference <<>> --
  ti: {
    new: '신규 평가',
    single: '단일 성능 평가',
    compare: '비교 성능 평가',
    condition: '조건 보기',
    status: {
      curr: '현재 작업중',
      me: '내가 사용중',
      ok: '사용 가능',
    },
    reinspect: '재검수하기',
    inspect: '검수하기',
    result: '검수결과',
    inspectResult: '검수 결과',
    removeInfer: '성능 평가 삭제',
    remove: '삭제',
    confirmRemoveInfer: '선택한 성능 평가를 삭제하시겠습니까?',
    fields: {
      workType: '작업 유형',
      updatedAt: '일시',
      title: '검사명',
      model: '적용 모형',
      algorithm: '알고리즘',
      image: '이미지',
      status: '진행상태',
      modelAccuracy: '모형 성능',
      busy: '사용 현황',
      inspection: '검수하기',
      result: '검수결과',
    },
    fm: {
      trialWarning: '해당기능은 Trial 버전에서는 사용불가합니다.',
      busyWarning: '{name}이(가) 작업 중입니다.',
      changeDescSuc: 'Description이 변경되었습니다.',
      progressWarning: '진행 중인 성능 평가건은 삭제 할 수 없습니다.',
      removeSuc: '삭제되었습니다',
    },
    // 추가
    count: '{count}개',
  },
  // /train/inferecne/add <<>> --
  tiAdd: {
    info: '성능 평가 기본 정보',
    name: '검사 이름',
    data: '데이터 선택',
    chooseData: '데이터를 선택하세요.',
    useLabeledData: '라벨링 된 데이터',
    sampleImage: '샘플 이미지 보기',
    folderImageCnt: '폴더 내 이미지 개수 {count}개',
    workType: '작업 유형',
    model: '학습모형',
    inputModel: '학습모형을 선택하세요.',
    chooseModal: '학습모형 선택',
    class: 'Class 종류',
    memo: '메모 작성',
    detail: '상세 설정',
    inferParams: '추론 파라미터',
    infer: '성능 평가 수행',
    requestInfer: '성능 평가 수행을 요청중입니다.',
    confirmInfer: '입력하신 정보로 성능 평가를 수행하시겠습니까?',
    confirmMsg1: '현재 {name} 추론이 GPU 상에서 진행되고 있어, 먼저 요청된 추론이 종료된 이후에 추론이 가능합니다.',
    confirmMsg2: '추론 요청을 하더라도 대기 시간이 발생할 수 있습니다.',
    breadcrumb: {
      infer: '성능 평가',
      new: '신규 평가',
    },
    fm: {
      chooseData: '데이터를 선택해주세요.',
      chooseWork: '작업 유형을 선택해주세요.',
      inputName: '검사 이름을 입력해주세요.',
      chooseModel: '학습 모형을 선택해주세요.',
      success: '신규 성능 평가가 생성되었습니다.',
    },
    // 추가
    count: '{count}개',
  },
  // /train/inferecne/_id/ <<>> --
  tiId: {
    info: '성능 평가 기본 정보',
    name: '검사 이름',
    type: '유형',
    modelAlgo: '학습 모형 / 알고리즘',
    user: '생성 User',
    inspectUser: '검수 User',
    createdAt: '검사 일시',
    duration: '검사 소요 시간',
    input: '사용 데이터 경로',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    validationResult: 'Validation 검증 결과',
    bestThreshold: '최적 임계값',
    selectedThreshold: '현재 선택한 threshold 값',
    checkResult: '검사 결과 보기',
    resultPerImage: '모형 판정 결과 / 대상 이미지 수',
    defectRate: '불량률',
    detectionCnt: '유형별 검출 건수',
    fileName: '파일 이름',
    copayFileName: '파일명 복사',
    noImageMsg: '이미지가 없습니다.',
    imageArr: '이미지 배열',
    firstRow: '1열',
    secondRow: '2열',
    thirdRow: '3열',
    fourthRow: '4열',
    classification: 'Class 분류',
    inspectResult: '검사 결과 검수하기',
    list: '목록으로',
    positive: '양',
    negative: '불',
    breadcrumb: {
      infer: '성능 평가',
      detail: '상세 확인',
    },
    unclassified: '미분류',
    fields: {
      name: 'Class명',
      result: '검수결과',
    },
    total: '전체',
    fm: {
      busyWarning: '{name}이(가) 작업 중입니다.',
    },
    // 추가
    count: '{count}장',
    save: '선택한 Threshold 저장하기',
    saveThreshold: '검수 결과 저장',
    saveResult: '저장',
    thresholdConfirm1: '선택하신 Threshold 값으로 검수 결과가 저장됩니다.',
    thresholdConfirm2: '현재 선택하신 값으로 저장하시겠습니까?',
  },
  // /train/inferecne/_id/cf <<>> --
  tiIdCf: {
    info: '작업 기본 정보',
    name: '검사 이름',
    type: '유형',
    modelAlgo: '학습 모형 / 알고리즘',
    updatedAt: '검사 일시',
    duration: '검사 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    save: '검수 결과 저장 및 성능 확인',
    extendHour: '작업 시간 연장',
    extendHourMsg1: '1분 뒤 작업 시간이 종료됩니다.',
    extendHourMsg2: '작업 시간을 연장하시겠습니까?',
    extendHourMsg3: '(응답이 없을 시 1분 뒤 작업 내용은 자동으로 저장되고, 현재 작업 화면은 종료됩니다.)',
    breadcrumb: {
      infer: '성능 평가',
      inspect: '검수하기',
    },
    min: '분',
    sec: '초',
    fm: {
      success: '검수 결과가 저장되었습니다.',
    },
  },
  // /train/inferecne/_id/od <<>> --
  tiIdOd: {
    save: '검수 결과 저장 및 성능 확인',
    extendHour: '작업 시간 연장',
    extendHourMsg1: '1분 뒤 작업 시간이 종료됩니다.',
    extendHourMsg2: '작업 시간을 연장하시겠습니까?',
    extendHourMsg3: '(응답이 없을 시 1분 뒤 작업 내용은 자동으로 저장되고, 현재 작업 화면은 종료됩니다.)',
    breadcrumb: {
      infer: '성능 평가',
      inspect: '검수하기',
    },
    min: '분',
    sec: '초',
    fm: {
      success: '저장 되었습니다.',
    },
  },
  // /train/inferecne/_id/sg <<>> --
  tiIdSg: {
    save: '검수 결과 저장 및 성능 확인',
    extendHour: '작업 시간 연장',
    extendHourMsg1: '1분 뒤 작업 시간이 종료됩니다.',
    extendHourMsg2: '작업 시간을 연장하시겠습니까?',
    extendHourMsg3: '(응답이 없을 시 1분 뒤 작업 내용은 자동으로 저장되고, 현재 작업 화면은 종료됩니다.)',
    breadcrumb: {
      infer: '성능 평가',
      inspect: '검수하기',
    },
    min: '분',
    sec: '초',
    fm: {
      success: '저장 되었습니다.',
    },
  },
  // /train/inferecne/_id/result/cf <<>> --
  tiIdResultCf: {
    result: '성능 검수 결과',
    info: '성능 평가 기본 정보',
    name: '검사 이름',
    type: '유형',
    inferenceData: '평가 데이터',
    modelAlgo: '학습 모형 / 알고리즘',
    createdAt: '검사 일시',
    updatedAt: '최종 검수 일시',
    duration: '검사 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    checkResult: '검사 결과 보기',
    inspectionPerImage: '검수 결과 반영 / 대상 이미지 수 {count}개',
    defectRate: '불량률',
    detectionCnt: '유형별 검출 건수',
    performance: '모형 성능',
    accuracy: '정확도',
    bestThreshold: '최적 임계값',
    threshold: '임계값',
    classPrecision: 'Class별 Precision',
    classRecall: 'Class별 Recall',
    inspect: '검수하기',
    list: '목록으로',
    positive: '양',
    negative: '불',
    breadcrumb: {
      infer: '성능 평가',
      result: '결과 확인',
    },
  },
  // /train/inferecne/_id/result/od <<>> --
  tiIdResultOd: {
    result: '성능 검수 결과',
    info: '성능 평가 기본 정보',
    name: '검사 이름',
    type: '유형',
    modelAlgo: '학습 모형 / 알고리즘',
    createdAt: '검사 일시',
    updatedAt: '최종 검수 일시',
    duration: '검사 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    checkResult: '검사 결과 보기',
    inspectionPerImage: '검수 결과 반영 / 대상 이미지 수 {count}개',
    defectRate: '불량률',
    detectionCnt: '유형별 검출 건수',
    performance: '모형 성능',
    accuracy: '정확도',
    classMAP: 'Class별 mAP',
    judgement: '판정 이미지',
    inspection: '검수 이미지',
    resultList: '판정 결과 리스트',
    inspect: '검수하기',
    list: '목록으로',
    fileName: '파일명',
    positive: '양',
    negative: '불',
    resultImage: '결과 이미지',
    breadcrumb: {
      infer: '성능 평가',
      result: '결과 확인',
    },
    count: '개',
  },
  // /train/inferecne/_id/result/sg <<>> --
  tiIdResultSg: {
    result: '성능 검수 결과',
    info: '성능 평가 기본 정보',
    name: '검사 이름',
    type: '유형',
    modelAlgo: '학습 모형 / 알고리즘',
    createdAt: '검사 일시',
    updatedAt: '최종 검수 일시',
    duration: '검사 소요 시간',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    checkResult: '검사 결과 보기',
    inspectionPerImage: '검수 결과 반영 / 대상 이미지 수 {count}개',
    defectRate: '불량률',
    detectionCnt: '유형별 검출 건수',
    performance: '모형 성능',
    accuracy: '정확도',
    classMAP: 'Class별 mAP',
    judgement: '판정 이미지',
    inspection: '검수 이미지',
    resultList: '판정 결과 리스트',
    inspect: '검수하기',
    list: '목록으로',
    fileName: '파일명',
    positive: '양',
    negative: '불',
    breadcrumb: {
      infer: '성능 평가',
      result: '결과 확인',
    },
    count: '개',
  },
  // /train/inference/compare <<>> --
  tiCompare: {
    single: '단일 성능 평가',
    compare: '비교 성능 평가',
    condition: '조건 보기',
    outOfImage: '{total}외 {count}개',
    removeInfer: '성능 평가 삭제',
    remove: '삭제',
    confirmRemoveInfer: '선택한 성능 평가를 삭제하시겠습니까?',
    fields: {
      workType: '작업 유형',
      updateAt: '최근 평가 일시',
      workName: 'Work 명',
      class: 'Class 정보',
      model: '평가 대상 모형',
      algorithm: '알고리즘',
      image: '이미지',
      user: '평가 User',
      status: '평가 진행 상태',
      resultFolder: '결과 폴더명',
    },
    fm: {
      changeDescSuc: 'Description이 변경되었습니다.',
      removeSuc: '삭제되었습니다',
    },
    // 추가
    count: '{count}개',
  },
  // /train/inference/compare/_id <<>> --
  tiCompareId: {
    info: '비교 평가 기본 정보',
    workType: '작업 유형',
    user: '등록 User',
    createdAt: '등록 일시',
    updateAt: '최근 평가 일시',
    data: '사용 데이터',
    imageCnt: '이미지 수',
    classInfo: 'Class 정보',
    compareResult: '비교 평가 결과',
    addModel: '모형 추가',
    accuracy: '정확도',
    list: '목록으로',
    modelDetail: '평가모형 상세정보',
    modelName: '모형 명',
    type: '유형',
    algorithm: '알고리즘',
    trainingUser: '학습 User',
    endedAt: '학습 완료 일시',
    duration: '학습 소요 일시',
    detail: '상세 설정',
    detailInfo: '상세 설정 정보',
    close: '닫기',
    removeInfer: '성능 평가 삭제',
    remove: '삭제',
    confirmRemoveInfer: '선택한 성능 평가를 삭제하시겠습니까?',
    fields: {
      index: '순서',
      model: '모형 명',
      algorithm: '알고리즘',
      createdAt: '평가 일시',
      user: '평가 User',
      status: '진행 상태',
      accuracy: '모형 성능(정확도)',
      map: '모형 성능(mAP)',
    },
    performanceFields: {
      index: '순서',
      model: '모형 명',
      algorithm: '알고리즘',
      user: '평가 User',
      status: '진행 상태',
      accuracy: '정확도',
    },
    aucFields: {
      index: '순서',
      model: '모형 명',
      algorithm: '알고리즘',
      user: '평가 User',
      status: '진행 상태',
    },
    breadcrumb: {
      infer: '비교 성능 평가',
      result: '상세 확인',
    },
    fm: {
      descSuc: 'Description이 변경되었습니다.',
      remove: '삭제되었습니다',
    },
    // 추가
    count: '{count}장',
  },
  // /my/index <<>> --
  my: {
    title: '마이페이지',
    info: '기본정보',
    id: '아이디',
    name: '이름',
    company: '회사',
    department: '부서',
    project: '내 프로젝트',
    projectSetting: '기본 프로젝트 설정',
    curProject: '현재 프로젝트',
    defaultProject: '기본 프로젝트',
    admin: '관리자',
    user: '참여쟈',
    permission: '권한',
    projectInfo: '프로젝트 정보',
    manageUser: '참여자 관리',
    noProject: '프로젝트가 없습니다.',
    breadcrumb: {
      my: '내 정보',
    },
  },
  // /my/change-password <<>> --
  myChangePassword: {
    title: '마이페이지',
    currPw: '현재 비밀번호',
    newPw: '새 비밀번호',
    pwMsg: '영문+숫자 10자리 이상 또는 영문+숫자+특수문자 8자리 이상 비밀번호만 사용 가능합니다.',
    confirmPw: '비밀번호 확인',
    confirmNewPw: '새 비밀번호 확인',
    changePw: '비밀번호 변경',
    breadcrumb: '비밀번호 변경',
    fm: {
      inputCurrPw: '현재 비밀번호를 입력해주세요.',
      inputNewPw: '새 비밀번호를 입력해주세요.',
      pwWarning: '영문+숫자 10자리 이상 또는 영문+숫자+특수문자 8자리 이상 비밀번호만 사용 가능합니다.',
      incorrectPw: '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
      inputOtherPw: '현재 사용중인 비밀번호와 다른 비밀번호를 입력해주세요.',
      changePwSuc: '비밀번호가 변경되었습니다. 다시 로그인해주세요.',
    },
  },
  myUsageProjects: {
    title: '마이페이지',
    imageUploadStatus: '이미지 업로드 현황',
    learnStatus: '학습 현황',
    breadcrumb: '프로젝트별 사용량',
    totalUploadCount: '총 업로드 횟수',
    totalLearningCount: '총 학습 횟수',
    usedUploadCount: '사용 업로드 장수',
    usedLearningCount: '사용 학습 횟수',
    remainUploadCount: '잔여 업로드 장수',
    remainLearningCount: '잔여 학습 횟수',
    limitImageCount: '업로드 이미지 장수 제한 값',
    totalImageUploadCount: '총 업로드 이미지 장수',
    remainImageUploadCount: '잔여 업로드 가능 장수',
    limitLearningCount: '학습 횟수 제한 값',
    totalLearningUploadCount: '총 학습 횟수',
    remainLearningUploadCount: '잔여 학습 횟수',
    images: '{count} 장',
    learning: '{count} 건',
  },
  // /my/notifications <<>> --
  myNotifications: {
    title: '마이페이지',
    filter: {
      all: '전체',
      processing: '데이터 가공',
      training: '학습 수행',
      inference: '성능 평가',
      compareInference: '비교 성능 평가',
      addCompare: '비교 성능 모형 추가',
    },
    project: '프로젝트 명',
    condition: '조건 보기',
    status: {
      // 완료, 진행
      labeling: '데이터 가공',
      learning: '학습 수행',
      inference: '성능 평가',
      compareInfer: '비교 성능 모형 추가',
      compare: '비교 성능 평가',
      completed: '완료',
      processed: '진행',
      verb: '되었습니다.',
      job: '작업이',
    },
    resultNone: '알림이 없습니다.',
    breadcrumb: {
      noti: '알림 이력',
    },
    done: '완료',
    process: '진행',
    shortName: {
      labeling: '가공',
      compareInference: '모형',
      compare: '비교',
      training: '학습',
      inference: '성능',
    },
    name: {
      labeling: '데이터 가공',
      compareInference: '비교 성능 모형 추가',
      compare: '비교 성능 평가',
      training: '학습 수행',
      inference: '성능 평가',
    },
    hour: '시간',
    min: '분',
    sec: '초',
    spend: '소요',
  },
  // /my/projects <<>> --
  myProjects: {
    title: '프로젝트',
    createProject: '프로젝트 등록',
    name: '프로젝트 명',
    code: '프로젝트 코드',
    condition: '조건 보기',
    people: '{count}명',
    fields: {
      title: '프로젝트 명',
      code: '프로젝트 코드',
      company: '고객사',
      userCnt: '참여자 수',
      createdAt: '등록일시',
    },
    breadcrumb: {
      list: '프로젝트 List',
    },
  },
  // /my/projects/create <<>> --
  myProjectsCreate: {
    title: '프로젝트 등록',
    companyInfo: '고객사 정보',
    company: '고객사',
    chooseCompany: '고객사를 선택하세요.',
    projectInfo: '프로젝트 정보',
    projectTitle: '프로젝트 제목',
    projectCode: '프로젝트 코드',
    checkDuplicate: '중복체크',
    isOkCode: '등록 가능한 프로젝트 코드입니다.',
    codeMsg1: '프로젝트 코드는 등록 이후 수정이 불가능합니다.',
    codeMsg2: "대소문자와 ‘-’, '_'만 사용 가능합니다.",
    projectDesc: '프로젝트 설명',
    productImage: '제품 이미지',
    addImage: '이미지 추가',
    default: '대표',
    setDefault: '대표로 설정',
    inputImage: '이미지를 추가하세요',
    inspectionInfo: '검사라인 정보',
    addLine: '라인 검사',
    line: '라인 추가',
    lineName: '라인 이름',
    lineCode: '라인 코드',
    productName: '제품 이름',
    processName: '공정 이름',
    codeMsg3: '라인 코드는 대소문자와 ‘-’, ‘_‘만 사용 가능하며, 다른 라인 코드와 중복될 경우 사용불가합니다.',
    inputLine: '라인을 추가하세요',
    create: '등록 완료',
    fields: {
      name: '고객사 명',
      projectCnt: '프로젝트 수',
      lineCnt: '라인 수',
      managerCnt: '관리자 수',
      userCnt: '참여자 수',
      createdAt: '등록일시',
    },
    breadcrumb: {
      project: '프로젝트',
      create: '프로젝트 등록',
    },
    fm: {
      inputCode: '4자 이상의 프로젝트 코드를 입력해주세요.',
      codeError: '{code}는 프로젝트 코드로 사용불가능합니다.',
      duplicateLineName: '중복된 라인 이름이 있습니다.',
      duplicateLineCode: '중복된 라인 코드가 있습니다.',
      chooseCompany: '고객사를 선택하세요.',
      inputProject: '프로젝트 제목을 입력하세요.',
      checkDuplicate: '프로젝트 코드 중복체크를 진행해주세요.',
      inputLineName: '라인 이름을 입력해주세요.',
      inputLineCode: '라인 코드를 입력해주세요.',
      validateLine: "라인 코드는 0~9, 알파벳 대소문자, '-' 또는 '_'만 입력 가능합니다.",
      inputMoreCode: '라인 코드는 4글자 이상 입력해주세요.',
      inputProductName: '제품 이름을 입력해주세요.',
      inputProcess: '공정 이름을 입력해주세요.',
    },
  },
  // /my/projects/_id <<>> --
  myProjectsId: {
    project: '프로젝트',
    info: '프로젝트 정보',
    company: '고객사명',
    title: '프로젝트 제목',
    code: '프로젝트 코드',
    createdAt: '생성 일시',
    updatedAt: '수정 일시',
    desc: '프로젝트 설명',
    noDesc: '프로젝트 설명이 없습니다.',
    image: '제품 이미지',
    thumb: '대표',
    noImage: '이미지가 없습니다.',
    inspectionInfo: '검사라인 정보',
    noLineInfo: '라인 정보가 없습니다.',
    userInfo: '사용자 정보',
    admin: '관리자',
    user: '참여자',
    more: '자세히',
    list: '목록으로',
    removeProject: '프로젝트 삭제',
    editInfo: '정보 수정',
    removeProjectMsg: '프로젝트를 삭제하시겠습니까?',
    fields: {
      name: '라인',
      code: '라인 코드',
      productName: '제품',
      process: '공정',
    },
    breadcrumb: {
      project: '프로젝트',
    },
    fm: {
      removeSuccess: '프로젝트가 삭제되었습니다.',
    },
    // 추가
    count: '{count}명',
  },
  // /my/projects/_id/edit  <<>> --
  myProjectsIdEdit: {
    project: '프로젝트',
    companyInfo: '고객사 정보',
    company: '고객사명',
    projectInfo: '프로젝트 정보',
    title: '프로젝트 제목',
    code: '프로젝트 코드',
    desc: '프로젝트 설명',
    image: '제품 이미지',
    uploadImage: '이미지 추가',
    thumb: '대표',
    setDefault: '대표로 설정',
    addImage: '이미지를 추가하세요',
    done: '수정 완료',
    breadcrumb: {
      project: '프로젝트',
      edit: '수정',
    },
    fm: {
      uploadImageFail: '이미지 등록에 실패했습니다.',
      done: '수정완료되었습니다.',
      duplicateLine: '중복된 라인 이름이 있습니다.',
      duplicateCode: '중복된 라인 코드가 있습니다.',
      chooseCompany: '고객사를 선택하세요.',
      inputTitle: '프로젝트 제목을 입력하세요.',
      inputLineName: '라인 이름을 입력해주세요.',
      inputLineCode: '라인 코드를 입력해주세요.',
      validateLine: "라인 코드는 0~9, 알파벳 대소문자, '-' 또는 '_'만 입력 가능합니다.",
      inputMoreCode: '라인 코드는 4글자 이상 입력해주세요.',
      inputProductName: '제품 이름을 입력해주세요.',
      inputProcess: '공정 이름을 입력해주세요.',
    },
  },
  // /my/projects/_id/user <<>> --
  myProjectsIdUser: {
    title: '마이페이지',
    projectAdmin: '프로젝트 관리자',
    companyAdmin: '고객사 관리자',
    user: '참여자',
    total: '{count}명의 사용자가 있습니다.',
    addUser: '참여자 등록',
    lineInfo: '라인 정보',
    addLine: '라인 추가',
    permission: '권한 추가',
    line: '라인',
    removeUser: '참여자 삭제',
    remove: '삭제',
    removeUserMsg: '{name}({username})를 참여자에서 삭제하시겠습니까?',
    breadcrumb: {
      project: '프로젝트',
      user: '사용자',
    },
    fm: {
      addUserSuc: '참여자들 추가했습니다.',
      removeUserSuc: '참여자가 삭제되었습니다.',
    },
    // 추가
    training: '학습',
    monitoring: '모니터링',
  },
  // /my/projects/_id/user/manager <<>> --
  myProjectsIdUserManager: {
    title: '마이페이지',
    projectAdmin: '프로젝트 관리자',
    companyAdmin: '고객사 관리자',
    user: '참여자',
    total: '{count}명의 프로젝트 관리자가 있습니다.',
    moreAdmin: '관리자 추가',
    removeAmin: '관리자 삭제',
    remove: '삭제',
    removeUserMsg: '{name}({username})를 프로젝트 관리자에서 삭제하시겠습니까?',
    addAdmin: '관리자 등록',
    admin: '관리자',
    breadcrumb: {
      project: '프로젝트',
      user: '관리자',
    },
    fm: {
      addAdminSuc: '프로젝트 관리자를 추가했습니다.',
      removeAdminSuc: '프로젝트 관리자가 삭제되었습니다.',
    },
  },
  // /my/user  <<>> --
  myUser: {
    user: '사용자',
    createUser: '사용자 등록',
    total: '총 {count}명의 사용자가 있습니다.',
    filter: {
      name: '프로젝트 명',
      department: '부서',
      condition: '조건 보기',
    },
    role: {
      type: '유형',
      system: '시스템 관리자',
      service: '서비스 관리자',
      company: '고객사 관리자',
      user: '사용자',
    },
    fields: {
      name: '이름',
      company: '고객사',
      roldId: '유형',
      department: '부서',
      engaged: '소속 프로젝트',
      createdAt: '등록일시',
    },
    breadcrumb: {
      users: '사용자 List',
    },
    // 추가
    count: '{count}개',
  },
  // /my/user/create <<>> --
  myUserCreate: {
    user: '사용자',
    info: '유저 정보',
    type: '사용자 유형',
    admin: '관리자',
    common: '일반 사용자',
    company: '고객사',
    selectCompany: '고객사를 선택하세요.',
    project: '프로젝트',
    total: '{count}개',
    selectProject: '프로젝트 선택',
    lineInfo: '라인 정보',
    addLine: '라인 추가',
    addPermission: '권한 추가',
    line: '라인',
    id: '아이디',
    inputId: '아이디를 입력해주세요.',
    checkDuplicate: '중복 확인',
    validId: '사용 가능한 아이디입니다.',
    invalidId: '이미 등록된 아이디입니다.',
    name: '이름',
    inputName: '이름을 입력해주세요.',
    department: '부서',
    selectDepartment: '부서를 입력해주세요.',
    done: '등록 완료',
    selectedCompany: '고객사 선택',
    filter: {
      company: '고객사 명',
      condition: '조건 보기',
    },
    fields: {
      name: '고객사 명',
      projectCnt: '프로젝트 수',
      lineCnt: '라인 수',
      managerCnt: '관리자 수',
      userCnt: '참여자 수',
      createdAt: '등록일시',
    },
    projectFields: {
      title: '프로젝트 명',
      code: '프로젝트 코드',
      company: '고객사',
      user: '참여자',
      line: '라인',
      createdAt: '등록일시',
    },
    breadcrumb: {
      user: '사용자',
      userCreate: '사용자 등록',
    },
    fm: {
      inputId: '4자 이상의 아이디를 입력해주세요.',
      chooseCompany: '고객사를 선택해주세요.',
      chooseProject: '프로젝트를 선택해주세요.',
      choosePermission: '프로젝트 별 하나 이상의 권한을 선택해주세요.',
      checkIdDuplicate: '아이디 중복체크를 진행해주세요.',
      duplicateId: '중복된 아이디는 사용 불가능합니다.',
      createPw: '비밀번호를 생성해주세요.',
      inputName: '이름을 입력해주세요.',
      inputDepartment: '부서를 입력해주세요.',
    },
  },
  // /my/user/_id  <<>> --
  myUserId: {
    title: '사용자',
    id: '아이디',
    name: '이름',
    company: '고객사',
    department: '부서',
    edit: '정보 수정',
    projectList: '프로젝트 목록',
    setDefault: '기본 프로젝트 설정',
    default: '기본 프로젝트',
    admin: '관리자',
    user: '참여자',
    permission: '권한',
    editPermission: '권한 수정',
    noEngagedProject: '소속된 프로젝트가 없습니다.',
    list: '목록으로',
    removeUser: '사용자 삭제',
    remove: '삭제',
    removeUserMsg: '{username} 사용자를 삭제하시겠습니까?',
    breadcrumb: {
      user: '사용자',
    },
    fm: {
      removeSuccess: '사용자가 삭제되었습니다.',
    },
  },
  // /my/user/_id/edit  <<>> --
  myUserIdEdit: {
    title: '사용자',
    info: '기본 정보',
    id: '아이디',
    name: '이름',
    department: '부서',
    departmentName: '부서 명',
    pw: '비밀번호',
    newPw: '새 비밀번호',
    done: '수정 완료',
    breadcrumb: {
      user: '사용자',
      edit: '정보 수정',
    },
  },
  // /cs  <<>> --
  cs: {
    lgCenter: 'LG PRI AI플랫폼 고객센터',
    center: '고객센터',
    lgAsk: '사용 중 문의사항이 있으시면 하기 방법으로 문의바랍니다.',
    ask: '문의사항이 있으면 언제든지 말씀해주세요.',
    inquiryWindow: '문의 창구',
    inquiryDesc:
      '궁금하신 사항 또는 요청사항에 대해 하기 가이드와 같이 이메일로 문의해 주시면 빠른 시일 내에 도움드리겠습니다.',
    generalInquiry: '일반 문의 접수',
    withinTwo: '상시 접수, 영업일 기준 2일 이내 회신',
    tips: '문의 메일 작성 가이드',
    tipsDesc1: '문의를 남기시는 분의 성함 / 소속 / 연락처를 남겨주세요.',
    tipsDesc2: '작업을 진행했던 플랫폼 명과 프로젝트, 이슈가 발생했던 화면에 대한 상세한 문의 내용을 작성해주세요.',
    ex1: 'ex',
    ex2: '플랫폼 : MAVIN Cloud',
    ex3: '프로젝트 : DD_냉장고_라벨검사_상부',
    tipsDesc3: '화면 이미지를 첨부해주시면 내용 확인에 더 도움이 됩니다.',
    example: '문의 메일 예시',
    title: '제목',
    company: '[소속회사명]',
    inquiryCS: 'CS 문의',
    tipsDesc4: '라벨링 병합으로 선택한 항목 보이지 않음',
    content: '내용',
    contentEx1: '홍길동 / 제조AI솔루션팀 / 010-1234-1234',
    contentEx2: '플랫폼 : MAVIN Cloud',
    contentEx3: '데이터 가공 생성 화면에서 병합할 데이터 목록 선택했으나 선택한 데이터 목록이 보이지 않습니다.',
    contentEx4: '프로젝트 : ',
    contentEx5: '선택한 병합할 데이터',
    emergency: '긴급 문의 접수',
    operation: '영업일 기준 오전 10시 ~ 오후 6시 운영',
    process: '처리 절차',
    inquiry: '접수',
    inquiryType: '일반 문의 접수 / 긴급 문의 접수',
    confirmation: '문의 내용 확인 및 안내',
    confirmDesc: '문의 접수일로부터 영업일 기준 2일 이내에 아래의 분류 기준에 따라 분류 후 회신드립니다.',
    simpleInquiry: '단순 사용법 문의',
    simpleCase: '사용 방법 미숙지로 인한 문의로 간략한 안내가 필요한 문의의 경우',
    computerInquiry: '전산 관련 문의',
    computerCase:
      '데이터의 수정, 삭제 등이 필요하나 유효성 검사 등의 사유로 인해 시스템 상에서 처리가 불가하여 접수된 문의인 경우',
    defectsInquiry: '하자 보수 요청',
    defectsCase: '시스템의 하자로 인해 발생한 문의인 경우',
    featureInquiry: '기능 수정 요청',
    featureCase: '사용성 혹은 성능을 개선해야 해결되는 문의인 경우',
    criteraAnswers: '분류 기준 안내 및 답변 전달',
    classification: '분류 기준 안내',
    classificationDesc: '안내일로 부터 업무일 기준 2일 이내 처리 후 회신',
    classificationDesc2: '하자 보수 일정 안내 후 처리',
    classificationDesc3: '보수 완료 이후 개별 안내',
    classificationDesc4: '다음 버전 업데이트 진행시 수정 진행',
    classificationDesc5: '수정된 업데이트 버전 배포 완료 이후 개별 안내',
  },
  // /intro  <<>> --
  intro: {
    mavin:
      'MAVIN-Cloud는 AI 전문가의 도움이나 코딩없이도 현업에서 쉽게 AI 알고리즘을 학습하고, 지속 적용할 수 있게 하는 GUI기반의 AI 이미지 학습/검사 플랫폼입니다.',
    desc1:
      'MAVIN-Cloud는 데이터 관리, 데이터 가공(Labeling), 모형학습, 성능평가에 이르는 일련의 AI 학습 및 적용 프로세스를 유기적이고, 지속적으로 수행할 수 있도록 함으로써,',
    desc2: 'AI 모형의 개발 및 적용기간을 단축시켜 제조현장의 DX 확산 가속화의 기반을 마련하고있습니다.',
    desc3:
      '특히 생산기술원 전자생산기술센터와 협력하여 국내 및 해외법인의 다양한 제품 및 부품의 검사공정에 활용되어 LG전자의 제조 DX경쟁력 강화에 기여하고 있습니다.',
    desc4:
      'MAVIN-Cloud 에서는 코딩없이 클릭만으로 데이터 업로드부터, Labeling, 학습, 평가까지 손쉽게 진행할 수 있습니다.',
    desc5: '클라우드 서비스로 제공되므로 GPU나 기타 환경설정이 필요없어, 현업에서 곧바로 학습을 진행하실 수 있습니다.',
    feature: 'MAVIN-Cloud 주요 기능 및 Flow',
    advanced: 'MAVIN-Cloud 2.0 신규 강화 기능',
    contact:
      'MAVIN-Cloud 서비스 사용 관련 상세안내가 필요하시거나 기술 문의가 있으신 경우, 아래 메일로 연락주시면 빠르게 도움드리도록 하겠습니다.',
    feature1: '데이터 사용목적에 따라 학습용/평가용으로 구분하여 데이터 업로드 진행',
    feature2: '학습 및 평가에 필요한 이미지 데이터 Labeling 진행 (Auto-Labeling 기능 지원)',
    feature3: 'PRI AI전문가가 제공하는 알고리즘 Pool에서 원하는 모델을 선택하여 AI 학습 진행',
    feature4: '학습이 완료된 AI 모형의 최종 성능평가 진행',
    advancedTitle1: 'AI Labeling 기능',
    advanced1:
      'Semi-Auto Labeling 기능으로, 대량의 이미지 원본 Data를 사용자가 직접 Labeling하지 않고, 준비된 모형이 자동으로 추론하게 함으로써 대량의 Labeling 작업을 쉽게 진행할 수 있습니다.',
    advancedTitle2: 'Labeling 병합 및 모형 재학습 기능',
    advanced2:
      '기존에 학습된 AI 모형의 파라미터 정보를 가지고, 새로운 Data set으로 모형을 재학습 할 수 있습니다. “Data Labeling 병합 기능”으로 누적 생성된 Data set을 생성 후, 재학습 기능을 사용하면 쉽고 빠르게 재학습이 가능합니다.',
    advancedTitle3: 'AI 모형성능 비교 평가 기능',
    advanced3:
      '학습시킨 단일 AI 모형에 대한 평가 외에, 여러 AI 모형들에 대한 성능을 동일한 Data로 한번에 비교 평가할 수 있습니다.',
  },
  // /admin
  admin: {
    service: '서비스 관리자',
    statistics: '종합 통계',
    //변경 추가
    companyCnt: '총 고객사 수',
    count: '개',
    projectCnt: '총 프로젝트 수',
    //
    userCnt: '총 이용자 수',
    people: '명',
    analysis: '이용현황 분석',
    userTop10: '고객사 접속 TOP 10',
    userTrend: '이용자 접속 추이',
    projectTrend: '프로젝트 행동 추이',
    currentHistory: '최근 행동 이력',
    detail: '자세히',
    username: '님이 ',
    verb: '',
    data: '데이터를',
    labeling: '했습니다.',
    processed: '가공',
    training: '학습을',
    learning: '했습니다.',
    perfomed: '수행',
    inference: '성능을',
    evaluated: '평가',
    performance: '했습니다.',
    compare: '성능을 ',
    comEvaluated: '비교평가',
    compartive: '했습니다.',
    breadcrumb: {
      dashboard: '대시보드',
    },
  },
  // /admin/history
  adminHistory: {
    service: '서비스 관리자',
    filter: {
      company: '고객사',
      projectName: '프로젝트 이름',
      all: '전체',
      labeling: '데이터 가공',
      training: '학습 수행',
      inference: '단일 평가',
      compare: '비교 평가',
      condition: '조건 보기',
    },
    username: '님이 ',
    verb: '',
    data: '데이터를',
    labeling: '했습니다.',
    processed: '가공',
    training: '학습을',
    learning: '했습니다.',
    perfomed: '수행',
    inference: '성능을',
    evaluated: '평가',
    performance: '했습니다.',
    compare: '성능을 ',
    comEvaluated: '비교평가',
    compartive: '했습니다.',
    breadcrumb: {
      dashboard: '대시보드',
      history: '최근 행동 내역',
    },
  },
  // /admin/algorithm  <<>> --
  adminAlgorithm: {
    title: 'AI 알고리즘',
    createAlgo: '알고리즘 등록',
    total: '총 {count}개의 알고리즘이 있습니다.',
    filter: {
      name: '알고리즘 명',
      condition: '조건 보기',
    },
    removeAlgo: '알고리즘 삭제',
    remove: '삭제',
    removeAlgoMsg: '선택한 알고리즘을 삭제하시겠습니까?',
    fields: {
      name: '알고리즘 명',
      type: '유형',
      trainParams: '학습 파라미터',
      inferParams: '추론 파라미터',
      createdAt: '등록 일시',
    },
    breadcrumb: {
      list: '알고리즘 List',
    },
    workOptions: {
      type: '작업 유형',
    },
    fm: {
      removeAlgo: '알고리즘이 삭제되었습니다.',
      changeDesc: '알고리즘 Description이 변경되었습니다.',
    },
    // 추가
    etc: '{name} 외 {count}개',
    count: '{count}개',
  },
  // /admin/algorithm/create --
  admingAlgorithmCreate: {
    title: 'AI 알고리즘',
    info: '알고리즘 정보',
    name: '알고리즘 이름',
    type: '알고리즘 유형',
    work: '작업 유형',
    scope: '알고리즘 적용 범위',
    total: '전체 프로젝트',
    specific: '특정 프로젝트',
    // 추가
    algoProject: '알고리즘 적용 프로젝트',
    count: '{count}개',
    //
    selectProject: '프로젝트 선택',
    chooseProject: '알고리즘을 적용할 프로젝트를 선택하세요',
    algoDesc: '알고리즘 Description',
    exInfo: '설명자료',
    algoInfo: '알고리즘 설명자료',
    params: '파라미터',
    trainParams: '학습 파라미터',
    inferParams: '추론 파라미터',
    aug: 'Augmentation 설정',
    addAlgo: '알고리즘 등록',
    breadcrumb: {
      algorithm: '알고리즘',
      create: '등록',
    },
    algorithmOptions: {
      val1: 'AI 라벨링',
      val2: '학습',
      val3: '데이터 증강',
    },
    fm: {
      addAlgoSuc: '알고리즘이 등록되었습니다.',
      inputName: '알고리즘 이름 입력해주세요',
      duplicateName: '중복되는 알고리즘 명이 있습니다. 다른 이름을 입력해주세요.',
      chooseType: '알고리즘 유형을 선택해주세요',
      chooseWork: '작업 유형을 선택해주세요',
      chooseScope: '알고리즘 적용 범위를 선택해주세요',
      chooseProject: '알고리즘을 적용할 프로젝트를 선택해주세요',
      inputValidParams: '파라미터의 Default Value, Min, Max 값을 올바르게 입력해주세요',
      inputMust: '파라미터의 필수값을 입력해주세요',
    },
  },
  // /admin/algorithm/_id/  <<>> --
  adminAlgorithmId: {
    title: 'AI 알고리즘',
    name: '알고리즘 이름',
    type: '알고리즘 유형',
    work: '작업 유형',
    createdAt: '등록 일시',
    scope: '알고리즘 적용 범위',
    all: '전체 프로젝트',
    specific: '특정 프로젝트',
    project: '알고리즘 적용 프로젝트',
    algoDesc: '알고리즘 Description',
    algoFile: '알고리즘 설명파일',
    trainParams: '학습 파라미터',
    inferParams: '추론 파라미터',
    list: '목록으로',
    removeAlgo: '알고리즘 삭제',
    edit: '정보 수정',
    remove: '삭제',
    removeAlgoMsg: '{name} 알고리즘을 삭제하시겠습니까?',
    fields: {
      name: '파라미터 명',
      key: '파라미터 Key',
    },
    breadcrumb: {
      algorithm: '알고리즘',
    },
    options: {
      type: '작업 유형 선택',
      val1: 'AI라벨링',
      val2: '학습',
      val3: '증강',
    },
    fm: {
      incorrectInfo: '알고리즘 정보가 올바르지 않습니다.',
      removeSuccess: '알고리즘이 삭제되었습니다.',
    },
  },
  // /admin/algorithm/_id/edit  <<>> --
  adminAlgorithmIdEdit: {
    title: 'AI 알고리즘',
    info: '알고리즘 정보',
    name: '알고리즘 이름',
    type: '알고리즘 유형',
    work: '작업 유형',
    scope: '알고리즘 적용 범위',
    all: '전체 프로젝트',
    specific: '특정 프로젝트',
    // 추가
    project: '알고리즘 적용 프로젝트',
    count: '{count}개',
    //
    selectedProject: '프로젝트 선택',
    chooseProject: '알고리즘을 적용할 프로젝트를 선택하세요',
    algoDesc: '알고리즘 Description',
    attached: '첨부파일',
    selectedFile: '현재 파일',
    remove: '삭제',
    algorithmInfo: '알고리즘 설명자료',
    params: '파라미터',
    trainParams: '학습 파라미터',
    inferParams: '추론 파라미터',
    aug: 'Augmentation 설정',
    done: '수정 완료',
    breadcrumb: {
      algorithm: '알고리즘',
      edit: '수정',
    },
    algorithmOptions: {
      val1: 'AI 라벨링',
      val2: '학습',
      val3: '데이터 증강',
    },
    fm: {
      changeAlgorithm: '알고리즘이 변경되었습니다.',
      inputName: '알고리즘 이름 입력해주세요',
      duplicateName: '중복되는 알고리즘 명이 있습니다. 다른 이름을 입력해주세요.',
      chooseType: '알고리즘 유형을 선택해주세요',
      chooseScope: '알고리즘 적용 범위를 선택해주세요',
      chooseProject: '알고리즘을 적용할 프로젝트를 선택해주세요',
      inputValidParams: '파라미터의 Default Value, Min, Max 값을 올바르게 입력해주세요',
      inputMust: '파라미터의 필수값을 입력해주세요',
    },
  },
  // /admin/company --
  adminCompany: {
    title: '고객사',
    createCompany: '고객사 등록',
    total: '총 {count}개의 고객사가 있습니다.',
    filter: {
      name: '고객사 명',
      condition: '조건 보기',
    },
    fields: {
      name: '고객사 명',
      projectCnt: '프로젝트 수',
      lineCnt: '라인 수',
      managerCnt: '관리자 수',
      userCnt: '참여자 수',
      createdAt: '등록 일시',
    },
    breadcrumb: {
      list: '고객사 List',
    },
    // 추가
    count: '{count}개',
    people: '{people}명',
  },
  // /admin/company/create --
  adminCompanyCreate: {
    company: '고객사',
    info: '고객사 정보',
    name: '고객사 명',
    // 추가
    admin: '관리자',
    count: '{count}명',
    //
    addAdmin: '관리자 추가',
    adminType: '관리자',
    createAdmin: '관리자 등록',
    addAdminMsg: '관리자를 추가하세요',
    done: '등록 완료',
    breadcrumb: {
      company: '고객사',
      companyCreate: '고객사 등록',
    },
    fm: {
      duplicateAdmin: '이미 목록에 추가된 관리자입니다.',
      inputName: '고객사 명을 입력해주세요.',
      duplicateName: '중복되는 고객사 명이 있습니다. 다른 이름을 입력해주세요.',
      addCompanySuccess: '고객사가 추가되었습니다.',
    },
  },
  // /admin/company/_id --
  adminCompanyId: {
    company: '고객사',
    name: '고객사 명',
    edit: '수정',
    createdAt: '등록 일시',
    updatedAt: '수정 일시',
    projectList: '프로젝트 목록',
    addProject: '프로젝트 등록',
    userCnt: '참여자 : {count} 명',
    editInfo: '프로젝트 정보 수정',
    managerUser: '사용자 관리',
    noProject: '등록된 프로젝트가 없습니다.',
    // 추가
    adminCnt: '관리자',
    count: '{count}명',
    //
    addAdmin: '관리자 추가',
    noAdmin: '관리자가 없습니다.',
    list: '목록으로',
    removeCompany: '고객사 삭제',
    createAdmin: '관리자 등록',
    admin: '관리자',
    removeAdmin: '관리자 삭제',
    remove: '삭제',
    removeAdminMsg: "'{companyName}'고객사에서 '{name}}({username})' 관리자를 삭제하시겠습니까?",
    removeCompany: '고객사 삭제',
    removeCompanyMsg: '{company}고객사를 삭제하시겠습니까?',
    fm: {
      error: '오류가 발생했습니다.',
      removeSuccess: '관리자를 삭제했습니다.',
      inputName: '고객사 명을 입력해주세요.',
      changeName: '고객사 명을 변경해주세요.',
      duplicateName: '중복되는 고객사 명이 있습니다. 다른 이름을 입력해주세요.',
      changeNameSuc: '고객사 명이 변경되었습니다.',
      removeCompanySuc: '고객사가 삭제되었습니다.',
    },
    breadcrumb: {
      company: '고객사',
    },
  },
  // /admin/notice  <<>>> --
  adminNotice: {
    notice: '공지',
    createdNotice: '공지 등록',
    all: '전체 공지',
    specific: '고객사별 공지',
    removeNotice: '공지 삭제',
    remove: '삭제',
    removeNoticeMsg: '선택한 공지를 삭제하시겠습니까?',
    fields: {
      title: '제목',
      updatedAt: '수정일시',
    },
    breadcrumb: {
      list: '공지 List',
    },
    fm: {
      removeSuccess: '공지가 삭제되었습니다.',
    },
  },
  // /admin/notice/create <<>> --
  adminNoticeCreate: {
    management: '공지 관리',
    title: '제목',
    noticeTitle: '공지 제목',
    content: '본문',
    noticeContent: '공지 본문',
    attached: '첨부파일',
    noticeAttached: '공지 첨부파일',
    done: '작성 완료',
    breadcrumb: {
      notice: '공지',
      all: '전체 공지 등록',
    },
    fm: {
      inputTitle: '공지 제목을 입력하세요.',
      inputContent: '공지 본문을 입력하세요.',
      uploadFail: '파일 업로드에 실패했습니다.',
      addNoticeSuccess: '공지가 등록되었습니다.',
    },
  },
  // /admin/notice/_id  <<>> --
  adminNoticeId: {
    management: '공지 관리',
    title: '제목',
    content: '본문',
    attached: '첨부파일',
    list: '목록으로',
    remove: '삭제',
    edit: '수정',
    removeNotice: '공지 삭제',
    remove: '삭제',
    removeNoticeMsg: '공지를 삭제하시겠습니까?',
    breadcrumb: {
      notice: '공지',
    },
    fm: {
      removeSuccess: '공지가 삭제되었습니다.',
    },
  },
  // /admin/notice/_id/edit  <<>> --
  adminNoticeIdEdit: {
    management: '공지 관리',
    title: '제목',
    noticeTitle: '공지 제목',
    content: '본문',
    noticeContent: '공지 본문',
    attached: '첨부파일',
    file: '현재 파일',
    remove: '삭제',
    noticeAttached: '공지 첨부파일',
    done: '수정 완료',
    breadcrumb: {
      notice: '공지',
      edit: '수정',
    },
    fm: {
      changeNotice: '공지가 변경되었습니다.',
      inputTitle: '공지 제목을 입력하세요.',
      inputContent: '공지 본문을 입력하세요.',
      uploadFail: '파일 업로드에 실패했습니다.',
    },
  },
  // /admin/notice/company  <<>> --
  adminNoticeCompany: {
    notice: '공지',
    createNotice: '공지 등록',
    filter: {
      all: '전체 공지',
      specific: '고객사별 공지',
      name: '고객사 명',
      condition: '조건 보기',
    },
    removeNotice: '공지 삭제',
    remove: '삭제',
    removeNoticeMsg: '선택한 공지를 삭제하시겠습니까?',
    fields: {
      company: '고객사',
      title: '제목',
      updatedAt: '수정일시',
    },
    breadcrumb: {
      list: '공지 List',
      specific: '고객사별 공지',
    },
    fm: {
      removeSuccess: '공지가 삭제되었습니다.',
    },
  },
  // /admin/notice/company/create  <<>> --
  adminNoticeCompanyCreate: {
    management: '공지 관리',
    company: '고객사',
    selectedCompany: '고객사 선택',
    selectCompany: '고객사를 선택하세요',
    title: '제목',
    noticeTitle: '공지 제목',
    content: '본문',
    noticeContent: '공지 본문',
    attached: '첨부파일',
    noticeAttached: '공지 첨부파일',
    done: '작성 완료',
    breadcrumb: {
      notice: '공지',
      specific: '고객사별 공지',
      created: '등록',
    },
    fm: {
      uploadFail: '파일 업로드에 실패했습니다.',
      createSuccess: '공지가 등록되었습니다.',
      chooseCompany: '1개 이상의 고객사를 선택하세요.',
      inputTitle: '공지 제목을 입력하세요.',
      inputContent: '공지 본문을 입력하세요.',
    },
  },
  // /admin/notice/company/_id  <<>> --
  adminNoticeCompanyId: {
    management: '공지 관리',
    company: '고객사',
    title: '제목',
    content: '본문',
    attached: '첨부파일',
    list: '목록으로',
    remove: '삭제',
    edit: '수정',
    removeNotice: '공지 삭제',
    removeNoticeMsg: '공지를 삭제하시겠습니까?',
    breadcrumb: {
      notice: '고객사별 공지',
    },
    fm: {
      removeSuccess: '공지가 삭제되었습니다.',
    },
  },
  // /admin/notice/company/_id/edit  <<>> --
  adminNoticeCompanyIdEdit: {
    management: '공지 관리',
    company: '고객사',
    selectedCompany: '고객사 선택',
    selectCompany: '고객사를 선택하세요',
    title: '제목',
    noticeTitle: '공지 제목',
    content: '본문',
    noticeContent: '공지 본문',
    attached: '첨부파일',
    file: '현재 파일',
    remove: '삭제',
    noticeAttached: '공지 첨부파일',
    done: '수정 완료',
    fields: {
      name: '고객사 명',
      projectCnt: '프로젝트 수',
      lineCnt: '라인 수',
      managerCnt: '관리자 수',
      userCnt: '참여자 수',
      createdAt: '등록 일시',
    },
    breadcrumb: {
      specific: '고객사별 공지',
      edit: '수정',
    },
    fm: {
      editSuccess: '공지가 변경되었습니다',
      uploadFail: '파일 업로드에 실패했습니다.',
      chooseCompany: '1개 이상의 고객사를 선택하세요.',
      inputTitle: '공지 제목을 입력하세요.',
      inputContent: '공지 본문을 입력하세요.',
    },
  },
  // /admin/project --
  adminProject: {
    project: '프로젝트',
    createProject: '프로젝트 등록',
    total: '총 {count}개의 프로젝트가 있습니다.',
    filter: {
      company: '고객사 명',
      name: '프로젝트 명',
      code: '프로젝트 코드',
      condition: '조건 보기',
    },
    fields: {
      title: '프로젝트 명',
      code: '프로젝트 코드',
      company: '고객사 명',
      userCnt: '참여자 수',
      lineCnt: '라인 수',
      updatedAt: '최근 수정 일시',
    },
    breadcrumb: {
      list: '프로젝트 List',
    },
    // 추가
    count: '{count}개',
    people: '{people}명',
  },
  // /admin/project/create  <<>> --
  adminProjectCreate: {
    createProject: '프로젝트 등록',
    info: '고객사 정보',
    company: '고객사',
    chooseCompany: '고객사를 선택하세요.',
    selectCompany: '고객사 선택',
    newCompany: '신규 고객사 등록',
    projectInfo: '프로젝트 정보',
    projectTitle: '프로젝트 제목',
    projectCode: '프로젝트 코드',
    checkDuplicate: '중복체크',
    validCode: '등록 가능한 프로젝트 코드입니다.',
    invalidCode: '프로젝트 코드는 등록 이후 수정이 불가능합니다.',
    invalidCode2: "대소문자와 숫자, ‘-’, '_'만 사용 가능합니다.",
    projectDesc: '프로젝트 설명',
    image: '제품 이미지',
    moreImage: '이미지 추가',
    default: '대표',
    setDefault: '대표로 설정',
    addImage: '이미지를 추가하세요',
    lineInfo: '검사라인 정보',
    moreLine: '라인 추가',
    lineName: '라인 이름',
    lineCode: '라인 코드',
    productName: '제품 이름',
    processName: '공정 이름',
    invalidCodeMsg: '라인 코드는 대소문자와 ‘-’, ‘_‘만 사용 가능하며, 다른 라인 코드와 중복될 경우 사용불가합니다.',
    addLine: '라인을 추가하세요',
    done: '등록 완료',
    projectLimitPolicy: '프로젝트 제한 정책',
    usingPeriod: '사용 기간',
    imageUploadCount: '업로드 가능 이미지 장수',
    learnCount: '학습 가능 횟수',
    breadcrumb: {
      project: '프로젝트',
      create: '프로젝트 등록',
    },
    fm: {
      inputCode: '4자 이상의 프로젝트 코드를 입력해주세요.',
      codeEror: '{code}로 시작하는 프로젝트 코드는 사용 불가능합니다.',
      error: '에러가 발생했습니다.',
      errorMsg: '에러 메세지: ',
      createSuccess: '프로젝트 등록이 완료되었습니다.',
      duplicateLineName: '중복된 라인 이름이 있습니다.',
      duplicateLineCode: '중복된 라인 코드가 있습니다.',
      chooseCompany: '고객사를 선택하세요.',
      inputProject: '프로젝트 제목을 입력하세요.',
      checkDuplicate: '프로젝트 코드 중복체크를 진행해주세요.',
      inputLineName: '라인 이름을 입력해주세요.',
      inputLineCode: '라인 코드를 입력해주세요.',
      validateLine: "라인 코드는 0~9, 알파벳 대소문자, '-' 또는 '_'만 입력 가능합니다.",
      inputMoreCode: '라인 코드는 4글자 이상 입력해주세요.',
      inputProductName: '제품 이름을 입력해주세요.',
      inputProcess: '공정 이름을 입력해주세요.',
      validateInteger: '업로드 가능 이미지 장수와 학습 가능 횟수는 숫자만 입력 가능 합니다.',
      validateDate: '사용 기간을 입력해주세요.',
    },
  },
  // /admin/project/_id --
  adminProjectId: {
    project: '프로젝트',
    info: '프로젝트 정보',
    company: '고객사명',
    title: '프로젝트 제목',
    code: '프로젝트 코드',
    createdAt: '등록 일시',
    updatedAt: '수정 일시',
    desc: '프로젝트 설명',
    noDesc: '프로젝트 설명이 없습니다.',
    image: '제품 이미지',
    default: '대표',
    noImage: '이미지가 없습니다.',
    lineInfo: '검사라인 정보',
    noLineInfo: '라인 정보가 없습니다.',
    userInfo: '사용자 정보',
    // 추가
    admin: '관리자',
    user: '참여자',
    count: '{count}명',
    //
    detail: '자세히',
    list: '목록으로',
    removeProject: '프로젝트 삭제',
    edit: '정보 수정',
    remove: '삭제',
    removeProjectMsg: '프로젝트를 삭제하시겠습니까?',
    fields: {
      name: '라인 명',
      code: '라인 코드',
      produceName: '제품',
      processName: '공정',
    },
    breadcrumb: {
      project: '프로젝트',
    },
    fm: {
      removeSuccess: '프로젝트가 삭제되었습니다.',
    },
  },
  // /admin/project/_id/edit  <<>> --
  adminProjectIdEdit: {
    project: '프로젝트',
    companyInfo: '고객사 정보',
    companyName: '고객사명',
    info: '프로젝트 정보',
    title: '프로젝트 제목',
    code: '프로젝트 코드',
    desc: '프로젝트 설명',
    image: '제품 이미지',
    moreImage: '이미지 추가',
    default: '대표',
    setDefault: '대표로 설정',
    addImage: '이미지를 추가하세요',
    lineInfo: '검사라인 정보',
    moreLine: '라인 추가',
    lineName: '라인 이름',
    lineCode: '라인 코드',
    productName: '제품 이름',
    processName: '공정 이름',
    invalidCodeMsg: '라인 코드는 대소문자와 ‘-’, ‘_‘만 사용 가능하며, 다른 라인 코드와 중복될 경우 사용불가합니다.',
    addLine: '라인을 추가하세요',
    done: '수정 완료',
    breadcrumb: {
      project: '프로젝트',
      edit: '수정',
    },
    fm: {
      uploadFail: '이미지 등록에 실패했습니다.',
      editSuccess: '프로젝트가 수정되었습니다.',
      duplicateLineName: '중복된 라인 이름이 있습니다.',
      duplicateLineCode: '중복된 라인 코드가 있습니다.',
      inputProject: '프로젝트 제목을 입력하세요.',
      inputLineName: '라인 이름을 입력해주세요.',
      inputLineCode: '라인 코드를 입력해주세요.',
      validateLine: "라인 코드는 0~9, 알파벳 대소문자, '-' 또는 '_'만 입력 가능합니다.",
      inputMoreCode: '라인 코드는 4글자 이상 입력해주세요.',
      inputProductName: '제품 이름을 입력해주세요.',
      inputProcess: '공정 이름을 입력해주세요.',
    },
  },
  // /admin/project/_id/user  <<>> --
  adminProjectIdUser: {
    project: '프로젝트',
    projectAdmin: '프로젝트 관리자',
    companyAdmim: '고객사 관리자',
    user: '참여자',
    total: '{count}명의 사용자가 있습니다.',
    createUser: '참여자 등록',
    lineInfo: '라인 정보',
    moreLine: '라인 추가',
    morePermission: '권한 추가',
    line: '라인',
    removeUser: '참여자 삭제',
    remove: '삭제',
    removeUserMsg: '{name}({username})를 참여자에서 삭제하시겠습니까?',
    breadcrumb: {
      project: '프로젝트',
      user: '참여자',
    },
    fm: {
      cratedSuccess: '참여자를 추가했습니다.',
      removeSuccess: '참여자가 삭제되었습니다.',
    },
  },
  // /admin/project/_id/user/manager  <<>> --
  adminProjectIdUserManager: {
    project: '프로젝트',
    projectAdmin: '프로젝트 관리자',
    companyAdmim: '고객사 관리자',
    user: '참여자',
    total: '{count}명의 고객사 관리자가 있습니다.',
    moreAdmin: '고객사 관리자 추가',
    createAdmin: '관리자 등록',
    admin: '관리자',
    removeAdmin: '관리자 삭제',
    remove: '삭제',
    removeAdminMsg: "'{company}'고객사에서 '{name}({username})' 관리자를 삭제하시겠습니까?",
    breadcrumb: {
      project: '프로젝트',
      admin: '관리자',
    },
    fm: {
      createSuccess: '고객사 관리자를 추가했습니다.',
      removeSuccess: '고객사 관리자가 삭제되었습니다.',
    },
  },
  // /admin/user --
  adminUser: {
    user: '사용자',
    createUser: '사용자 등록',
    resetRequest: '비밀번호 초기화 요청',
    total: '총 {count}명의 사용자가 있습니다.',
    filter: {
      company: '고객사 명',
      project: '프로젝트 명',
      department: '부서',
      condition: '조건 보기',
    },
    fields: {
      name: '이름',
      company: '고객사',
      role: '유형',
      department: '부서',
      project: '소속 프로젝트',
      createdAt: '등록 일시',
    },
    breadcrumb: {
      list: '사용자 List',
    },
    role: {
      type: '유형',
      system: '시스템 관리자',
      service: '서비스 관리자',
      company: '고객사 관리자',
      user: '사용자',
    },
    // 추가
    count: '{count}개',
  },
  // /admin/user/create --
  adminUserCreate: {
    user: '사용자',
    info: '유저 정보',
    userType: '사용자 유형',
    admin: '관리자',
    general: '일반사용자',
    company: '고객사',
    chooseCompany: '고객사를 선택하세요.',
    selectCompany: '고객사 선택',
    newCompany: '신규 고객사 등록',
    // 추가
    project: '프로젝트',
    count: '{count}개',
    //
    selectProject: '프로젝트 선택',
    lineInfo: '라인 정보',
    moreLine: '라인 추가',
    morePermission: '권한 추가',
    line: '라인',
    chooseProject: '프로젝트를 선택하세요',
    id: '아이디',
    inputId: '아이디를 입력해주세요.',
    checkDuplicate: '중복 확인',
    validId: '사용 가능한 아이디입니다.',
    invalidId: '이미 등록된 아이디입니다.',
    name: '이름',
    inputName: '"이름을 입력해주세요.',
    department: '부서',
    inputDepartment: '부서를 입력해주세요.',
    expiredAt: '계정 만료일',
    done: '등록 완료',
    breadcrumb: {
      user: '사용자',
      createUser: '사용자 등록',
    },
    fm: {
      inputMoreId: '4자 이상의 아이디를 입력해주세요.',
      chooseCompany: '고객사를 선택해주세요.',
      chooseProject: '프로젝트를 선택해주세요.',
      choosePermission: '프로젝트 별 하나 이상의 권한을 선택해주세요.',
      checkDuplicate: '아이디 중복체크를 진행해주세요.',
      duplicatedId: '중복된 아이디는 사용 불가능합니다.',
      inputPw: '비밀번호를 생성해주세요.',
      inputName: '이름을 입력해주세요.',
      inputDepartment: '부서를 입력해주세요.',
      inputTrial: 'Trial 사용자의 계정 만료일을 입력해주세요.',
    },
  },
  // /admin/user/_id  <<>> --
  adminUserId: {
    user: '사용자',
    id: '아이디',
    name: '이름',
    company: '고객사 명',
    department: '부서',
    createdAt: '등록 일시',
    edit: '정보 수정',
    list: '프로젝트 목록',
    default: '기본 프로젝트 설정',
    defaultProject: '기본 프로젝트',
    admin: '관리자',
    user: '참여자',
    permission: '권한',
    done: '권한 수정',
    noProject: '소속된 프로젝트가 없습니다.',
    list: '목록으로',
    removeUser: '사용자 삭제',
    remove: '삭제',
    removeUserMsg: '{username} 사용자를 삭제하시겠습니까?',
    breadcrumb: {
      user: '사용자',
    },
    fm: {
      removeSuccess: '사용자가 삭제되었습니다.',
    },
  },
  // /admin/user/_id/edit <<>> --
  adminUserIdEdit: {
    user: '사용자',
    info: '기본 정보',
    id: '아이디',
    name: '이름',
    department: '부서',
    expiredAt: '계정 만료일',
    pw: '비밀번호',
    newPw: '새 비밀번호',
    done: '수정 완료',
    breadcrumb: {
      user: '사용자',
      edit: '정보 수정',
    },
    fm: {
      editSuccess: '사용자 정보 수정에 성공했습니다.',
      inputName: '이름을 입력해주세요.',
      inputDepartment: '부서를 입력해주세요.',
    },
  },
  // /admin/user/reset  <<>> --
  adminUserReset: {
    user: '사용자',
    resetPw: '비밀번호 초기화 요청',
    unprocessedMsg: '총 {count}건의 미처리 변경 요청이 있습니다.',
    filter: {
      status: '처리상태',
      unprocessed: '미처리',
      done: '변경완료',
      invalid: '처리불가',
      condition: '조건 보기',
    },
    deletedCompany: '삭제된 고객사',
    deletedUser: '삭제된 사용자',
    fields: {
      company: '고객사 명',
      createdAt: '요청일시',
      updatedAt: '처리일시',
      status: '처리상태',
    },
    breadcrumb: {
      list: '사용자 List',
      reset: '비밀번호 초기화 요청',
    },
  },
  // /admin/user/reset/_id   <<>> --
  adminUserResetId: {
    user: '사용자',
    info: '요청 정보',
    id: '아이디',
    status: '처리 상태',
    unprocessed: '미처리',
    done: '변경완료',
    invalid: '처리불가',
    idInfo: '아이디 정보',
    targetId: '변경할 아이디',
    name: '이름',
    company: '고객사',
    type: '유형',
    department: '부서',
    pw: '비밀번호',
    newPw: '새 비밀번호',
    done: '변경 완료',
    list: '목록으로',
    role: {
      system: '시스템 관리자',
      service: '서비스 관리자',
      company: '고객사 관리자',
      user: '사용자',
    },
    breadcrumb: {
      user: '사용자',
      reset: '비밀번호 초기화 요청',
    },
    fm: {
      success: '처리 상태가 변경되었습니다.',
      resetSuccess: '{searchId}의 비밀번호를 초기화했습니다.',
      inputPw: '비밀번호를 입력하세요',
    },
  },
  // /monitoring <<>>
  // /monitoring/model-history  <<>> --
  monitoringModelHistory: {
    history: '모형 이력',
    fields: {
      createdAt: '등록 일자',
      name: '모형 이름',
      input: '학습 데이터 경로',
      rate: '학습 성능',
      class: '정상/불량 유형',
    },
    breadcrumb: {
      history: '기간별 조회',
    },
  },
  // /monitoring/inspection <<>> --
  monitoringInspection: {
    inspection: '성능 검수',
    option: '검수 옵션',
    filter: {
      all: '전체',
      before: '검수 전',
      done: '검수 완료',
      condition: '조건 보기',
    },
    extraction: {
      random: '자동(랜덤 샘플링)',
      active: '자동(액티브 러닝)',
      manual: '수동',
    },
    status: {
      done: '검수 완료',
      before: '검수 전',
    },
    nowWorking: '현재 작업중',
    myWorking: '내가 작업중',
    available: '사용 가능',
    inspect: '검수하기',
    result: '결과보기',
    send: '보내기',
    sendData: '검수 데이터 보내기',
    sendDataMsg: '선택한 {count}개의 검수 데이터를 보내시겠습니까?',
    removeInspection: '성능 검수 삭제',
    remove: '삭제',
    removeInspectionMsg: '선택한 성능 검수를 삭제하시겠습니까?',
    fields: {
      status: '검수 여부',
      model: '현장 적용 모형',
      type: '작업 유형',
      extraction: '추출 유형',
      date: '검수 데이터 기간',
      cnt: '제품 수량 / 검수 데이터 수량',
      user: '검수자',
      performance: '검수 성능',
      busy: '사용 현황',
      inspection: '검수하기',
      result: '검수결과',
      send: '보내기 선택',
      remove: '삭제',
    },
    breadcrumb: {
      list: '성능 검수 List',
    },
    usage: '용도',
    fm: {
      busyMsg: '{username}이(가) 작업 중입니다.',
      removeSuccess: '성능검수건이 삭제되었습니다.',
      sendSuccess: '보내기에 성공했습니다.',
    },
  },
  // /monitoring/inspection/option  <<>> --
  monitoringInspectionOption: {
    inspection: '성능 검수',
    option: '검수 옵션',
    sampling: '샘플링 추가',
    noOption: '현재 설정된 옵션이 없습니다.',
    inactive: '비활성화',
    method: '방법',
    period: '샘플링 주기 설정',
    threshold: '임계값',
    rate: '샘플링 비율',
    samplingTime: '샘플링 시간 설정',
    samplingMsg: 'AM 00:00에 샘플링 됩니다.',
    updateOption: '옵션 적용',
    periodOptions: {
      first: '1일',
      second: '2일',
      third: '3일',
      fourth: '4일',
      fifth: '5일',
      sixth: '6일',
      seventh: '7일',
    },
    fm: {
      inputThreshold: '임계값은 0~1 사이의 수를 입력해야 합니다.',
      inputRate: '샘플링 비율은 0~100 사이의 수를 입력해야 합니다.',
      changeOption: '성능 검수 옵션이 변경되었습니다.',
    },
    breadcrumb: {
      inspection: '성능 검수',
      option: '검수 옵션 설정',
    },
  },
  // /monitoring/inspection/report --
  monitoringInspectionResult: {
    report: '성능 리포트',
    target: '분석 대상',
    period: '대상 기간',
    date: '일시',
    result: '결과 조회',
    trend: '정확도 Trend',
    checkResult: '검사 결과 보기',
    resultCnt: '검수 결과 반영 / 대상 이미지 수 {count}개',
    fieldModel: '현장적용 모형',
    ngRate: '불량률',
    typeCnt: '유형별 검출 건수',
    accuracyRate: '전체 정확도 수치',
    classPrecision: 'Class별 Precision',
    classRecall: 'Class별 Recall',
    classResult: 'Class 별 판정 결과',
    accuracy: '정확도',
    classMAP: 'Class별 mAP',
    editImageCnt: '수정 이미지 개수',
    judgementImage: '판정 이미지',
    inspectionImage: '검수 이미지',
    resultList: '판정 결과 리스트',
    fields: {
      name: 'Class 종류',
      predictionCnt: '모델 판정 수량',
      annotaionCnt: '검수결과 수량',
    },
    filename: '파일 명',
    breadcrumb: {
      report: '기간별 조회',
    },
    positive: '양',
    negative: '불',
    // 추가
    count: '{count}개',
  },
  // /monitoring/inspection/sampling --
  monitoringInspectionSampling: {
    inspection: '성능 검수',
    option: '검수 옵션',
    sampling: '샘플링 추가',
    period: '기간',
    time: '시간',
    samplingCnt: '샘플링 갯수',
    cntWarning: '100 부터 1,000 까지의 숫자만 입력할 수 있습니다.',
    createSampling: '샘플링 생성',
    breadcrumb: {
      inspection: '성능 검수',
      sampling: '샘플링 추가',
    },
    fm: {
      createSuccess: '샘플링 생성에 성공하였습니다.',
    },
  },
  // /monitoring/inspection/_id --
  monitoringInspectionId: {
    result: '성능 검수 결과',
    checkResult: '검사 결과 보기',
    resultCnt: '모형판정 결과 / 대상 이미지 수 {count}개',
    fieldModel: '현장적용 모형',
    ngRate: '불량률',
    typeCnt: '유형별 검출 건수',
    inspect: '검수하기',
    list: '목록으로',
    fields: {
      name: 'Class 종류',
      predictionCnt: '모델 판정 수량',
      annotaionCnt: '검수결과 수량',
    },
    positive: '양',
    negative: '불',
    over: '과검',
    breadcrumb: {
      inspection: '성능 검수',
      result: '결과 보기',
    },
  },
  // /monitoring/inspection/_id/cf  <<>> --
  // /monitoring/inspection/_id/od <<>> --
  monitoringInspectionIdCfOd: {
    inspection: '성능 검수',
    info: '작업 기본 정보',
    type: '작업 유형',
    modelAlgo: '학습 모형 / 알고리즘',
    createdAt: '검사 일시',
    save: '검수 결과 저장',
    timeout: '시간 초과',
    timeoutMsg: '1분 뒤 작업 시간이 종료됩니다. 작업 시간을 연장하시겠습니까?',
    breadcrumb: {
      inspection: '성능 검수',
      monitoring: '검수하기',
    },
    min: '분',
    sec: '초',
    fm: {
      saveSuccess: '검수 결과 저장을 성공하였습니다.',
    },
  },
  // /monitoring/inspection/_id/result/cf --
  // /monitoring/inspection/_id/result/od --
  monitoringInspectionIdResultCfOd: {
    title: '성능 검수 결과',
    checkResult: '검사 결과 보기',
    resultCnt: '검수 결과 반영 / 대상 이미지 수 {count}개',
    fieldModel: '현장적용 모형',
    ngRate: '불량률',
    typeCnt: '유형별 검출 건수',
    performance: '모형 성능',
    result: '검수 결과',
    targetResult: '모형 판정 결과',
    accuracyRate: '전체 정확도 수치',
    classPrecision: 'Class별 Precision',
    classRecall: 'Class별 Recall',
    classAccuracy: 'Class 별 정확도',
    classResult: 'Class 별 판정 결과',
    inspect: '검수하기',
    list: '목록으로',
    fields: {
      name: 'Class 종류',
      predictionCnt: '모델 판정 수량',
      annotaionCnt: '검수결과 수량',
    },
    positive: '양',
    negative: '불',
    cnt: '과검',
    breadcrumb: {
      inspection: '성능 검수',
      result: '결과 보기',
    },
    createdAt: '생성 일시',
    period: '기간',
    accuracy: '정확도',
    classMAP: 'Class별 mAP',
    ngFields: {
      name: '파일명',
    },
  },
  // /monitoring/line <<>> --
  monitoringLine: {
    line: '검사 현황',
    serialNum: '제품 일련번호',
    nowTime: '현재 시각',
    status: '판정 현황 및 수율 (금일 기준)',
    transference: '수율',
    transferenceMsg: '전체 {total}건 중 NG {count}건이 발생했습니다.',
    resultList: '판정 결과 리스트',
    checkError: '불량만 보기',
    lastUpdatedAt: '마지막 업데이트',
    fields: {
      serialNum: '일련번호',
      result: '판정',
    },
    breadcrumb: {
      line: '현장 모니터링',
    },
  },
  // /monitoring/line/report --
  monitoringLineReport: {
    report: '검사 리포트',
    byPeriod: '기간별 조회',
    byCharacter: '문자열 조회',
    period: '대상기간',
    date: '일시',
    time: '시간',
    checkResult: '결과 조회',
    errorInfo: '불량 검출 정보',
    // 추가
    byProductCnt: '제품 개수 기준',
    six: '6시간',
    twelve: '12시간',
    day: '1일',
    //
    totalCnt: '전체 검사 제품 건수',
    totalCntInfo: '건 ({direction}종 방향 / 총 {totalCount} 이미지)',
    errorCnt: '불량 검출 건수',
    errorCntInfo: '건 (제품 방향 총 개수 기준)',
    avgTransference: '제품 단위 일별 평균 수율',
    maxTransference: '제품 단위 일별 최고 수율',
    minTransference: '제품 단위 일별 최저 수율',
    trend: '기간별 Trend',
    loading: '다시 불러오고 있습니다.',
    periodError: '해당 기간내 불량 제품',
    typeErrorCnt: '유형별 불량 개수',
    aiModel: 'AI 모형',
    errorImage: '유형별 불량 이미지',
    fm: {
      diffError: '조회 기간은 30일을 초과할 수 없습니다.',
    },
    chart: {
      error: '불량률',
      cnt: '제품 개수',
    },
    ko: '한국어',
    hour: '시간',
    min: '분',
    sec: '초',
    increase: '증가',
    decrease: '감소',
    selected: '선택됨',
    noSelected: '선택된 시간 없음',
    close: '닫기',
    breadcrumb: {
      report: '기간별 조회',
    },
    current: '현재',
  },
  // /monitoring/line/report/search <<>> --
  monitoringLineReportSearch: {
    report: '검사 리포트',
    byPeriod: '기간별 조회',
    byCharacter: '문자열 조회',
    serialNum: '제품 일련번호',
    checkResult: '결과 조회',
    createdAt: '판정일시',
    origin: '원본 이미지',
    judgement: '판정 이미지',
    fm: {
      searchResultError: '해당 문자열로 조회되는 제품이 없습니다.',
      inputSerialNum: '검색할 제품 일련변호를 입력해주세요.',
    },
    // 추가
    breadcrumb: '문자열 조회',
  },
  // 컴포넌트 modal-model-history-detail <<>> --
  modalModelHistoryDetail: {
    period: '적용 기간',
    createdAt: '학습 일자',
    trainingPerformance: '학습 성능',
    trainingCnt: '학습 수량',
    inferenceDate: '검증 일자',
    inferPerfomance: '검증 성능',
    inferenceCnt: '검증 수량',
    algorithm: '알고리즘',
    edit: '수정',
    close: '닫기',
    detail: '모형 이력 상세',
    fm: {
      done: '설명이 수정되었습니다.',
    },
  },
  // 컴포넌트 modal-confusion-matrix-result  <<>> --
  modalConfusionMatrixResult: {
    total: '총 {count}개',
    close: '닫기',
  },
  // 컴포넌트 markup-classification-guide
  markupClassificationGuide: {},
  // 컴포넌트 modal-monitoring-result <<>> --
  modalMonitoringResult: {
    result: '판정 결과',
    origin: '원본 이미지',
    judgement: '판정 이미지',
    close: '닫기',
    direction1: '방향1',
    direction2: '방향2',
  },
  // 컴포넌트 modal-line-type-report <<>> --
  modalLineTypeReport: {
    errorImage: '유형별 불량 이미지',
    period: '기간',
    name: '모형 이름',
    algorithm: '알고리즘',
    type: '유형',
    selectType: '유형을 선택하세요',
    totalImage: '(이미지{count}개)',
    origin: '원본 이미지',
    judgement: '판정 이미지',
    resultList: '판정 결과 리스트',
    count: '개',
    totalCnt: ' (검출 건수 : {prediction}개)',
    image: '이미지 보기',
    close: '닫기',
    fields: {
      name: '파일 명',
    },
  },
  // 컴포넌트 modal-line-period-report --
  modalLinePeriodReport: {
    errorImage: '기간내 불량 이미지',
    period: '기간',
    origin: '원본 이미지',
    judgement: '판정 이미지',
    resultList: '판정 결과 리스트',
    image: '이미지 보기',
    close: '닫기',
    fields: {
      serialNum: '일련번호',
      judgement: '판정',
    },
    // 추가
    count: '{count}개',
  },
  // 컴포넌트 modal-user-add <<>> --
  modalUserAdd: {
    type: '참여자 유형',
    new: '신규 사용자',
    old: '기존 사용자',
    trial: 'Trial 여부',
    default: '일반',
    id: '아이디',
    inputId: '아이디를 입력해주세요.',
    checkDuplicate: '중복 확인',
    validId: '사용 가능한 아이디입니다.',
    invalidId: '이미 등록된 아이디입니다.',
    name: '이름',
    inputName: '이름을 입력해주세요.',
    department: '부서',
    inputDepartment: '부서를 입력해주세요.',
    expiredAt: '계정 만료일',
    permission: '권한',
    addMsg: '등록하기',
    selectedUser: '사용자 선택',
    chooseUser: '사용자를 선택하세요.',
    selectedData: '데이터 선택',
    cancel: '취소',
    add: '등록',
    duplicateIdMsg1: '{username}는 이미 등록된 아이디입니다.',
    duplicateIdMsg2: '해당 아이디를 사용자로 등록하시겠습니까?',
    filter: {
      name: '이름',
      department: '부서',
      condition: '조건 보기',
    },
    title: '참여자 등록',
    fields: {
      name: '이름',
      department: '부서',
      createdAt: '등록일시',
    },
    fm: {
      inputValidId: '4자 이상의 아이디를 입력해주세요.',
      duplicateUser: '이미 등록된 사용자입니다. 새 아이디를 입력해주세요.',
      inputId: '아이디를 입력해주세요.',
      checkDuplicate: '아이디 중복확인을 진행해주세요',
      createPw: '비밀번호를 생성해주세요.',
      inputName: '이름을 입력해주세요.',
      inputDepartment: '부서를 입력해주세요.',
      inputTrial: '계정 만료일을 입력해주세요.',
      choosePermission: '하나 이상의 권한을 선택해주세요.',
      chooseUser: '사용자를 선택해주세요.',
    },
    count: '{count}개',
  },
  // 컴포넌트 modal-manager-add <<>> --
  modalManagerAdd: {
    id: '아이디',
    inputId: '아이디를 입력해주세요.',
    checkDuplicate: '중복 확인',
    validId: '사용 가능한 아이디입니다.',
    invalidId: '이미 등록된 아이디입니다.',
    name: '이름',
    inputName: '이름을 입력해주세요.',
    department: '부서',
    inputDepartment: '부서를 입력해주세요.',
    addMsg: '등록하기',
    duplicateIdMsg: '{username}는 이미 등록된 아이디입니다. 해당 아이디를 사용자로 등록하시겠습니까?',
    cancel: '취소',
    add: '등록',
    invalidIdMsg: '{username}는 사용 불가능한 아이디입니다. 다른 아이디를 입력해주세요.',
    title: '관리자 등록',
    admin: '관리자',
    fm: {
      inputValidId: '4자 이상의 아이디를 입력해주세요.',
      invalidRoleId: '관리자로 등록할 수 없는 계정입니다.',
      invalidId: '사용할 수 없는 아이디입니다.',
      inputId: '아이디를 입력해주세요.',
      checkDuplicate: '아이디 중복확인을 진행해주세요',
      createPw: '비밀번호를 생성해주세요.',
      inputName: '이름을 입력해주세요.',
      inputDepartment: '부서를 입력해주세요.',
    },
    // 추가
    duplicateIdMsg1: '{username}는 이미 등록된 아이디입니다.',
    duplicateIdMsg2: '해당 아이디를 사용자로 등록하시겠습니까?',
    duplicateIdMsg3: '해당 아이디를 관리자로 등록하시겠습니까?',
  },
  // 컴포넌트 modal-multiple-select-datas <<>> --
  modalMultipleSelectDatas: {
    done: '선택 완료',
    sample: '샘플 이미지',
  },
  // 컴포넌트 modal-project-select  <<>> --
  modalProjectSelect: {
    filter: {
      company: '고객사 명',
      name: '프로젝트 명',
      code: '프로젝트 코드',
      condition: '조건 보기',
    },
    done: '선택 완료',
    title: '프로젝트 선택',
    fields: {
      title: '프로젝트 명',
      code: '프로젝트 코드',
      company: '고객사',
      user: '참여자',
      line: '라인',
      createdAt: '등록일시',
    },
    count: '{count}개',
    people: '{count}명',
  },
  // 컴포넌트 modal-user-permission-edit  <<>> --
  modalUserPermissonEdit: {
    title: '권한 수정',
    permission: '권한',
    close: '닫기',
    edit: '수정',
  },
  // 컴포넌트 algorithm-parameter-create-form  <<>> --
  algorithmParameterCreateForm: {
    totalCnt: '총 {count} 개',
    name: '파라미터 명',
    key: '파라미터 Key',
    desc: '설명',
    addParams: '파라미터를 추가하세요',
    add: '추가',
  },
  // 컴포넌트 modal-change-company-name  <<>> --
  modalChangeCompanyName: {
    title: '고객사명 변경',
    name: '고객사 명',
    inputName: '고객사 이름을 입력해 주세요',
    cancel: '취소',
    edit: '수정',
  },
  // 컴포넌트 modal-company-select  <<>> --
  modalCompanySelect: {
    filter: {
      name: '고객사 명',
      condition: '조건 보기',
    },
    done: '선택 완료',
    title: '고객사 선택',
    fields: {
      name: '고객사 명',
      projectCnt: '프로젝트 수',
      linceCnt: '라인 수',
      managerCnt: '관리자 수',
      userCnt: '참여자 수',
      createdAt: '등록일시',
    },
  },
  // 컴포넌트 modal-default-project-setting <<>> --
  modalDefaultProjectSetting: {
    title: '기본 프로젝트 설정',
    sub: '로그인 시 지정한 프로젝트로 이동합니다.',
    admin: '관리자',
    user: '참여자',
    permission: '권한',
    noProject: '프로젝트가 없습니다.',
    close: '닫기',
    done: '선택 완료',
  },
  // 컴포넌트 modal-change-user-name <<>> --
  modalChangeUserName: {
    title: '사용자 이름 변경',
    name: '이름',
    inputName: '사용자 이름을 입력해 주세요',
    cancel: '취소',
    edit: '수정',
  },
  // 컴포넌트 modal-learning-compare-add <<>> --
  modalLearningCompareAdd: {
    selectedModel: '대상 모형 선택',
    chooseModel: '대상 모형을 선택하세요.',
    classInfo: 'Class 정보',
    inferParams: '추론 파라미터 상세 정보',
    memo: '메모 작성',
    infer: '성능평가 시작',
    addModel: '평가 대상 모형 추가',
    fm: {
      incorrectClass: 'Class(순서,이름,OK여부) 정보가 일치하지 않습니다.',
      chooseModel: '대상 모형을 선택해주세요.',
      addModel: '모형이 추가되었습니다.',
    },
  },
  // 컴포넌트 train-class-info <<>> --
  trainClassInfo: {
    inputClass: 'Class 이름 입력',
    addClass: 'Class 추가',
    maxClass: 'Class는 최대 34개까지 설정 가능',
    existedClass: '이미 존재하는 Class입니다.',
    removeClass: 'Class 삭제',
    remove: '삭제',
    removeClassMsg1: '본 Class를 삭제하면 해당 Class Labeling 작업내용이 모두 삭제됩니다.',
    removeClassMsg2: 'Class를 삭제하시겠습니까?',
  },
  // 컴포넌트 workspace-folder-display <<>> --
  workspaceFolderDisplay: {
    unclassified: '미분류',
    total: '전체',
  },
  // 컴포넌트 annotation-cf-image-card <<>> --
  annotationCfImageCard: {
    aug: '증강',
  },
  // 컴포넌트 canvas-classificate-workspace --
  canvasClassificateWorkspace: {
    hide: '기본 정보 닫기',
    show: '기본 정보 표시',
    selectedImageCnt: '선택된 이미지 {count}개',
    total: '이미지 전체 선택',
    fileName: '파일 이름',
    unclassified: '미분류',
    removeImage: '이미지 삭제',
    copyFileName: '파일명 복사',
    noImageMsg: '이미지가 없습니다.',
    imageArr: '이미지 배열',
    firstRow: '1열',
    secondRow: '2열',
    thirdRow: '3열',
    fourthRow: '4열',
    classification: 'Class 분류',
    add: '추가',
    total: '전체',
    inputClass: 'Class 명을 입력하세요',
    inspectStatus: '검수 현황',
    changeHistory: '변경 이력',
    noHistory: '변경 이력이 없습니다.',
    editHistory: '수정현황',
    checkingImage: '이미지를 확인 중입니다.',
    savingData: '데이터를 저장중입니다.',
    loadingData: '데이터를 불러오고 있습니다.',
    remove: '삭제',
    // 추가
    confirmSelectedRemove: '선택한 이미지를 삭제하시겠습니까?',
    images: '{count}장',
    //
    confirmRemove: '선택한 {count}개의 이미지를 삭제하시겠습니까?',
    checkImage: '이미지 보기',
    fields: {
      name: 'Class명',
      predictions: '판정결과',
      inspection: '검수결과',
    },
    imageFields: {
      name: '파일 명',
      predictions: '판정결과',
      inspection: '검수결과',
    },
    fm: {
      moreClass: '2개 이상의 Class 정보를 추가해주세요.',
      addClass: 'Class 정보를 추가해주세요.',
      checkedOk: 'OK여부가 체크된 Class가 최소 1개 이상 필요합니다.',
      uncheckedOk: 'OK여부가 체크 해제된 Class가 최소 1개 이상 필요합니다.',
      removeWarning: '성능 검수에서는 이미지 삭제를 하실 수 없습니다.',
      categoryMinImage: 'Class 별 최소 1장의 이미지를 가져야합니다.',
    },
  },
  //컴포넌트 modal-class-edit <<>> --
  modalClassEdit: {
    title: 'Class 정보 수정',
    close: '닫기',
    edit: '수정',
    duplicateWarning: '중복된 Class는 사용할 수 없습니다',
  },
  // 컴포넌트 modal-relearning-select <<>> --
  modalRelearningSelect: {
    modelName: '모형 이름',
    condition: '조건 보기',
    selectDone: '선택 완료',
    title: '학습 모형 선택',
    fields: {
      type: '작업 유형',
      modelName: '모형 이름',
      algorithms: '알고리즘',
      createdAt: '일시',
    },
    algorithm: '알고리즘',
  },
  // 컴포넌트 modal-confusion-matrix-image <<>> --
  modalConfusionMatrixImage: {
    origin: '원본 이미지',
    result: '결과 이미지',
    list: '이미지 리스트',
    totalCnt: '총 {count}개',
    checkImage: '이미지 보기',
    noImage: '해당 Case에서는 표시할 이미지가 없습니다',
    close: '닫기',
    fields: {
      fileName: '파일명',
    },
  },
  // 컴포넌트 modal-compare-image <<>> --
  modalCompareImage: {
    checkImage: '이미지 보기',
    fileName: '파일명',
    origin: '원본 이미지',
    result: '결과 이미지',
    close: '닫기',
  },
  // 컴포넌트 train-class-learning-info <<>> --
  trainClassLearningInfo: {
    ratio: '샘플링 비율',
  },
  // 컴포넌트 markup-result-none  <<>> --
  markupResultNone: {
    title: '데이터가 없습니다.',
  },
  // 컴포넌트 annotation-objectdetection <<>> --
  annotationObjectdetection: {
    full: '전체 보기',
    wide: '작업 영역 크게 보기',
    fold: '작업 정보 영역 접기',
    unfold: '작업 정보 영역 펼치기',
    zoomIn: '[Ctrl] + 마우스 휠, 숫자 키패드 [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠, 숫자 키패드 [-], [Ctrl] + [-]',
    fixZoom: '배율 고정',
    remove: '삭제',
    resultImage: '판정 결과 이미지',
    enlarge: '확대',
    classInfo: 'Class 정보',
    add: '추가',
    merge: '병합',
    className: 'Class 이름',
    inputClass: 'Class 명을 입력하세요',
    changeClass: 'Class 이름 변경',
    dataList: '데이터 목록',
    fileName: '파일 이름',
    search: '검색',
    unconfirmed: '미확인 데이터',
    modiStatus: '수정현황',
    noImage: '이미지가 없습니다.',
    removeImage: '이미지 삭제',
    removeImageMsg1: '선택한 {image}이미지를 삭제하시겠습니까?',
    removeImageMsg2: '삭제된 이미지는 복구할 수 없습니다.',
    removeImageMsg3: '선택한 {count} 개의 이미지를 삭제하시겠습니까?',
    filter: {
      all: '전체',
      edited: '변경',
      unconfirmed: '미확인',
    },
    categoryFields: {
      name: 'Class 이름',
    },
    imageFields: {
      imageName: '파일명',
    },
    fm: {
      limitClass: '클래스는 34개까지 설정할 수 있습니다.',
      duplicateClass: '중복된 Class명이 존재합니다.',
      chooseRemoveImage: '삭제할 이미지를 선택해주세요.',
      removeWarning: '성능 검수에서는 이미지 삭제를 하실 수 없습니다.',
      imageWarning: '이미지를 불러올 수 없습니다.',
      // 추가
      selectClass: '클래스를 선택해주세요.',
    },
    // 추가
    moreData: '데이터 더 가져오기',
  },

  // 컴포넌트 annotation-Segmentation <<>> --
  annotationSegmentation: {
    full: '전체 보기',
    wide: '작업 영역 크게 보기',
    fold: '작업 정보 영역 접기',
    unfold: '작업 정보 영역 펼치기',
    zoomIn: '[Ctrl] + 마우스 휠, 숫자 키패드 [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠, 숫자 키패드 [-], [Ctrl] + [-]',
    fixZoom: '배율 고정',
    remove: '삭제',
    resultImage: '판정 결과 이미지',
    enlarge: '확대',
    classInfo: 'Class 정보',
    add: '추가',
    merge: '병합',
    className: 'Class 이름',
    inputClass: 'Class 명을 입력하세요',
    changeClass: 'Class 이름 변경',
    dataList: '데이터 목록',
    fileName: '파일 이름',
    search: '검색',
    unconfirmed: '미확인 데이터',
    modiStatus: '수정현황',
    noImage: '이미지가 없습니다.',
    removeImage: '이미지 삭제',
    removeImageMsg1: '선택한 {image}이미지를 삭제하시겠습니까?',
    removeImageMsg2: '삭제된 이미지는 복구할 수 없습니다.',
    removeImageMsg3: '선택한 {count} 개의 이미지를 삭제하시겠습니까?',
    filter: {
      all: '전체',
      edited: '변경',
      unconfirmed: '미확인',
    },
    categoryFields: {
      name: 'Class 이름',
    },
    imageFields: {
      imageName: '파일명',
    },
    fm: {
      limitClass: '클래스는 34개까지 설정할 수 있습니다.',
      duplicateClass: '중복된 Class명이 존재합니다.',
      chooseRemoveImage: '삭제할 이미지를 선택해주세요.',
      removeWarning: '성능 검수에서는 이미지 삭제를 하실 수 없습니다.',
      imageWarning: '이미지를 불러올 수 없습니다.',
      // 추가
      selectClass: '클래스를 선택해주세요.',
    },
    // 추가
    moreData: '데이터 더 가져오기',
    npyLoadError: 'npy 파일을 불러올 수 없어 SAM 라벨링 사용이 불가합니다.',
    onnxLoadError: 'onnx 파일을 불러올 수 없습니다.',
  },
  // 컴포넌트 modal-fabric-canvas-zoom <<>> --
  moadlFabricCanvasZoom: {
    title: '이미지 보기',
    fileName: '파일명',
    zoomIn: '[Ctrl] + 마우스 휠, 숫자 키패드 [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠, 숫자 키패드 [-], [Ctrl] + [-]',
    close: '닫기',
  },
  // 컴포넌트 markup-load-labeling <<>> --
  markupLoadLabeling: {
    loading: '로딩 중입니다.',
  },
  // 컴포넌트 annotation-classification <<>> --
  annotationClassification: {
    close: '기본 정보 닫기',
    show: '기본 정보 표시',
    selectedImageCnt: '선택된 이미지 {count}개',
    totalImageCnt: '이미지 전체 선택',
    fileName: '파일 이름',
    unclassified: '미분류',
    removeImage: '이미지 삭제',
    duplicateFileName: '파일명 복사',
    noImage: '이미지가 없습니다.',
    onlyAugData: '증강 데이터만 보기',
    imageArr: '이미지 배열',
    rowNumb: '{number}열',
    firstRow: '1열',
    secRow: '2열',
    thrdRow: '3열',
    fthRow: '4열',
    imageInfoVisible: '이미지 별 정보 표시',
    className: 'Class 명',
    isAugmentation: '증강 여부',
    isEdit: '수정 여부',
    classScore: 'Class Score',
    classification: 'Class 분류',
    add: '추가',
    merge: '병합',
    remove: '삭제',
    all: '전체',
    totalCnt: '{count}장',
    inputClass: 'Class 명을 입력하세요',
    inspectStatus: '검수 현황',
    changeHistory: '변경 이력',
    noHistory: '변경 이력이 없습니다.',
    editHistory: '수정현황',
    removeImage: '이미지 삭제',
    remove: '삭제',
    confirmMsg1: '선택한 {count}개의 이미지를 삭제하시겠습니까?',
    confirmMsg2: '삭제된 이미지는 복구할 수 없습니다.',
    checkImage: '이미지 보기',
    fields: {
      className: 'Class명',
      judgement: '판정결과',
      inspection: '검수결과',
    },
    modifiedFields: {
      fileName: '파일 명',
      judgement: '판정결과',
      inspection: '검수결과',
    },
    fm: {
      classLimitMsg: '클래스는 34개까지 설정할 수 있습니다.',
      duplicateClass: '중복된 Class명이 존재합니다.',
      removeWarning: '성능 검수에서는 이미지 삭제를 하실 수 없습니다.',
    },
    // 추가
    confirmSelected: '선택한 이미지를 삭제하시겠습니까?',
    moreData: '데이터 더 가져오기',
    selectedTh: '선택한 threshold',
  },
  // 컴포넌트 annotation-basic-info  <<>> --
  annotationBasicInfo: {
    title: '작업 기본 정보',
    inspectionTitle: '검사 이름',
    workType: '작업 유형',
    check: '보기',
    modelAlgorithms: '학습 모형 / 알고리즘',
    inspectedAt: '검사 일시',
    duration: '검사 소요 시간',
    detail: '상세 설정',
    checkDetail: '상세 정보 보기',
    workName: '작업 이름',
    inputData: '사용 데이터 경로',
    outputData: '결과 데이터 경로',
    createdAt: '생성 일시',
    updatedAt: '수정 일시',
    preModel: 'Pre-trained 모델',
    algorithmName: '알고리즘 이름',
    detailDesc: '상세 설정 정보',
    inputWorkName: '원본 작업 이름',
    classParamsInfo: '클래스 별 증강 파라미터 정보',
    mergeTarget: '병합 대상 작업',
    fm: {
      changeDesc: 'Description이 변경되었습니다.',
    },
  },
  // 컴포넌트 annotation-cf-guide  <<>>  --
  annotationCfGuide: {
    guide: '편리한 사용 가이드',
    all: '전체 선택',
    ctrlA: '컨트롤을 누른 채로 A',
    multiple: '복수 선택',
    ctrlLeft: '컨트롤을 누른 채로 좌클릭',
    scope: '범위 지정 선택',
    shftLeft: '쉬프트를 누른 채로 좌클릭',
    labeling: 'Labeling 분류',
    rightInput: '우클릭 후 해당 폴더의 번호 입력',
    removeImage: '이미지 삭제',
    rightRemove: '우클릭 후 Delete 또는 Backspace (지우기)',
    undo: '실행 취소',
    ctrlZ: '컨트롤을 누른 채로 Z',
    cmmA: 'Command를 누른 채로 A',
    cmmLeft: 'Command를 누른 채로 좌클릭',
  },
  // 컴포넌트 workspace-folder-display : 미분류, 전체

  // 컴포넌트 modal-processing-external-labeling
  modalExternalLabelingUpload: {
    cancel: '취소',
    type: '외부 Labeling 유형',
    algorithmType: '알고리즘 유형',
    workName: '작업 이름',
    uploadDataset: '원본 이미지 업로드',
    existingDataset: '기존 데이터셋 사용',
    uploadBbox: 'Bbox 정보 업로드',
    selectFile: '파일 선택',
    notDuplicated: '사용 가능한 작업 이름입니다.',
    duplicated: '이미 등록된 작업 이름입니다.',
    inputWorkName: '작업 이름을 입력해주세요.',
    inputDatasetName: '데이터셋 이름을 입력해주세요.',
    datasetDuplicated: '이미 등록된 데이터셋 이름입니다.',
    bboxNameDuplicated: '중복된 bbox 파일명이 존재합니다.',
    imageDatasetValidate: '이미지 데이터셋 확장자는 ".png", ".jpg"만 허용됩니다.',
    mismatchedName: '원본 이미지 파일명과 일치하지 않는 Bbox 파일명이 존재합니다.',
    extensionValidate: 'bbox 파일의 확장자가 외부 라벨링 유형과 다릅니다.',
    classFileValidate: '.txt 파일만 업로드가 가능합니다.',
    success: '외부 라벨링 작업이 생성되었습니다.',
    hover: {
      explain: '데이터 관리 탭에서 업로드한 데이터셋을 사용합니다.',
    },
    placeholder: {
      workName: '작업 이름을 입력하세요.',
      datasetName: '데이터셋 이름을 입력하세요.',
      imageDatasetUpload: '이미지 데이터셋을 업로드하세요.',
      imageValidateFile: '파일명에 한글과 특수문자(_제외)가 있는 경우, 해당 문자들은 생략되어 업로드됩니다.',
      bboxValidateFile: '원본 이미지 파일명과 일치하지 않는 Bbox 파일명이 있을 경우 업로드가 불가능합니다.',
      classUpload: 'Class명 정보 txt 파일을 업로드하세요.',
      BboxUpload: 'Bbox 정보 파일을 업로드하세요.',
    },
  },

  // 컴포넌트 modal-processing-class-merge <<>> --
  modalProcessingClassMerge: {
    inputMerge: '병합 Class 입력',
    resultName: '병합 결과 Class 이름',
    inputResult: '병합 결과 Class 이름을 입력하세요.',
    add: '추가',
    selectedMerge: '병합할 Class 선택 ({count}개 선택)',
    cancel: '취소',
    merge: '병합',
    fm: {
      noClass: '병합할 Class가 없습니다.',
      inputMergeClass: '병합할 Class에 추가할 Class명을 입력해주세요.',
      duplicateClass: '이미 병합할 Class에 추가된 Class입니다.',
      notExistedClass: '등록되지 않은 Class입니다.',
      inputMerge: '병합할 Class 이름을 입력하세요.',
      chooseMoreClass: '병합할 Class를 2개 이상 선택해주세요.',
      noDuplicatedClass: '중복된 카테고리 명은 등록할 수 없습니다.',
    },
  },
  // 컴포넌트 modal-processing-class-remove <<>> --
  modalProcessingClassRemove: {
    removeClass: 'Class 삭제',
    remove: '삭제',
    confirmMsg: '해당 Class를 삭제하시겠습니까?',
    desc1: '(삭제한 Class의 파일들은 미분류로 이동됩니다.)',
    desc2: '삭제한 Class의 Bbox는 모두 삭제됩니다.',
    fm: {
      choose: '삭제할 Class를 선택해주세요.',
    },
  },
  // 컴포넌트 modal-setting-detail <<>> --
  modalSettingDetail: {
    detail: '상세 설정 정보',
    trainParams: '학습 파라미터',
    inferParams: '추론 파라미터',
    aug: 'Augmentation 설정',
    close: '닫기',
  },
  // 컴포넌트 modal-processing-augmentation-setting-detail  <<>> --
  modalProcessingAugmentationSettingDetail: {
    detail: '상세 설정 정보',
    close: '닫기',
  },
  // 컴포넌트 algorithm-parameter-input-form <<>> --
  algorithmParameterInputForm: {
    desc1: 'Target Region 사용 시, 데이터셋 내 이미지들의 가로/세로 크기가 모두 동일해야 합니다.',
    desc2: '그렇지 않을 경우, Input Error가 발생합니다.',
    fmWarning: '{item}의 설명이 없습니다.',
  },
  // 컴포넌트 annotation-target-region <<>>
  annotationTargetRegion: {
    full: '전체 보기',
    wide: '작업 영역 크게 보기',
    fold: '작업 정보 영역 접기',
    unfold: '작업 정보 영역 펼치기',
    zoomIn: '[Ctrl] + 마우스 휠, 숫자 키패드 [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠, 숫자 키패드 [-], [Ctrl] + [-]',
    fixZoom: '배율 고정',
    classInfo: 'Class 정보',
    regionInfo: 'Region 정보',
    dataList: '데이터 목록',
    regionFields: {
      position: '좌표 정보',
    },
    imageFields: {
      imageName: '파일명',
    },
    fm: {
      imageWarning: '이미지를 불러올 수 없습니다.',
      targetWarning: '최대 10개의 Target 영역을 추가할 수 있습니다.',
      // 추가
      selectClass: '클래스를 선택해주세요.',
      duplicatedClass: '중복된 Class명이 존재합니다.',
    },
    // 추가
    add: '추가',
    delete: '삭제',
    className: 'Class 이름',
    inputClass: 'Class 명을 입력하세요',
    changeName: 'Class 이름 변경',
    deleteClass: 'Class 삭제',
    classMsg: '해당 Class를 삭제하시겠습니까?',
    classMsg2: '삭제한 Class의 Patch, Bbox는 모두 삭제됩니다.',
  },
  // 컴포넌트 annotation-od-current-info-display <<>> --
  annotationCurrentInfoDisplay: {
    close: '기본 정보 닫기',
    show: '기본 정보 표시',
    size: '사이즈',
    selectedClass: '선택 중인 Class',
    selectedBbox: '선택 중인 Bbox',
  },
  // 컴포넌트 modal-dataset-select <<>> --
  modalDatasetSelect: {
    imageCnt: '이미지 {num}개',
    updatedAt: '최종수정 일자',
    cancel: '취소',
    select: '선택',
    title: 'Dataset 선택',
    noData: '데이터가 없습니다.',
  },
  // 컴포넌트 modal-processing-datas <<>> --
  modalProcessingData: {
    title: '데이터 선택',
    checkCond: '조건 보기',
    type: '유형',
    usage: '용도',
    cancel: '취소',
    done: '선택 완료',
    fields: {
      title: 'Work 명',
      processType: '가공 유형',
      workType: '작업 유형',
      usageType: '용도',
      createdAt: '일시',
    },
  },
  // 컴포넌트 modal-learning-select <<>> --
  modalLearningSelect: {
    modelName: '모형 이름',
    checkCond: '조건 보기',
    type: '유형',
    done: '선택 완료',
    title: '학습 모형 선택',
    algorithms: '알고리즘',
    fields: {
      createdAt: '일시',
      learningEndTime: '학습 완료 일시',
      name: '모형 이름',
      algorithmTitle: '알고리즘',
    },
  },
  // 컴포넌트 modal-processing-sample-image <<>> --
  modalProcessingSampleImages: {
    title: '샘플 이미지',
    close: '닫기',
  },
  // 컴포넌트 modal-processing-control <<>> --
  modalProcessingControl: {
    desc1: '해당 Data set은 학습 혹은 성능평가에 사용된 이력이 있습니다.',
    desc2: '작업 수정을 원하시면 "Labeling 작업 복사하기"를,',
    desc3: '해당 라벨링 작업 내용만 확인하기를 원하시면 "Labeling" 진행 내용 보기"를 선택해주세요',
    desc4: '해당 Data set은 이미 최종 저장되었습니다.',
    copyLabeling: 'Labeling 작업 복사하기',
    showLabeling: 'Labeling 진행 내용 보기',
  },
  // 컴포넌트 modal-processing-copy <<>> --
  modalProcessingCopy: {
    title: 'Labeling 작업 복사',
    workName: '작업 이름',
    folderName: 'Labeling 결과 저장 폴더이름',
    projectName: 'Labeling 결과 저장 프로젝트',
    inputWorkName: '작업 이름을 입력하세요.',
    inputFolderName: '폴더 이름을 입력하세요',
    inputProject: '프로젝트를 선택 하세요',
    chooseProject: '프로젝트 선택',
    copyAnotherProject: '다른 프로젝트로 복사',
    sameWorkName: '작업이름과 동일하게 설정',
    copyError: '이미지 데이터 복사 중 오류가 발생하였습니다.',
    desc1: '데이터 가공 작업 복사 시, 원본 데이터 경로에 추가/삭제된 데이터가 동기화됩니다.',
    desc2: "추가된 데이터는 '미분류'로 본류",
    confirmCopy: '복사를 진행하시겠습니까?',
    cancel: '취소',
    copy: '복사하기',
    copyDone: '데이터 복사가 시작되었습니다.',
    copyFail: '복사 작업 생성에 실패하였습니다.',
    overWriteDataset: '데이터셋 확인',
    duplicateDataset: '중복된 데이터셋이 존재합니다. ({currentIndex}/{totalCount})',
    overWrite: '덮어쓰기',
    skip: '건너뛰기',
    allOverWrite: '모두 덮어쓰기',
    allSkip: '모두 건너뛰기',
  },
  // 컴포넌트 modal-processing-merge <<>> --
  modalProcessingMerge: {
    title: '데이터 가공 병합',
    workName: '작업 이름',
    resultFolderName: 'Labeling 결과 저장 폴더이름',
    sameWorkName: '작업이름과 동일하게 설정',
    folderName: '폴더 이름',
    textarea: '메모 작성',
    selectedData: '선택한 데이터',
    chooseMerge: '병합할 데이터 선택',
    checkCond: '조건 보기',
    type: '유형',
    cancel: '취소',
    runLabeling: 'Labeling 수행',
    fields: {
      title: 'Work 명',
      processTypeId: '가공 유형',
      usageTypeId: '용도',
      createdAt: '일시',
    },
    workType: '작업 유형',
    nameValidator: {
      title: 'Labeling 결과 저장 폴더 이름',
      msg: "0~9, 알파벳 대소문자, '_' 또는 '-'만 입력 가능합니다.",
    },
    fm: {
      startMerge: '데이터 병합이 시작되었습니다.',
      mergeFail: '병합 작업 생성에 실패하였습니다.',
      chooseName: '작업 이름을 입력해주세요.',
      chooseMerge: '두개 이상의 병합할 데이터를 선택해주세요',
      inputResultName: 'Labeling 결과 저장 폴더 이름을 입력해주세요.',
    },
    duplicateClassNameMsg: '클래스명 중복 시 상위 데이터의 정보로 덮어쓰게 됩니다.',
  },
  // 컴포넌트 modal-description <<>> --
  modal: {
    close: '닫기',
    edit: '수정',
  },
  // 컴포넌트 modal-dataset-folder-rename <<>> --
  modalDatasetFolderRaname: {
    title: '폴더이름 변경',
    folderName: '폴더 이름',
    placeholder: '폴더 이름을 입력하세요',
    rename: '변경하기',
    fm: {
      duplicate: '중복된 폴더명이 있습니다. 다른 폴더명을 입력해주세요.',
      rename: '기존 폴더명과 동일합니다. 변경할 폴더명을 입력해주세요.',
    },
  },
  // 컴포넌트 modal-dataset-download-request-list  <<>> --
  modalDatasetDownloadRequestList: {
    title: '다운로드 요청 목록',
    cancelAlert: '창을 닫아도 다운로드 작업은 취소되지 않습니다.',
    autoSearch: '자동 조회',
    removeAfter: '{date}일 후 내역 삭제',
    requesting: '요청 중',
    root: '경로',
    fileCount: '파일 개수',
    resultNone: '다운로드 요청 내역이 없습니다.',
    close: '닫기',
    // 추가
    dataset: '데이터셋',
    result: '라벨링 결과',
    // 추가
    workName: '라벨링 작업명',
  },
  // 컴포넌트 modal-image-zoom <<>> --
  modalImageZoom: {
    fileName: '파일명',
    zoomIn: '[Ctrl] + 마우스 휠, 숫자 키패드 [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠, 숫자 키패드 [-], [Ctrl] + [-]',
    close: '닫기',
    checkImage: '이미지 보기',
    bboxList: 'Bbox 리스트',
  },
  // layouts-admin
  // - layout-global-navigation-bar  <<>> --
  layoutGlobalNavigationBar: {
    uTitle: 'U+ AI비전검사',
    user: '{username}님',
    intro: 'MAVIN-Cloud 소개',
    alert: '알림',
    noAlert: '알림이 없습니다.',
    allAlert: '모든 알림 보기',
    my: '마이페이지',
    notice: '공지사항',
    download: '다운로드',
    status: '이용 현황',
    center: '고객센터',
    login: '로그인',
    logout: '로그아웃',
    logoutMsg: '로그아웃 하시겠습니까?',
    shortName: {
      labeling: '가공',
      modeling: '모형',
      compare: '비교',
      training: '학습',
      infernece: '성능',
    },
    longName: {
      labeling: '데이터 가공',
      modeling: '비교 성능 모형 추가',
      compare: '비교 성능 평가',
      training: '학습 수행',
      infernece: '성능 평가',
    },
    // noticication: {
    done: '> 작업이 완료 되었습니다.',
    process: '> 작업이 진행 되었습니다.',
    // },
    hour: '시간',
    min: '분',
    sec: '초',
    take: '소요',
    inputProject: '프로젝트 제목을 입력하세요.',
  },
  // - layout-admin-side-navigation-bar  <<>> --
  layoutAdminSideNavigationBar: {
    admin: '대시보드',
    company: '고객사 관리',
    project: '프로젝트 관리',
    user: '사용자 관리',
    algorithm: '알고리즘 관리',
    notice: '공지 관리',
  },
  // layouts-default
  // - layout-side-navigation-bar  <<>> --
  layoutSideNavigationBar: {
    menu: {
      my: '내 정보',
      changePw: '비밀번호 변경',
      projects: '프로젝트 관리',
      usageProjects: '프로젝트 사용량',
      user: '사용자 관리',
      notifications: '알림 이력',
    },
  },
  // - layout-copyright  <<>> --
  layoutCopyright: {
    title: 'LG전자 PRI 제조AI솔루션팀',
    copyright: 'Copyright © 2022 MAVIN Cloud All rights reserved',
    privacy: '개인정보처리방침',
  },
  // layouts-fluid
  // - cs-iframe  <<>> --
  csIframe: {
    center: '고객센터 바로가기',
  },
  // layouts-monitoring
  // privacyPolicy 개인정보처리방침 --
  privacyPolicy: {
    title: 'LG전자 개인정보 처리방침',
    subTitle:
      'LG전자는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」  및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.',
    article1: {
      title: '제1조(개인정보의 처리 목적)',
      sub1: 'LG전자는 MAVIN-Cloud 서비스 홈페이지(',
      sub2: ') 회원제 운영에 있어 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.',
      part1: '1. 홈페이지 회원가입 및 관리',
      desc1:
        '회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지 목적으로 개인정보를 처리합니다.',
      part2: '2. 민원사무 처리',
      desc2:
        '민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.',
      part3: '3. 재화 또는 서비스 제공',
      desc3: '서비스 제공, 계약서·청구서 발송, 요금결제·정산을 목적으로 개인정보를 처리합니다.',
      part4: '4. 서비스 이용 통계',
      desc4: '접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.',
    },
    article2: {
      title: '제2조(개인정보의 처리 및 보유 기간)',
      sub1: 'LG전자는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리· 보유합니다.',
      sub2: 'LG전자는 MAVIN-Cloud서비스 이용계약에서 정한 계약기간이 만료된 후 3개월간 개인정보를 보유합니다. 단, 관계법령에 의해 보존할 경우 그 의무기간 동안 별도 보관합니다.',
    },
    article3: {
      title: '제3조(처리하는 개인정보의 항목)',
      sub1: 'LG전자는 이름, 로그인ID, 비밀번호, 이메일, 회사명, 부서, 접속로그, 서비스 이용기록을 처리합니다.',
    },
    article4: {
      title: '제4조(개인정보의 파기절차 및 파기방법)',
      sub1: 'LG전자는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.',
      sub2: '정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.',
      sub3: '개인정보 파기의 절차 및 방법은 다음과 같습니다.',
      part1: '1. 파기절차',
      desc1:
        ' LG전자는 파기 사유가 발생한 개인정보를 선정하고, LG전자의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.',
      part2: '2. 파기방법',
      desc2:
        'LG전자는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄 하거나 소각하여 파기합니다.',
    },
    article5: {
      title: '제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)',
      sub1: '정보주체는 LG전자에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.',
      sub2: '제1항에 따른 권리 행사는LG전자에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 LG전자는 이에 대해 지체 없이 조치하겠습니다.',
      sub3: '제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.',
      sub4: '개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.',
      sub5: '개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.',
      sub6: 'LG전자는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.',
    },
    article6: {
      title: '제 6조(개인정보의 위탁에 관한 사항)',
      sub1: 'LG전자는 서비스 이행을 위해 개인정보 처리업무를 아래와 같이 외부 전문 업체에 위탁하여 운영하고 있습니다. 위탁 계약 시 개인정보가 안전하게 보호될 수 있도록 필요한 서약을 규정해 관련 법규를 준수하고 이를 감독하고 있습니다. 위탁하는 업무의 변경이 발생할 경우 웹사이트의 공지사항을 통해 안내 드리겠습니다.',
      part1: '위탁업체',
      desc1: '위탁업무 내용',
      part2: '㈜클라우드메이트',
      desc2: '서비스 및 인프라 운영관리, 서비스 인프라 개발 및 고도화, 서비스 모니터링',
    },
    article7: {
      title: '제7조(개인정보의 안전성 확보조치에 관한 사항)',
      sub1: 'LG전자는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.',
      part1: '1. 내부관리계획의 수립 및 시행',
      desc1: '개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.',
      part2: '2. 개인정보 취급 직원의 최소화 및 교육',
      desc2:
        '개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.',
      part3: '3. 정기적인 자체 감사 실시',
      desc3: '개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.',
      part4: '4. 개인정보에 대한 접근 제한',
      desc4:
        '개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.',
      part5: '5. 접속기록의 보관 및 위변조 방지',
      desc5:
        '개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. <br /> 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다. ',
      part6: '6. 개인정보의 암호화',
      desc6:
        '이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.',
      part7: '7. 해킹 등에 대비한 기술적 대책',
      desc7:
        'LG전자는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.',
      part8: '8. 비인가자에 대한 출입 통제',
      desc8:
        '개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.',
      part9: ' 9. 문서보안을 위한 잠금장치 사용',
      desc9: '개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.',
    },
    article8: {
      title: '제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항',
      sub1: 'LG전자는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.',
    },
    article9: {
      title: '제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)',
      sub1: 'LG전자는 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.',
    },
    article10: {
      title: '제10조 (개인정보 보호책임자에 관한 사항)',
      sub1: 'LG전자는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.',
      part1: '	개인정보 보호책임자 : LG전자 정보보호담당',
      part2: '	개인정보 운영책임자 : PRI 제조AI솔루션팀장',
      part3: '	전화 번호 :  031-8054-2132',
      part4: '	이메일 주소 : priaiplatform.cs@lge.com',
      sub2: '정보주체께서는 LG전자의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. LG전자는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.',
    },
    article11: {
      title: '제11조(개인정보의 열람청구를 접수·처리하는 부서)',
      sub1: '정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. LG전자는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.',
      part1: '▶ 개인정보 열람청구 접수·처리 부서',
      part2: '•	부서명 : PRI 제조AI솔루션팀',
      part3: '•	연락처 : 031-8054-2132, priaiplatform.cs@lge.com',
    },
    article12: {
      title: '제12조(정보주체의 권익침해에 대한 구제방법)',
      sub1: '정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.',
      part1: '1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)',
      desc1: '2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)',
      part2: '3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)',
      desc2: '4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)',
      part3:
        'LG전자는 정보주체의 개인정보자기결정권을 보장하고, 개인정보 침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.  ',
      desc3: ' 개인정보보호 관련 고객 상담 및 신고 ',
      part4: '	부서명 : PRI 제조AI솔루션팀',
      desc4: '	연락처 : 031-8054-2132, priaiplatform.cs@lge.com',
      part5:
        '「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.',
      desc5: ' 중앙행정심판위원회 : (국번없이) 110(www.simpan.go.kr)    ',
    },
    article13: {
      title: '제13조(개인정보 처리방침 변경)',
      sub1: '이 개인정보처리방침은 2023년 5월 1부터 적용됩니다.',
      part1: '이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.',
      desc1: '- 해당 없음',
    },
  },
  // 추가요청
  // modal-password-alarm <<>> --
  modalPasswordAlarm: {
    delay: '3개월 뒤에 변경하기',
    now: '지금 변경하기',
  },
  // workspace-title  <<>> --
  workspaceTitle: {
    title: '라인 검사 이름',
  },
  //모달 공통
  close: '닫기',
  confirm: '확인',
  cancel: '취소',
  // modal-dataset-upload --
  modalDatasetUpload: {
    title: '업로드',
    purpose: '데이터 사용 목적',
    processing: '데이터 가공',
    inference: '단일 성능 평가',
    type: 'Dataset 타입',
    new: '신규',
    existing: '기존 Dataset',
    name: 'Dataset 이름',
    inputName: '이름을 입력하세요',
    chooseDataset: 'Dataset을 선택하세요',
    selectDataset: 'Dataset 선택',
    classification: 'Class 분류',
    checking: 'Classification, Anomaly Detection용 Dataset일 경우',
    success: '성공 {count}개',
    fail: '실패 {count}개',
    loadingOld: '기존 Dataset 클래스 불러오기',
    uploading: '파일 업로드',
    fileCnt: '{count}개/{max}개',
    uploadFile: '파일을 업로드하세요',
    validateFile: '파일명에 한글과 특수문자(_ 제외)가 있는 경우, 해당 문자들은 생략되어 업로드됩니다.',
    selectClass: 'Class 선택',
    addNewClass: 'Class가 없는 폴더입니다.신규 Class를 추가해주세요.',
    duplicateFoldername: '폴더명 중복',
    duplicateFoldernameMsg: '중복된 폴더명이 있습니다. 해당 폴더로 업로드를 진행하시겠습니까?',
    overLimit: '최대 업로드 개수 초과',
    trialMsg: 'Trial 계정의 경우, 한 번에 최대 3000장까지만 이미지 업로드가 가능합니다.',
    limitMsg: '업로드 가능한 이미지 수는 최대 {limit} 장이므로 업로드가 불가합니다.',
    limitAlert: '현재 업로드 가능한 이미지 수: {count} ',
    cancel: '취소',
    upload: '업로드',
    fm: {
      chooseType: '타입을 선택해주세요',
      duplicateClass: '중복된 클래스명이 존재합니다.',
      inputDataset: 'Dataset 이름을 입력해주세요.',
      chooseDataset: 'Dataset을 선택해주세요.',
      inputClass: '클래스명을 입력해주세요.',
      chooseFile: '업로드할 파일을 선택해주세요.',
      includeNew: '모든 신규 클래스에 업로드할 파일을 선택해주세요.',
      // 추가
      invalid: '{name}은(는) 폴더명으로 사용할 수 없습니다.',
      classChecked: '영어 대/소문자, -, _ 로 이루어진 이름의 파일만 선택가능합니다.',
    },
  },
  // train-dataset-class-input  --
  trainDatasetClassInput: {
    selectFile: '파일 선택',
    inputClass: 'Class 이름을 입력하세요',
    add: '추가',
    validateFile: '파일명에 한글과 특수문자(_ 제외)가 있는 경우, 해당 문자들은 생략되어 업로드됩니다.',
  },
  // modal-processing-ai-labeling --
  modalProcessingAiLabeling: {
    title: 'AI Labeling One-Click Process 재진행',
    basicInfo: '재진행을 위한 기본 정보',
    workName: '작업 이름',
    resultFolderName: 'Labeling 결과 저장 폴더이름',
    sameWorkName: '작업이름과 동일하게 설정',
    folderName: '폴더 이름',
    manualLabeling: '수동 Labeling 재진행',
    manualDesc: '데이터를 추가/삭제하여 1단계-수동 라벨링을 재진행할 수 있습니다.',
    algorithmReset: '알고리즘 정보 재설정',
    algorithmDesc: '생성할 학습 모델 정보를 재설정할 수 있습니다.',
    selectAlgorithm: '알고리즘 선택',
    checkAlgoInfo: '알고리즘 설명 보기',
    detail: '상세설정',
    trainParams: '학습 파라미터',
    inferParams: '추론 파라미터',
    augParams: 'augmentation 설정',
    desc: '메모 작성',
    cancel: '취소',
    run: 'AI Labeling 수행',
    chooseAlgorithm: '알고리즘을 선택하세요',
    fm: {
      selectData: 'One-Click Process 재진행을 진행할 데이터 가공 건을 선택해주세요.',
      title: 'Labeling 결과 저장 폴더이름',
      success: '데이터 병합이 시작되었습니다.',
      inputWorkName: '작업 이름을 입력해주세요.',
      inputFolderName: 'Labeling 결과 저장 폴더 이름을 입력해주세요.',
      invalidName: '입력할 수 없는 폴더 이름입니다. 다른 폴더 이름을 입력해주세요.',
      duplicateFolderName: '이미 존재하는 폴더 이름입니다. 다른 폴더 이름을 입력해주세요.',
      noAlgorithmImage: '알고리즘 설명 이미지가 없습니다.',
    },
  },
  // annotation-cf-ai-labeling-untrained-result --
  annotationCfAiLabelingUntrainedResult: {
    title: 'AI Labeling 작업 결과',
    resultSample: 'Labeling 결과 Sample',
    scatter: 'Data 산점도',
    full: '전체 보기',
    scatterFull: 'Data 산점도 전체보기',
    scatterWide: 'Data 산점도 크게보기',
  },
  // input-password
  inputPassword: {
    label: '비밀번호',
    placeholder: '비밀번호를 입력해주세요.',
    new: '비밀번호 생성',
  },
  // annotation-od-ai-result-thumb-list
  annotationOdAiResultThumb: {
    hidden: '모아보기 영역 닫기',
    show: '모아보기 영역 표시',
    gather: '데이터 이미지 모아보기',
    image: '이미지 보기',
  },
  // admin/statistics/train
  adminStatisticsTrain: {
    filter: '조건보기',
    total: '총 진행 건수',
    count: '{count} 건',
    model: '모형 학습',
    progressLearnCnt: '학습 진행 건수',
    progressInferCnt: '추론 진행 건수',
    watingLearnCnt: '학습 대기 건수',
    watingInferCnt: '추론 대기 건수',
    learningList: '학습 진행 리스트',
    learningWaiting: '학습 대기 리스트',
    inferenceList: '추론 진행 리스트',
    inferenceWaiting: '추론 대기 리스트',
    training: '학습',
    typeProcessing: '가공 유형',
    typeWork: '작업 유형',
    workCnt: '작업 건수',
    algorithm: '알고리즘',
    trainingCnt: '학습 진행 건수',
    avgTime: '평균 소요 시간',
    inferCnt: '평가 진행 건수',
    breadcrumb: {
      statistics: '통계 조회',
      history: '사용 현황 조회',
      trend: '월 별 Trend',
    },
    targetOpt: {
      total: '전체',
      pri: 'PRI 전용',
    },
    usage: '이용 현황',
    untrained: '비지도',
    trained: '지도',
    single: '단일 성능 평가',
    comparative: '비교 성능 평가',
  },
  myStatistics: {
    service: '시스템 관리자',
    history: '사용 현황 조회',
    trend: '월 별 Trend 조회',
    dash: '대시보드',
    status: '자원 사용 현황',
    autoRefresh: '자동 새로고침',
    learningProgress: '진행',
    learningWaiting: '대기',
    resource: 'Resource Monitoring',
  },
  // resource-monitoring
  resourceMonitoring: {
    info: '자원 사용 현황',
    breadcrumb: 'Resource Monitoring',
    autoRefresh: '자동 새로고침',
    learningProgress: '진행',
    learningWaiting: '대기',
    count: '{count} 건',
    progressLearnCnt: '학습 진행 건수',
    progressInferCnt: '추론 진행 건수',
    watingLearnCnt: '학습 대기 건수',
    watingInferCnt: '추론 대기 건수',
    learningList: '학습 진행 리스트',
    learningWaitingList: '학습 대기 리스트',
    inferenceList: '추론 진행 리스트',
    inferenceWaitingList: '추론 대기 리스트',
    fields: {
      startTime: '시작 일시',
      createdAt: '요청 일시',
      updateUserName: '요청자',
      workName: '작업명',
      progress: '진행률',
      learnDuration: '진행 시간',
      waitDuration: '대기 시간',
      algorithm: '알고리즘',
      workTypeName: '작업 유형명',
      projectName: '프로젝트명',
    },
  },
  // annotation-od-ai-labeling-untrained-result
  annotationOdAiLabelingUntrainedResult: {
    result: '기준 이미지에서 Key Point를 추출한 결과',
    full: '전체보기',
    wide: '크게보기',
  },
  // modal-manual-dataset
  modalManualDataset: {
    title: '수동 Labeling 대상 이미지',
    selectedImages: '선택된 이미지 {image}개',
    selectAll: '이미지 전체 선택',
    unclassified: '미분류',
    none: '이미지가 없습니다.',
    cancel: '취소',
    select: '추가',
    noData: '데이터가 없습니다.',
    fm: {
      selectImage: '추가할 이미지를 선택해주세요.',
    },
  },
  // annotation-cf-ai-labeling-result-sample-card
  annotationCfAiLabelingResultSampleCard: {
    image: '이미지 보기',
  },
  // annotation-board
  annotationBoard: {
    unfold: '클래스/데이터 영역 펼치기',
    hide: '클래스/데이터 영역 숨기기',
  },
  // annotation-data-display
  annotationDataDisplay: {
    hide: '기본 정보 닫기',
    show: '기본 정보 표시',
    size: '사이즈',
    selectedClass: '선택 중인 Class',
    currentData: '현재 작업중인 data 파일 이름',
    wh: '가로 x 세로',
  },
  // annotation-info-display
  annotationInfoDisplay: {
    class: 'Class 정보',
    list: '데이터 목록',
  },
  // annotation-toolbar
  annotationToolbar: {
    select: '선택',
    tool: '선택툴',
    draw: '그리기',
    dia: '도형툴',
    selectBrush: 'Brush 선택',
    brush: '브러쉬',
    zoom: '줌',
  },
  // canvas-fabric-workspace
  canvasFabricWorkspace: {
    deleteSelected: '지정 내용 삭제',
    zoomIn: '[Ctrl] + 마우스 휠,숫자 키패드 [+],[Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠,숫자 키패드 [-],[Ctrl] + [-]',
    ratio: '배율',
    fix: '고정',
    wide: '작업 영역 크게 보기',
    full: '전체 보기',
    resultImage: '판정 결과 이미지',
    enlarge: '확대',
    edit: '수정',
    unchecked: '미확인 데이터',
    fileName: '파일 이름',
    search: '검색',
    removeSelected: '선택 삭제',
    editStatus: '수정현황',
    checkingImage: '이미지를 확인 중입니다.',
    savingData: '데이터를 저장중입니다.',
    loadingData: '데이터를 불러오고 있습니다.',
    deleteImage: '이미지 삭제',
    delete: '삭제',
    deleteImageConfirm: '이미지를 삭제하시겠습니까?',
    deleteImageConfirm2: '선택한 {images}개의 이미지를 삭제하시겠습니까?',
    categoryFields: {
      className: 'Class 이름',
    },
    radioFilters: {
      all: '전체',
      edited: '변경',
      incomplete: '미확인',
    },
    anotFields: {
      fineName: '파일명',
    },
    fm: {
      loadingError: '이미지를 불러올 수 없습니다.',
      addClass: 'Class 정보를 추가해주세요.',
      selectToDelete: '삭제할 이미지를 선택해주세요.',
    },
  },
  // input-date-range-picker
  inputDateRangePicker: {
    select: '조회 기간 선택',
    pw: '비밀번호',
  },
  // markup-image-view
  markupImageView: {
    zoom: '[Ctrl] + 마우스 휠',
  },
  // markup-prediction-view
  markupPredictionView: {
    zoomIn: '[Ctrl] + 마우스 휠, 숫자 키패드 [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + 마우스 휠, 숫자 키패드 [-], [Ctrl] + [-]',
  },
  // markup-simple-company-view, markup-simple-datas-view
  markupSimpleCompanyView: {
    total: '{name} 외 {rest}',
    items: '개',
  },
  // modal-password-reset
  modalPasswordReset: {
    title: '비밀번호를 잊으셨나요?',
    desc: '위 메일로 비밀번호 초기화 요청 시 비밀번호 변경이 가능합니다.',
    close: '닫기',
    fm: {
      inputId: '아이디를 입력하세요',
      existed: '이미 비밀번호 초기화를 요청한 사용자입니다. 관리자에게 문의해주세요.',
    },
  },
  // notices
  notices: {
    title: '공지사항',
    all: '전체 공지',
    company: '고객사별 공지',
    department: '조직별 공지',
    fields: {
      title: '제목',
      updatedAt: '작성일시',
    },
    attached: '첨부파일',
    list: '목록보기',
    download: '다운로드',
    downloadMc1: '마빈 1.0 다운로드',
    downloadMc2: '마빈 2.0 다운로드',
    support: '지원',
    support2Yolo: '마빈2.0용 YOLO 지원',
    manual: '매뉴얼',
    support2Yolo8Env: 'yolov8 지원',
  },
  // use-history
  useHistory: {
    breadcrumb: 'Use History',
    title: '사용 이력 조회',
    history: '사용 현황 조회',
    trend: '월 별 Trend',
    processType: '가공 유형',
    workType: '작업 유형',
    newLabeling: '신규',
    mergeLabeling: '병합',
    aiLabelingTrained: 'AI 라벨링\n지도',
    aiLabelingUntrained: 'AI 라벨링\n비지도',
    augmentedLabeling: '증강',
    externalLabeling: '외부',
    count: '건',
  },
  // result-class-info-tooltip
  resultClassInfo: {
    className: 'Class 이름',
    imageName: '해당 이미지 이름',
    outlier: 'Outlier',
    median: 'Median',
    noData: '데이터가 없습니다.',
  },
};
