import * as Cookies from 'js-cookie';

export default async ({ $axios, store, app, route, req }) => {
  const token = sessionStorage.getItem('x-access-mavin');
  store.dispatch('setBasicInfo');

  if (token) {
    app.$axios.setToken(token, 'Bearer'); // axios 헤더 토큰 넣기
    const cookie_current_project = Cookies.get('current_project');
    const cookie_current_line = Cookies.get('current_line');
    let currentProject = null;
    let currentLine = null;

    if (cookie_current_project) {
      currentProject = JSON.parse(cookie_current_project);
    }
    if (cookie_current_line) {
      currentLine = JSON.parse(cookie_current_line);
    }

    await store.dispatch('setToken', { token });
    await store.dispatch('getUserInfo', { currentProject, currentLine });
    // store.dispatch('getCommons')
  } else if (!token) {
    // store.dispatch('logout')
  }
  // else {
  //     store.dispatch('refreshToken')
  // }

  /*
    // if on the browser, parse the cookies using js-cookie otherwise parse from the raw http request
    getItem: key => process.client ? Cookies.getJSON(key) : cookie.parse(req.headers.cookie || '')[key],
        // js-cookie can handle setting both client-side and server-side cookies with one method
        // use isDev to determine if the cookies is accessible via https only (i.e. localhost likely won't be using https)
        setItem: (key, value) => Cookies.set(key, value, { expires: 14, secure: !isDev }),
        // also allow js-cookie to handle removing cookies
        removeItem: key => Cookies.remove(key)
    */
};
