
export default {
  props: {
    modalId: {
      type: String,
      required: false,
      default: 'downloadRequestList',
    },
  },
  data() {
    return {
      isInit: false,
      isLoading: true,
      showIsLoading: true,
      autoSearch: true,
      autoSearchFn: null,
      items: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      autoSearchTime: 3000,
      activeTab: 0,
    };
  },
  computed: {
    imageUrl() {
      return this.$store.state.mavin_core_image;
    },
    project() {
      return this.$store.getters.current_project;
    },
    projectCode() {
      return this.project.code;
    },
    username() {
      return this.$store.getters.userInfo.username;
    },
  },
  watch: {
    currentPage() {
      this.getList();
    },
    autoSearch(val) {
      if (val && !this.isInit) {
        if (this.autoSearchFn) {
          clearInterval(this.autoSearchFn);
        }
        this.autoSearchFn = setInterval(() => {
          this.getList();
        }, this.autoSearchTime);
      } else {
        clearInterval(this.autoSearchFn);
        this.autoSearchFn = null;
      }
    },
    activeTab() {
      this.search();
    },
  },
  methods: {
    async onShownModal() {
      if (this.$route.name.includes('processing')) {
        this.activeTab = 1;
      } else {
        this.activeTab = 0;
      }
      this.isInit = false;
      await this.$forceUpdate();
      await this.$nextTick();

      this.autoSearch = true;
      this.isLoading = true;
      this.showIsLoading = true;
      this.isInit = true;
      this.items = [];
      this.search();
    },
    closeModal() {
      this.$store.dispatch('hideDownloadRequestModal');
      // this.$root.$emit('bv::hide::modal', this.modalId)
    },
    search() {
      this.isInit = true;
      if (this.currentPage === 1) {
        this.getList();
      } else {
        this.currentPage = 1;
      }

      if (this.autoSearchFn) {
        clearInterval(this.autoSearchFn);
      }
      this.autoSearchFn = setInterval(() => {
        this.getList();
      }, this.autoSearchTime);
    },
    async getList() {
      try {
        this.isLoading = true;
        if (this.isInit) {
          this.showIsLoading = true;
          this.isInit = false;
        }

        let url = '';
        const params = {
          project_id: this.project.id,
          page: this.currentPage,
          perPage: this.perPage,
        };

        if (this.activeTab) {
          // 라벨링 결과
          url = '/api/v2/output/download/request';
        } else {
          // 데이터셋
          url = '/api/v2/download/request';
        }

        const {
          data: {
            data: { data, total },
          },
        } = await this.$axios.get(url, {
          params,
        });

        this.items = data.map((item) => {
          const today = this.$moment().set({ hour: 0, minute: 0, second: 0 });
          const createdAt = this.$moment(item.created_at).set({ hour: 0, minute: 0, second: 0 });
          const diff = today.diff(createdAt, 'days');
          const expired = 3 - (diff ? diff : 0);

          return {
            ...item,
            file_count: item.origin_files ? item.origin_files.split(',').length : 0,
            expired_date: expired < 0 ? 0 : expired,
            origin_path: item.origin_path
              ? item.origin_path
              : `project/${item.project_code}/data/${
                  item.usage_type_code === 'test' ? 'inference' : item.usage_type_code
                }/${item.work_output}`,
          };
        });

        this.totalRows = total;
        this.isLoading = false;
        this.showIsLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.showIsLoading = false;
        console.log(e);
      }
    },
    download(item) {
      const imagePathRoot = this.imageUrl + this.projectCode;
      const filePath = `/download/${this.username}/${item.job_id}/${item.file_name}`;

      // const image = await fetch(imagePathRoot + filePath)
      // const imageBlog = await image.blob()
      // const imageURL = URL.createObjectURL(imageBlog)

      const linkTag = document.createElement('a');
      linkTag.setAttribute('href', imagePathRoot + filePath);
      linkTag.setAttribute('download', item.file_name);
      linkTag.setAttribute('target', '_blank');
      linkTag.click();
    },
    onHiddenModal() {
      if (this.autoSearchFn) {
        clearInterval(this.autoSearchFn);
        this.autoSearchFn = null;
      }

      this.$store.dispatch('hideDownloadRequestModal');
      this.$emit('hidden');
    },
  },
};
