import { render, staticRenderFns } from "./AdminSideNavigationBar.vue?vue&type=template&id=2c5da70d&scoped=true"
import script from "./AdminSideNavigationBar.vue?vue&type=script&lang=js"
export * from "./AdminSideNavigationBar.vue?vue&type=script&lang=js"
import style0 from "./AdminSideNavigationBar.vue?vue&type=style&index=0&id=2c5da70d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5da70d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutCopyright: require('/usr/src/app/client/components/layout/copyright.vue').default})
