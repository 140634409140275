export default {
  language: {
    ko: 'Korean',
    en: 'English',
  },
  //공통
  pri: 'LGE PRI Mfg. AI Solution Team',
  // 컴포넌트 markup-loading <<>>
  loading: 'Loading Data',

  // 공통 - 숫자 관련
  count: 'items',
  num: 'images',

  // 공통 - breadcrumb
  breadcrumb: {
    top: 'Top',
  },

  // flashmessage
  fm: {
    nameValidator: {
      title: 'Invalid character',
      msg: "Only 0-9, upper/lower-case alphabet, '-' or '_' are allowed",
      // 추가
      moreMsg: "Only 0-9, upper/lower-case alphabet, '@','-' or '_' are allowed",
    },
    validateFolderName: 'Invalid folder name. Please enter a different folder name.',
    duplicateFolderName: 'Existing folder name. Please enter a different folder name.',
  },
  // 상단바
  noti: 'Notice',
  csCenter: 'Customer Center',
  // 사이드 하단
  privacy: 'Privacy Policy',
  // auth/login <<>> --
  login: {
    title: 'AI Image Inspection Platform',
    subTitle: 'A Cloud-based AI Vision Inspection Platform Designed For Everyone',
    id: 'ID',
    inputId: 'Please enter ID',
    pw: 'Password',
    inputPw: 'Please enter password',
    login: 'Login',
    rememberId: 'Remember ID',
    newId: 'How to request a new ID',
    forgetPw: 'Forgot password?',
    resetPw: 'Password Renewal',
    resetPwMsg: 'Requested password renewal to system administrator.',
    resetPwInfo: 'If you need password renewal, ',
    resetPwInfo2: 'Please Forward your Request to PRI-AIT-Platform@lge.com.',
    pwAlarm: 'Password Notice',
    pwAlarmMsg1: 'Your password has been renewed by system administrator',
    pwAlarmMsg2: 'Taking you to password change page.',
    pwAlarmMsg3: "You haven't changed password in 3 months.",
    pwAlarmMsg4: 'Please change your password.',
    info: 'MAVIN-Cloud is optimized for Chrome web browser.',
    info2: "If you don't have Chrome installed, we advise you to install Chrome and use MAVIN-Cloud.",
    fm: {
      id: 'Please enter ID',
      pw: 'Please enter password',
    },
    changePw: 'Please change your password',
  },
  // index  <<>>
  home: {
    noIncluded: 'You are not included in any project.',
    askAdmin: 'Please request help to system administrator.',
    noPermission: 'You do not have permission to the selected project.',
    aiModel: 'AI Model Training',
    aiField: 'AI Line Inspection',
    serviceAdmin: 'Service Administrator',
    error: {
      projectDeniedTitle: 'Access to the project has expired.',
      projectDeniedContent: 'If you wish to extend the period, please contact pri-aiplatform-cs@lge.com.',
    },
  },

  // /train --
  dataset: 'Data Management',
  processing: 'Data Preprocessing',
  learning: 'Model Training',
  inference: 'Performance Evaluation',
  line: 'Line Status',
  lineReport: 'Inspection Report',
  inspection: 'Performance Verification',
  inspectReport: 'Performance Report',
  modelHistory: 'Model History',

  // /train/dataset 데이터관리 //
  td: {
    breadCrumbTop: 'Top folder',
    breadCrumbCurr: 'Preprocessing',
    folder: 'Folders',
    noFolder: 'No folder',
    file: 'Files',
    noFile: 'No file',
    placeHolderFile: 'File name',
    search: 'Search',
    learning: 'Training',
    upload: 'Upload files',
    downloadFile: 'Download files',
    fileDownloadList: 'File Download List',
    finalChanged: 'Last modified',
    inputImage: 'Input Images',
    renameFolder: 'Rename folder',
    remove: 'Remove',
    removeFolder: 'Remove folder',
    downloadFolder: 'Download folder',
    download: 'Download',
    condition: {
      name: 'Name',
      date: 'Date',
      asc: 'Ascending',
      desc: 'Descending',
    },
    lastUploadedDate: 'Last Uploaded',
    uploadFail: 'Failed to upload dataset',
    confirmRemove: 'Do you want to remove the {name} folder?',
    uploadFailMsg: 'Failed to upload {count} files out of {total} files.',
    nthFile: '{index}-th file',
    checkImage: 'View image',
    // 추가
    items: '{count}items', // items
    selectedImages: '{count} Selected',
    deselectAll: 'Deselect All',
  },
  // 데이터관리 flashmessage
  tdFm: {
    fileUploadSuc: 'File Upload has been completed.',
    chooseDownload: 'Select a file or a folder to download.',
    chooseDownloadFolder: 'Select a folder to download.',
    chooseDownloadFile: 'Select a file to download.',
    selectedDownload: {
      title: 'Download Request Successful',
      msg: 'Download has been requested for the selected files and folders.',
    },
    validate: {
      renameTitle: 'Unable to modify',
      removetitle: 'Unable to delete',
      renameMsg: 'Unable to modify the folder.',
      removeMsg: 'Unable to delete the folder.',
    },
    renameSuc: 'Folder name has been modified.',
    removeSuc: 'Folder has been deleted.',
  },
  // /train/processing //
  tp: {
    externalLabeling: 'External Labeling Upload',
    newLabeling: 'New Labeling',
    aiLabeling: 'AI Labeling',
    checkCond: 'Filter',
    inputError: 'Must use a dataset where all images with same width/height size.',
    working: 'Currently working',
    myWorking: 'working',
    aiWaiting: 'AI Labeling awaiting',
    hasHistory: 'Use history exists',
    workable: 'Work available',
    result: 'Result',
    checkResult: 'View Result',
    aug: 'Aug',
    augMsg: 'You can augment the dataset',
    copyWork: 'Copy Labeling',
    mergeWork: 'Merge Labeling',
    removeData: 'Remove Data',
    remove: 'Remove',
    confirmRemove: 'Do you want to remove selected data?',
    fields: {
      typeProcessing: 'Processing Type',
      typeWork: 'Work Type',
      usageTypeId: 'Purpose',
      updatedAt: 'Updated At',
      title: 'Work Name',
      status: 'Status',
      inputLabelings: 'Data Directory',
      result: 'View Result',
    },
    workType: 'Work type',
    copying: 'Copying',
    moving: 'Entering',
    nowWorking: 'Currently in progress',
    fm: {
      waiting: 'Data Processing being created.',
      progressing: 'Data Orocessing being saved.',
      error: 'Data Processing with Error.',
      busyUser: '{name} in progress.',
      descSuc: 'Description has been changed.',
      historyWarning: 'Data manipulation with training or performance evaluation history cannot be removed.',
      progressWarning: 'AI Labeling in progress. Please try again after the progress is complete.',
      remove: 'Removed.',
      // 추가
      selectData: 'Please select a work to download.',
      downloadTitle: 'Download Requested',
      downloadMsg: 'Download of the selected files and folders has been requested.',
    },
    // 추가
    // labelingDownload: '라벨링 정보 파일 다운로드',
    // classDownload: '클래스 단위 파일 다운로드'
    labelingDownload: 'Download Labeling Information File',
    classDownload: 'Class-level File Download',
  },
  // /train/processing/add/new, index //
  tpAdd: {
    info: 'Labeling Info',
    processType: 'Data Processing Type',
    workType: 'Work ype',
    workName: 'Work Name',
    dataset: 'Data Select',
    chooseData: 'Select data',
    sampleImage: 'View sample image',
    folderImageCnt: '{count} images in folder',
    resultFolderName: 'Labeling Result Storage Folder Name',
    sameWorkName: 'Set Same as Work Name',
    folderName: 'Folder Name',
    mergeList: 'List of data to merge',
    chooseMerge: 'Select data to merge',
    chooseMergeData: 'Select data to merge',
    classInfo: 'Class Information',
    classInput: 'Input Class Information',
    model: 'Model',
    chooseModel: 'Choose a Model',
    textarea: 'Description',
    setDetail: 'Detailed Setting',
    inferenceParams: 'Inference Parameter',
    runLabeling: 'Perform Labeling',
    runningLabeling: 'Labeling is in progress',
    confirmLabeling: 'Would you like to proceed labeling with the information you entered?',
    breadcrumb: {
      first: 'New Labeling',
      second: 'Input Work Information',
    },
    fm: {
      trialWarning: 'This features is unavailble for a Trial account.',
      chooseName: 'Please enter a work name.',
      workNameLimit: 'Please enter the work name within 30 characters.',
      chooseProcessType: 'Please choose a process type.',
      chooseWorkType: 'Please select a work type.',
      chooseMerge: 'Please select two or more data to merge.',
      chooseData: 'Please select a data.',
      chooseModel: 'Please select a model.',
      inputResultName: 'Please enter a folder name to store labeling result.',
      startMerge: 'Data merging has started.',
      processSuc: 'Data processing has been created.',
      moreClass: 'Please add two or more class information.',
      addClass: 'Please add class information.',
      checkedOk: 'At least one class that has been checked with OK is required.',
      uncheckedOk: 'At least one class with OK unchecked is required.',
    },
  },
  // /train/processing/add/ai <<>> --
  tpAddAi: {
    info: 'Labeling Basic Information',
    processType: 'Processing Type',
    manualDownload: 'Download usage manual for unsupervised AI Labeling',
    manualName: '매뉴얼_v1_230828',
    workType: 'Work Type',
    workName: 'Work Name',
    dataset: 'Data select',
    chooseData: 'Select data',
    sampleImage: 'View sample image',
    folderImageCnt: '{num}images in folder',
    resultFolderName: 'Labeling Result Storage Folder Name',
    sameWorkName: 'Set Same as Work Name',
    folderName: 'Folder Name',
    algorithmsInfo: 'Algorithm Infomation',
    model: 'Model',
    chooseModel: 'Choose a model',
    classInfo: 'Class Information',
    setDetail: 'Detailed Setting',
    inferenceParams: 'Inference Parameter',
    selectAlgorithms: 'Select Algorithm',
    algorithmDesc: 'View Algorithm Description',
    trainParams: 'Train Parameter',
    augParams: 'Augmentation Setting',
    textarea: 'Description',
    runLabeling: 'Run Labeling',
    runningLabeling: 'Labeling is in progress',
    confirmLabeling: 'Would you like to proceed labeling with the information you entered?',
    confirmClassCnt: 'Confirm number of classes ',
    confirmClassCntMsg: 'Number of classes has been set to {numCluster}. Do you want to run labeling as is?',
    confirmClassCntCf: '[Note]',
    confirmClassCntDesc1: 'Number of Classes',
    confirmClassCntDesc2:
      'Please note that entering 2 is not a simple OK/NG Classification, and you must enter the number of image types',
    confirmClassCntDesc3: 'Only integers between 2 ~ 34 can be entered',
    back: 'Back ',
    save: 'Save',
    chooseAlgorithms: 'Choose an algorithm',
    breadCrumb: 'Work Information',
    labelingType: {
      pretrained: 'Supervised Learning (With pre-Trained Model)',
      unsupervised: 'Unsupervised Learning',
    },
    fm: {
      trialWarning: 'This features is unavailble for a Trial Account.',
      chooseWorkType: 'Please select a Work Type.',
      chooseData: 'Please select a Data.',
      chooseProcessType: 'Please select a Processing Type.',
      chooseName: 'Please enter a Work Name.',
      inputResultName: 'Please enter a Folder Name to store labeling result.',
      chooseModel: 'Please select a Model.',
      chooseAlgorithms: 'Please select an Algorithm.',
      inputParams: 'Please enter the parameter value {item}.',
      inputParamsScoped: 'Please enter the parameter value {item} within the range.',
      noAlgorithmsImage: 'There is no algorithm desciprtion images.',
      addTarget: 'Please add Target Region.',
      processSuc: 'Data processing has been created.',
      // 추가
      minImage: 'At least 100 manually labeled images are necessary.',
      min: 'Manual Labeling Images less then 100 cannot be used.',
      selectStdImgFolder: 'Please select a standard image folder.',
      selectStdImg: 'Please select a standard image.',
      inputPatchBbox: 'Please add at least one class to all images and enter Patch and Bbox for all added classes.',
    },
    // 추가2
    existingModel: 'Use Existing Model',
    useExisted: 'Proceed Data Labeling using a model previously created by the user.',
    algorithm: 'Select Algorithm',
    success: '{count} succeeded',
    fail: '{count} failed',
    manualLabeling: 'Perform Manual Lablieng',
    stdLabeling: 'Base Image Labeling',
    aiLabeling: 'AI Image Labeling',
    manualProcess: 'Manual Labeling',
    manualRatio: 'Manual Labeling Ratio',
    manualRatioDesc: 'Ratio of datasets with Manual Labeling to train the model for AI Labeling. (random)',
    uploadStdImage: 'Upload Standard Image',
    existingStd: 'Use Existing Images',
    selectExStd: 'Select existing standard image.',
    uploadFile: 'Please upload a file.',
    fileNameValidator:
      'If the file name contains Korean and special characters (except _),  those characters will be omitted and uploaded.',
    images: 'images',
  },
  // /train/processing/add/augmentation <<>> --
  tpAddAug: {
    info: 'Labeling Basic Information',
    workName: 'Work Name',
    resultFolderName: 'Folder Name of Data Augmentation Result Storage',
    sameWorkName: 'Set Same as Work Name',
    folderName: 'Folder Name ',
    chooseData: 'Select a data',
    targetData: 'Target Data',
    dataset: 'Data Select',
    sampleImage: 'View sample images',
    type: 'Type',
    augMethod: 'Set Augmentation Ratio and Methods',
    augAll: 'Apply All ',
    originCnt: '{count} original images ',
    totalCnt: 'Total  {count} augmented images',
    augRatio: 'Augmentation Ratio',
    imageAugMethod: 'Image augmentation method',
    previewAug: 'Preview augmented images',
    preview: 'Preview',
    originImage: 'Original images',
    resultImage: 'Augmented images',
    noPreviewImage: 'There are no preview results to display',
    textarea: 'Description',
    runAug: 'Run Augmentation',
    runLabeling: 'Run Labeling',
    runningLabeling: 'labeling is in progress',
    confirmLabeling: 'Would you like to proceed labeling with the information you entered?',
    resultFolderName: 'Labeling result storage folder name',
    breadCrumb: {
      aug: 'Data Augmentation',
      info: 'Work Information',
    },
    fm: {
      chooseName: 'Please enter a Work Name.',
      inputFolderName: 'Please enter a Folder Name to store data augmentation results.',
      chooseData: 'Please choose a Target Data.',
      augSuccess: 'Data Augmentation has been created.',
    },
    // 추가
    count: '{count} items',
  },
  // /train/processing/_id/cf <<>> --
  tpIdCf: {
    autoSaving: 'Auto saving...',
    lastSavedTime: 'Last saved',
    extendWork: 'Extend Work Time',
    saveAndOut: 'Save & To List',
    labelingResult: 'Labeling result',
    save: 'Final save of Labeling results',
    list: 'View list',
    saveLabeling: 'Save labeling',
    saveLabelingDesc1: 'Do you want to save by folder with the labeling you have done?',
    saveLabelingDesc2: "It would be disabled after it and you can use 'Labeling copy'",
    saveLabelingDesc3: 'When classifying, the original files are moved and the operation cannot be canceled',
    saveUsage: 'Save as {name}',
    confirmMsg1: 'The operation will automatically end after 1 minute.',
    confirmMsg2: 'Would you like to save your work temporarily and continue working?',
    confirmMsg3:
      'If there is no response, the work will be automatically saved temporarily after 1 minute, and the current work screen will close.',
    breadCrumb: {
      processing: 'Data Labeling',
      labeling: 'Labeling',
    },
    min: 'minute(s)',
    sec: 'second(s)',
    fm: {
      moreClass: 'Please add two or more Class Information.',
      addClass: 'Please add Class Information.',
      checkedOk: 'At least one class that has been checked with OK is required.',
      uncheckedOk: 'At least one class with OK unchecked is required.',
      categoryMinImage: 'At least one image per class is necessary.',
      noDuplicated: 'Duplicated class name cannot be saved.',
      chooseUsage: 'Please select the purpose of data processing.',
      success: 'Saved.',
      // 추가
      creating: 'Data processing creation is in progress. Please start labeling in a moment.',
      manualMinImage: 'At least 100 manually labeled images are necessary.',
      unclassifiedWarning: 'There are unclassified data. AI Labeling is only saved once classification is complete.',
      isNotMultiple: 'Every class must have at least one image.',
    },
  },
  // /train/processing/_id/cf/result <<>> --
  tpIdCfResult: {
    info: 'Basic Information',
    workName: 'Work Name',
    workType: 'Work Type',
    updatedAt: 'Work Date',
    inputData: 'Classification target data directory',
    folderImageCnt: '{count}images in folder',
    resultRoute: 'Save Labeling Result Directory',
    preModel: 'pre-Trained Model',
    resultLabeling: 'Labeling result',
    labeling: 'Annotation Ratio',
    distribution: 'Class Distribution',
    edit: 'Edit',
    list: 'To List',
    breadCrumb: {
      labeling: 'Data Labeling',
      result: 'Labeling Result',
    },
  },
  // /train/processing/_id/od <<>> --
  tpIdOd: {
    labelingResult: 'Labeling result',
    temtSave: 'Save Temporarily',
    saveAndOut: 'Save & To List',
    save: 'Final save of labeling results',
    list: 'View list',
    saveLabeling: 'Save Labeling ',
    saveLabelingDesc1: 'Do you want to save by folder with the labeling you have done?',
    saveLabelingDesc2: 'When classifying, the original files are moved and the operation cannot be canceled',
    saveUsage: 'Save as {usage}',
    extendWork: 'Extend Work Time',
    confirmMsg1: 'The operation will automatically end after 1 minute.',
    confirmMsg2: 'Would you like to save your work temporarily and continue working?',
    confirmMsg3:
      'If there is no response, the work will be automatically saved temporarily after 1 minute, and the current work screen will close.',
    fm: {
      tempSuc: 'Saved Temporarily.',
      noDuplicated: 'Duplicated class name cannot be saved.',
      chooseUsage: 'Please select the purpose of data processing.',
      unTrial: 'This feature is for comparative performance evaluation and unavailable in trial version.',
      success: 'Saved.',
      // 추가
      noCocoMsg: "Data Processing Creation is in progress. Please start labeling when it's ready.",
      minImage: 'At least 100 Manually Labeled Images are necessary.',
      unDrawnImages: 'There are unclassified data. AI labeling is only saved Once the classification is complete.',
    },
    breadCrumb: {
      processing: 'Data Labeling',
      labeling: 'Run Labeling',
    },
    min: 'minute(s)',
    sec: 'second(s)',
    // 추가
    labeling: 'AI Labeling',
  },
  // /train/processing/_id/od/result <<>> --
  tpIdOdResult: {
    info: 'Basic Information',
    workName: 'Work Name',
    workType: 'Work Type',
    updatedAt: 'Work Date',
    inputData: 'Classification target data directory',
    folderImageCnt: '{count}images in folder',
    resultRoute: 'Save Labeling Result Directory',
    preModel: 'pre-Trained Model',
    resultLabeling: 'Labeling result',
    annotationRatio: 'Annotation ratio',
    distribution: 'Class Distribution',
    annotationAvg: 'Annotation area - Mean',
    annotationDist: 'Annotation area - Variance',
    annotationMin: 'Annotation area - Minimum',
    annotationMax: 'Annotation area - Maximum',
    annotationCnt: 'Number of image per Annotation',
    edit: 'Edit',
    list: 'To List',
    breadCrumb: {
      labeling: 'Data Labeling',
      result: 'Labeling Result',
    },
    // 추가
    count: ' {count}',
    positionInfo: 'Labeling Position',
  },
  // /train/learning <<>> --
  tl: {
    new: 'New Training',
    retrain: 'Re-training',
    filter: {
      status: 'Status',
    },
    condition: 'Filter',
    progressAlert1: 'There are {count}trainings in progress. (At most 3 concurrent trainings are allowed for a user)',
    progressAlert2: 'Please retry after an existing training has been completed.',
    cancelTrain: 'Cancel Training',
    removeTrain: 'Delete Training',
    remove: 'Delete',
    removeTrainMsg: 'Do you want to delete the selected training?',
    trainInfo: 'Training Info',
    trainInfoMsg1: 'The selected training is in {status} status, and cannot show the training result.',
    trainInfoMsg2: 'Training info and parameter settings are shown as below.',
    trainInfoDetail: {
      name: 'Model Name',
      type: 'Type',
      algorithm: 'Algorithm',
      createdAt: 'Created At',
      canceledAt: 'Canceld At',
      detail: 'Detail',
      detailInfo: 'Detail Info',
    },
    cancelTrainModel: 'Cancel Training',
    confirmCancel: 'Cancel',
    no: 'No',
    cancelTrainMsg: 'Do you want to cancel the training?',
    algorithm: 'Algorithm',
    workType: 'Work Type',
    cancelConfirm:
      'Do you want to cancel training in progress? You will not be able to information from the model that has been canceled.',
    return: 'Return',
    fields: {
      workType: 'Work Type',
      createdAt: 'Created At',
      name: 'Model Name',
      algorithm: 'Algorithm',
      data: 'Training Data',
      status: 'Status',
      options: 'Options',
      progress: 'Progress',
    },
    fm: {
      undo: "Training hasn't been completed.",
      unremovableMsg: 'Training in progress cannot be deleted.',
      removeSuc: 'Deleted.',
      descSuc: 'Modified description.',
      cancel: 'Cancelled training.',
      cancelErrorMsg: 'Training cannot be canceled. ({status})',
    },
  },
  // /train/learning/add <<>> --
  tlAdd: {
    info: 'Info for Model Training',
    modelName: 'Model Name',
    checkDuplicate: 'Check Duplicate',
    notDuplicated: 'Model name can be used.',
    duplicated: 'Model name already exists.',
    data: 'Select Data',
    chooseData: 'Please select data.',
    sampleImage: 'View Samples',
    folderImageCnt: '{count} images in folder',
    type: 'Type',
    classCnt: 'Class Type ({count})',
    algorithmInfo: 'Algorithm Info',
    chooseAlgo: 'Select Algorithm',
    hpoToggle: 'Set parameter auto optimization',
    checkAlgoInfo: 'View algorithm description',
    detail: 'Details',
    params: 'Train Parameters',
    aug: 'Augmentation Settings',
    memo: 'Description',
    runTrain: 'Run Training',
    loadingTrain: 'Requesting Model Training',
    progressAlert1: 'There are {count} trainings in progress. (At most 3 concurrent trainings are allowed for a user.)',
    progressAlert2: 'Please retry after an existing training has been completed.',
    y: 'Yes',
    n: 'No',
    confirmMsg1:
      'There are {count} {name} trainings in progress on GPU server. The training will be run after the prior training requests are completed.',
    confirmMsg2:
      'Even if you make the training request now, there could be some waiting time until the training starts.',
    confirmMsg3: 'Do you want to proceed?',
    trainModel: 'Model Training',
    trainModelMsg1: 'Do you want to proceed model training?',
    trainModelMsg2: 'You can work on other jobs while the training is in progress.',
    trainModelMsg3: 'Training progress can be viewed in Work List page or from alarm function on the top.',
    algorithmDesc: 'Algorithm Description',
    chooseAlgorithms: 'Please select an algorithm.',
    limitLearning: 'You cannot proceed with learning as the maximum limit of {limit} sessions has been reached.',
    overLimit: 'Exceeded maximum number of learning sessions',
    breadcrumb: {
      learning: 'Model Training',
      new: 'New Training',
    },
    fm: {
      inputModelName: 'Please input the model name.',
      noAlgoDesc: 'There is no algorithm description.',
      checkDuplicate: 'Please confirm duplicate model name.',
      duplicate: 'Duplicate model name. Please input a different model name.',
      chooseData: 'Please select a dataset.',
      chooseAlgo: 'Please select an algorithm.',
      inputRatio: 'Please input sampling ratio.',
      inputParams: 'Please input {name} parameter value.',
      inputRange: 'Please input {name} parameter value in range.',
      inputAugParams: 'Please input {name} augmentation parameter value.',
      inputAugRange: 'Please input {name} augmentation parameter value in range.',
      success: 'Successfully created model training.',
    },
  },
  // /train/learning/_id/cf <<>> --
  tlIdCf: {
    info: 'Model Training Info',
    modelName: 'Model Name',
    work: 'Type',
    algorithm: 'Algorithm',
    createdAt: 'Training Creation Date',
    endedAt: 'Training Completion Date',
    duration: 'Training Duration',
    detail: 'Details',
    detailInfo: 'Details Info',
    validationResult: 'Validation Result',
    recommendedParameters: 'Recommended Parameters',
    accuracy: 'Accuracy',
    threshold: 'Optimal Threshold',
    classPrecision: 'Class Precisionn',
    classRecall: 'Class Recall',
    acGraph: 'Accuracy Graph',
    checkOrigin: 'View Full Data',
    lossGraph: 'Loss Graph',
    stoppingNoti: 'Note: Early Stopping has been applied, and the training could have ended prior to the input epoch.',
    resultSampling: 'Result Image Samples',
    resultMsg: 'Original / Result Image',
    curClass: 'Current Class',
    resampling: 'Resample',
    origin: 'Original',
    result: 'Result',
    resultNoti:
      'No result could be shown due to low performance. Improved model training performance is needed to view a result.',
    list: 'To List',
    hour: 'hour(s)',
    min: 'minute(s)',
    sec: 'second(s)',
    sort: {
      low: 'Low',
      mid: 'Mid',
      high: 'High',
    },
    breadcrumb: {
      training: 'Model Training',
      result: 'Labeling Result',
    },
  },
  // /train/learning/_id/od <<>> --
  tlIdOd: {
    info: 'Model Training Info',
    modelName: 'Model Name',
    work: 'Type',
    algorithm: 'Algorithm',
    createdAt: 'Training Creation Date',
    endedAt: 'Training Completion Date',
    duration: 'Training Duration',
    detail: 'Details',
    detailInfo: 'Details Info',
    validationResult: 'Validation Result',
    recommendedParameters: 'Recommended Parameters',
    classMAP: 'class mAP',
    resultSampling: 'Result Image Samples',
    resultMsg: 'Original / Result Image',
    curClass: 'Current Class',
    resampling: 'Resample',
    origin: 'Original',
    result: 'Result',
    resultNoti:
      'No result could be shown due to low performance. Improved model training performance is needed to view a result.',
    list: 'To List',
    sort: {
      asc: 'Ascending',
      desc: 'Descending',
    },
    breadcrumb: {
      training: 'Model Training',
      result: 'Model Training Result',
    },
  },
  // /train/learning/_id/sg <<>> --
  tlIdSg: {
    info: 'Model Training Info',
    modelName: 'Model Name',
    work: 'Type',
    algorithm: 'Algorithm',
    createdAt: 'Training Creation Date',
    endedAt: 'Training Completion Date',
    duration: 'Training Duration',
    detail: 'Details',
    detailInfo: 'Details Info',
    validationResult: 'Validation Result',
    recommendedParameters: 'Recommended Parameters',
    classMAP: 'class mAP',
    resultSampling: 'Result Image Samples',
    resultMsg: 'Original / Result Image',
    curClass: 'Current Class',
    resampling: 'Resample',
    origin: 'Original',
    result: 'Result',
    resultNoti:
      'No result could be shown due to low performance. Improved model training performance is needed to view a result.',
    list: 'To List',
    sort: {
      asc: 'Ascending',
      desc: 'Descending',
    },
    breadcrumb: {
      training: 'Model Training',
      result: 'Model Training Result',
    },
  },
  // /train/learning/relearning <<>> --
  tlRelearning: {
    info: 'Info for model training',
    modelName: 'Model Name',
    checkDuplicate: 'Check Duplicate',
    notDuplicated: 'Model name can be used.',
    duplicated: 'Model name already exists.',
    trainingModel: 'Training Model',
    chooseModel: 'Please choose a model.',
    detail: 'Details',
    params: 'Training Parameters',
    aug: 'Augmentation Settings',
    data: 'Select Data',
    chooseData: 'Please select data.',
    sampleImage: 'View Samples',
    folderImageCnt: '{count} images in folder',
    type: 'Type',
    classCnt: 'Class Type ({count})',
    memo: 'Description',
    runRetrain: 'Re-Train',
    loadingTrain: 'Requesting model training',
    progressAlert1: 'There are {count} trainings in progress. (At most 3 concurrent trainings are allowed for a user.)',
    progressAlert2: 'Please retry after an existing training has been completed.',
    y: 'Yes',
    n: 'No',
    confirmMsg1:
      'There are {count} {name} trainings in progress on GPU server. The training will be run after the prior training requests are completed.',
    confirmMsg2:
      'Even if you make the training request now, there could be some waiting time until the training starts.',
    confirmMsg3: 'Do you want to proceed?',
    trainModel: 'Model Training',
    trainModelMsg1: 'Do you want to proceed model training?',
    trainModelMsg2: 'You can work on other jobs while the training is in progress.',
    trainModelMsg3: 'Training progress can be viewed in Work List page or from alarm function on the top.',
    breadcrumb: {
      retraining: 'Re-Training',
      info: 'Info',
    },
    fm: {
      inputModelName: 'Please input the model name.',
      chooseModel: 'Please select a model to re-train.',
      checkDuplicate: 'Please confirm duplicate model name.',
      duplicate: 'Duplicate model name. Please input a different model name.',
      chooseData: 'Please select a dataset.',
      inputRatio: 'Please input sampling ratio.',
      success: 'Successfully created model re-training.',
    },
  },
  // /train/inference <<>> --
  ti: {
    new: 'New Evaluation',
    single: 'Single Performance Evaluation',
    compare: 'Comparative Performance Evaluation',
    condition: 'Filter',
    status: {
      curr: 'Currently Working',
      me: 'Working',
      ok: 'Available',
    },
    reinspect: 'Re-Inspection',
    inspect: 'Inspection',
    result: 'Inspection Result',
    inspectResult: 'Result',
    removeInfer: 'Delete performance evaluation',
    remove: 'Delete',
    confirmRemoveInfer: 'Do you want to delete selected performance evaluation?',
    fields: {
      workType: 'Work Type',
      updatedAt: 'Work Date',
      title: 'Work Name',
      model: 'Applied Model',
      algorithm: 'Algorithm',
      image: 'Image',
      status: 'Status',
      modelAccuracy: 'Model Performance',
      busy: 'Usage Status',
      inspection: 'Inspection',
      result: 'Inspection Result',
    },
    fm: {
      trialWarning: 'This features is unavailble for a trial account.',
      busyWarning: '{name} is currently working.',
      changeDescSuc: 'Description has been changed.',
      progressWarning: 'Cannot delete performance evaluation is progress.',
      removeSuc: 'Deleted.',
    },
    // 추가
    count: ' {count}',
  },
  // /train/inferecne/add <<>> --
  tiAdd: {
    info: 'Performance Evaluation Basic Information',
    name: 'Inspection Name',
    data: 'Data Select',
    chooseData: 'Select data',
    useLabeledData: 'Use Labeled Data',
    sampleImage: 'View sample images',
    folderImageCnt: '{count} images in folder',
    workType: 'Work Type',
    model: 'Model',
    inputModel: 'Choose Model',
    chooseModal: 'Choose model',
    class: 'Class Type',
    memo: 'Description',
    detail: 'Detailed Setting',
    inferParams: 'Inference Parameters',
    infer: 'Run Performance Evaluation',
    requestInfer: 'Requesting Performance Evaluation',
    confirmInfer: 'Do you want to proceed performance evaluation with the information you entered?',
    confirmMsg1:
      'Currently, {name} inference is taking place on the GPU, so inference will start after the first requested inference is complete.',
    confirmMsg2: 'Even if an inference request is made, waiting time may occur.',
    breadcrumb: {
      infer: 'Performance Evaluation',
      new: 'New Evaluation',
    },
    fm: {
      chooseData: 'Select data.',
      chooseWork: 'Select Work Type.',
      inputName: 'Enter Insepction Name.',
      chooseModel: 'Select Model.',
      success: 'New performance evaluation has been created.',
    },
    // 추가
    count: ' {count}',
  },
  // /train/inferecne/_id/ <<>> --
  tiId: {
    info: 'Performance Evaluation Basic Information',
    name: 'Inspection Name',
    type: 'Type',
    modelAlgo: 'Model / Algorithm',
    user: 'Creating User',
    inspectUser: 'Verifying User',
    createdAt: 'Inspection Date',
    duration: 'Inspection Time',
    input: 'Usage Data Directory',
    detail: 'Detailed Setting',
    detailInfo: 'Detailed Setting Information',
    validationResult: 'Validation Result',
    bestThreshold: 'Best Threshold',
    selectedThreshold: 'Currently selected threshold ',
    checkResult: 'View inspection result',
    resultPerImage: 'Model inference result / Target image number',
    defectRate: 'Defect rate',
    detectionCnt: 'Number of detections by Type',
    fileName: 'File Name',
    copayFileName: 'Copy File Name',
    noImageMsg: 'There are no images',
    imageArr: 'Image array',
    firstRow: 'Row 1',
    secondRow: 'Row 2',
    thirdRow: 'Row 3',
    fourthRow: 'Row 4',
    classification: 'Class Classification',
    inspectResult: 'Verify Inspection result',
    list: 'To List',
    positive: 'positive',
    negative: 'negative',
    breadcrumb: {
      infer: 'Performance Evaluation',
      detail: 'Confirm Detail',
    },
    unclassified: 'Unclassified',
    fields: {
      name: 'Class Name',
      result: 'Verification Result',
    },
    total: 'Total',
    fm: {
      busyWarning: '{name} is working.',
    },
    // 추가
    count: '{count} images',
    save: 'Save Selected Threshold',
    saveThreshold: 'Save Verification Result',
    saveResult: 'Save',
    thresholdConfirm1: 'Verification Result will be saved based on the selected threshold value.',
    thresholdConfirm2: 'Do you wish to save with currently selected values?',
  },
  // /train/inferecne/_id/cf <<>> --
  tiIdCf: {
    info: 'Basic Information',
    name: 'Inspection Name',
    type: 'Type',
    modelAlgo: 'Model / Algorithm',
    updatedAt: 'Inspection Date',
    duration: 'Inspection Time',
    detail: 'Detailed Setting',
    detailInfo: 'Detailed setting information',
    save: 'Save verification result and check performance',
    extendHour: 'Extend Work Hour',
    extendHourMsg1: 'Work time ends in 1 minute.',
    extendHourMsg2: 'Do you want to extend your work time?',
    extendHourMsg3:
      '(If there is no response, the work will be automatically saved after 1 minute and the current work screen will close.)',
    breadcrumb: {
      infer: 'Performance Evaluation',
      inspect: 'Verify Images',
    },
    min: 'minute(s)',
    sec: 'second(s)',
    fm: {
      success: 'Verification result has been saved.',
    },
  },
  // /train/inferecne/_id/od <<>> --
  tiIdOd: {
    save: 'Save verification result and check performance',
    extendHour: 'Extend Work Hour',
    extendHourMsg1: 'Work time ends in 1 minute.',
    extendHourMsg2: 'Do you want to extend your work time?',
    extendHourMsg3:
      '(If there is no response, the work will be automatically saved after 1 minute and the current work screen will close.)',
    breadcrumb: {
      infer: 'Performance Evaluation',
      inspect: 'Verify Images',
    },
    min: 'minute(s)',
    sec: 'second(s)',
    fm: {
      success: 'Saved.',
    },
  },
  // /train/inferecne/_id/sg <<>> --
  tiIdSg: {
    save: 'Save verification result and check performance',
    extendHour: 'Extend Work Hour',
    extendHourMsg1: 'Work time ends in 1 minute.',
    extendHourMsg2: 'Do you want to extend your work time?',
    extendHourMsg3:
      '(If there is no response, the work will be automatically saved after 1 minute and the current work screen will close.)',
    breadcrumb: {
      infer: 'Performance Evaluation',
      inspect: 'Verify Images',
    },
    min: 'minute(s)',
    sec: 'second(s)',
    fm: {
      success: 'Saved.',
    },
  },
  // /train/inferecne/_id/result/cf <<>> --
  tiIdResultCf: {
    result: 'Performance Verification Result',
    info: 'Performance Evaluation Basic Information',
    name: 'Inspection Name',
    type: 'Type',
    inferenceData: 'Inference Data',
    modelAlgo: 'Model / Algorithm',
    createdAt: 'Insepction Date',
    updatedAt: 'Final Inspection Date',
    duration: 'Inspection Dime',
    detail: 'Detailed setting',
    detailInfo: 'Detailed setting information',
    checkResult: 'View inspection result',
    inspectionPerImage: 'Apply verification result / {count} target images',
    defectRate: 'Defect Rate',
    detectionCnt: 'Number of detections by Type',
    performance: 'Model Performance',
    accuracy: 'Accuracy',
    bestThreshold: 'Best Threshold',
    threshold: 'Threshold',
    classPrecision: 'Precision by Class',
    classRecall: 'Recall by Class',
    inspect: 'Verify images',
    list: 'To List',
    positive: 'positive',
    negative: 'negative',
    breadcrumb: {
      infer: 'Performance evaluation basic information',
      result: 'Confirm result',
    },
  },
  // /train/inferecne/_id/result/od <<>> --
  tiIdResultOd: {
    result: 'Performance Verification Result',
    info: 'Performance Evaluation Information',
    name: 'Inspection Name',
    type: 'Type',
    modelAlgo: 'Model / Algorithm',
    createdAt: 'Insepction Date',
    updatedAt: 'Final Inspection Date',
    duration: 'Inspection Time',
    detail: 'Detailed setting',
    detailInfo: 'Detailed setting information',
    checkResult: 'View inspection result',
    inspectionPerImage: 'Apply verification result / {count} target images',
    defectRate: 'Defect Rate',
    detectionCnt: 'Number of detections by Type',
    performance: 'Model Performance',
    accuracy: 'Accuracy',
    classMAP: 'mAP by Class',
    judgement: 'Prediction Image',
    inspection: 'Verified Images',
    resultList: 'Prediction result list',
    inspect: 'Verify images',
    list: 'To List',
    fileName: 'File Name',
    positive: 'positive',
    negative: 'negative',
    resultImage: 'Result Image',
    breadcrumb: {
      infer: 'Performance Evaluation',
      result: 'Check Result',
    },
    count: '',
  },
  // /train/inferecne/_id/result/od <<>> --
  tiIdResultSg: {
    result: 'Performance Verification Result',
    info: 'Performance Evaluation Information',
    name: 'Inspection Name',
    type: 'Type',
    modelAlgo: 'Model / Algorithm',
    createdAt: 'Insepction Date',
    updatedAt: 'Final Inspection Date',
    duration: 'Inspection Time',
    detail: 'Detailed setting',
    detailInfo: 'Detailed setting information',
    checkResult: 'View inspection result',
    inspectionPerImage: 'Apply verification result / {count} target images',
    defectRate: 'Defect Rate',
    detectionCnt: 'Number of detections by Type',
    performance: 'Model Performance',
    accuracy: 'Accuracy',
    classMAP: 'mAP by Class',
    judgement: 'Prediction Image',
    inspection: 'Verified Images',
    resultList: 'Prediction result list',
    inspect: 'Verify images',
    list: 'To List',
    fileName: 'File Name',
    positive: 'positive',
    negative: 'negative',
    breadcrumb: {
      infer: 'Performance Evaluation',
      result: 'Check Result',
    },
    count: '',
  },
  // /train/inference/compare <<>> --
  tiCompare: {
    single: 'Single Performance Evalutation',
    compare: 'Comparaitve Performance Evaluation',
    condition: 'Filter',
    outOfImage: '{total} except {count}',
    removeInfer: 'Remove Performance Evaluation',
    remove: 'Remove',
    confirmRemoveInfer: 'Do you want to remove selected evaluations?',
    fields: {
      workType: 'Work Type',
      updateAt: 'Latest Evaluation Date',
      workName: 'Work Name',
      class: 'Class Information',
      model: 'Evaluation Target Model',
      algorithm: 'Algorithm',
      image: 'Image',
      user: 'Evaluating User',
      status: 'Evaluation Status',
      resultFolder: 'Result Folder Name',
    },
    fm: {
      changeDescSuc: 'Eescription has been changed.',
      removeSuc: 'Removed.',
    },
    // 추가
    count: ' {count}',
  },
  // /train/inference/compare/_id <<>> --
  tiCompareId: {
    info: 'Comparaitve Performance Evaluation Information',
    workType: 'Work Type',
    user: 'Registered User',
    createdAt: 'Registered Date',
    updateAt: 'Latest Evaluation Date',
    data: 'Used Data',
    imageCnt: 'Number of Images',
    classInfo: 'Class Information',
    compareResult: 'Comparative Evaluation Result',
    addModel: 'Add model',
    accuracy: 'Accuracy',
    list: 'To List',
    modelDetail: 'Model Detailed Information',
    modelName: 'Model Name',
    type: 'Type',
    algorithm: 'Algorithm',
    trainingUser: 'Training User',
    endedAt: 'Train Complete Date',
    duration: 'Train Spent Time',
    detail: 'Detailed Setting',
    detailInfo: 'Detailed setting information',
    close: 'Close',
    removeInfer: 'Remove Performance Evaluation',
    remove: 'Remove.',
    confirmRemoveInfer: 'Do you want to remove selected evaluations?',
    fields: {
      index: 'Index',
      model: 'Model Name',
      algorithm: 'Algorithm',
      createdAt: 'Evalutation Date',
      user: 'Evaluating User',
      status: 'Progress Status',
      accuracy: 'Model Accuracy',
      map: 'mAP',
    },
    performanceFields: {
      index: 'Index',
      model: 'Model Name',
      algorithm: 'Algorithm',
      user: 'Evaluating User',
      status: 'Progress Status',
      accuracy: 'Accuracy',
    },
    aucFields: {
      index: 'Index',
      model: 'Model Name',
      algorithm: 'Algorithm',
      user: 'Evaluating User',
      status: 'Progress Status',
    },
    breadcrumb: {
      infer: 'Comparative Performance Evaluation',
      result: 'Confirm Detail',
    },
    fm: {
      descSuc: 'Description has been changed.',
      remove: 'Removed.',
    },
    // 추가
    count: ' {count}',
  },
  // /my/index <<>> --
  my: {
    title: 'My Page',
    info: 'Info',
    id: 'ID',
    name: 'Name',
    company: 'Company',
    department: 'Department',
    project: 'My Projects',
    projectSetting: 'Default Project Setting',
    curProject: 'Current Project',
    defaultProject: 'Default Project',
    admin: 'Admin',
    user: 'Users',
    permission: 'Permission',
    projectInfo: 'Project Info',
    manageUser: 'Manage Users',
    noProject: 'No project to show',
    breadcrumb: {
      my: 'My Info',
    },
  },
  // /my/change-password <<>> --
  myChangePassword: {
    title: 'My Page',
    currPw: 'Current Password',
    newPw: 'New Password',
    pwMsg:
      'Please use combination of alphabet and numbers over 10 characters, or combination of alphabet, numbers and special characters of over 8 characters',
    confirmPw: 'Confirm Password',
    confirmNewPw: 'Confirm New Password',
    changePw: 'Change Password',
    breadcrumb: 'Change Password',
    fm: {
      inputCurrPw: 'Please enter current password.',
      inputNewPw: 'Please enter new password.',
      pwWarning:
        'Please use combination of alphabet and numbers over 10 characters, or combination of alphabet, numbers and special characters of over 8 characters',
      incorrectPw: 'Passwords do not match.',
      inputOtherPw: 'Please enter a password that is different from the old one.',
      changePwSuc: 'Password has been changed. Please login again.',
    },
  },
  // /my/usage-projects <<>> --
  myUsageProjects: {
    title: 'My Page',
    imageUploadStatus: 'Image Upload Status',
    learnStatus: 'Learning Status',
    breadcrumb: 'Usage by Project',
    totalUploadCount: 'Total Upload Count',
    totalLearningCount: 'Total Learning Count',
    usedUploadCount: 'Used Upload Count',
    usedLearningCount: 'Used Learning Count',
    remainUploadCount: 'Remaining Upload Count',
    remainLearningCount: 'Remaining Learning Count',
    limitImageCount: 'Upload Image Count Limit',
    totalImageUploadCount: 'Total Uploaded Image Count',
    remainImageUploadCount: 'Remaining Uploadable Image Count',
    limitLearningCount: 'Learning Count Limit',
    totalLearningUploadCount: 'Total Learning Count',
    remainLearningUploadCount: 'Remaining Learning Count',
    images: '{count} images',
    learning: '{count} times',
  },
  // /my/notifications <<>> --
  myNotifications: {
    title: 'My Page',
    filter: {
      all: 'All',
      processing: 'Data Preprocessing',
      training: 'Model Training',
      inference: 'Performance Evalution',
      compareInference: 'Performance Comparison evaluation',
      addCompare: 'Add Comparison Model',
    },
    project: 'Project Name',
    condition: 'Filter',
    status: {
      // 완료, 진행
      labeling: 'Preprocessing',
      learning: 'Training',
      inference: 'Performance evaluation',
      compareInfer: 'Add comparison model',
      compare: 'Comparison evaluation',
      completed: 'completed',
      processed: 'processed',
      job: 'Job has been',
      verb: '',
    },
    resultNone: 'No notifications.',
    breadcrumb: {
      noti: 'Notifiaction History',
    },
    done: 'Done',
    process: 'Process',
    shortName: {
      labeling: 'Preprocessing',
      compareInference: 'Model',
      compare: 'Comparison',
      training: 'Training',
      inference: 'Evaluation',
    },
    name: {
      labeling: 'Data Preprocessing',
      compareInference: 'Add Comparison Model',
      compare: 'Performance Comparison evaluation',
      training: 'Model Training',
      inference: 'Performance evaluation',
    },
    hour: 'hour(s)',
    min: 'minute(s)',
    sec: 'second(s)',
    spend: 'spent',
  },
  // /my/projects <<>> --
  myProjects: {
    title: 'Project',
    createProject: 'Create Project',
    name: 'Project Name',
    code: 'Project code',
    condition: 'Filter',
    people: '{count} users',
    fields: {
      title: 'Project Name',
      code: 'Project Code',
      company: 'Company',
      userCnt: 'User Count',
      createdAt: 'Created at',
    },
    breadcrumb: {
      list: 'Project List',
    },
  },
  // /my/projects/create <<>> --
  myProjectsCreate: {
    title: 'Create Project',
    companyInfo: 'Company Info',
    company: 'Company',
    chooseCompany: 'Please select a company.',
    projectInfo: 'Project Info',
    projectTitle: 'Project Name',
    projectCode: 'Project Code',
    checkDuplicate: 'Check Duplicate',
    isOkCode: 'Available project code.',
    codeMsg1: 'Project code cannot be changed once created.',
    codeMsg2: "Please use lower/upper-case alphabet, '-', or '_'.",
    projectDesc: 'Project Description',
    productImage: 'Product Image',
    addImage: 'Add Image',
    default: 'Thumbnail',
    setDefault: 'Set Thumbnail',
    inputImage: 'Please add image.',
    inspectionInfo: 'Inspection line info',
    addLine: 'Line Inspection',
    line: 'Add Line',
    lineName: 'Line Name',
    lineCode: 'Line Code',
    productName: 'Product Name',
    processName: 'Process Name',
    codeMsg3:
      "Only upper and lower case letters, '-', and '_' can be used for line code, and unavailable if there's a duplicate code.",
    inputLine: 'Please add a line.',
    create: 'Creation completed.',
    fields: {
      name: 'Company Name',
      projectCnt: 'Project Count',
      lineCnt: 'Line Count',
      managerCnt: 'Admin Count',
      userCnt: 'User Count',
      createdAt: 'Created At',
    },
    breadcrumb: {
      project: 'Project',
      create: 'Create Project',
    },
    fm: {
      inputCode: 'Please enter project code over 4 characters.',
      codeError: '{code} is not usable for project code.',
      duplicateLineName: 'Duplicate line name.',
      duplicateLineCode: 'Duplicate line code.',
      chooseCompany: 'Please select a company.',
      inputProject: 'Please enter project name.',
      checkDuplicate: 'Please check duplicate project code.',
      inputLineName: 'Please enter a line name.',
      inputLineCode: 'Please enter a line code.',
      validateLine: "Please use numbers, lower/upper-case alphabet, '-' or '_' for line code.",
      inputMoreCode: 'Please enter line code over 4 characters.',
      inputProductName: 'Please enter product name.',
      inputProcess: 'Please enter process name.',
    },
  },
  // /my/projects/_id <<>> --
  myProjectsId: {
    project: 'Project',
    info: 'Project Info',
    company: 'Company Name',
    title: 'Project Name',
    code: 'Project Code',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    desc: 'Project Description',
    noDesc: 'No project description.',
    image: 'Product Image',
    thumb: 'Thumbnail',
    noImage: 'No image',
    inspectionInfo: 'Inspection line info',
    noLineInfo: 'No line info.',
    userInfo: 'User Info',
    admin: 'Admin',
    user: 'User',
    more: 'More',
    list: 'To List',
    removeProject: 'Delete Project',
    editInfo: 'Edit info',
    removeProjectMsg: 'Do you want to delete the project?',
    fields: {
      name: 'Line',
      code: 'Line Code',
      productName: 'Product',
      process: 'Process',
    },
    breadcrumb: {
      project: 'Project',
    },
    fm: {
      removeSuccess: 'Project has been removed.',
    },
    // 추가
    count: '{count} people',
  },
  // /my/projects/_id/edit  <<>> --
  myProjectsIdEdit: {
    project: 'Project',
    companyInfo: 'Company Info',
    company: 'Company Name',
    projectInfo: 'Project Info',
    title: 'Project Name',
    code: 'Project Code',
    desc: 'Project Description',
    image: 'Product Image',
    uploadImage: 'Add Image',
    thumb: 'Thumbnail',
    setDefault: 'Set Thumbnail',
    addImage: 'Please add image.',
    done: 'Updated',
    breadcrumb: {
      project: 'Project',
      edit: 'Edit',
    },
    fm: {
      uploadImageFail: 'Failed to add image.',
      done: 'Updated.',
      duplicateLine: 'Duplicate line name.',
      duplicateCode: 'Duplicate line code.',
      chooseCompany: 'Please select company.',
      inputTitle: 'Please enter project name.',
      inputLineName: 'Please enter line name.',
      inputLineCode: 'Please enter line code.',
      validateLine: "Please use numbers, lower/upper-case alphabet, '-' or '_' for line code.",
      inputMoreCode: 'Please enter line code over 4 characters.',
      inputProductName: 'Please enter product name.',
      inputProcess: 'Please enter process name.',
    },
  },
  // /my/projects/_id/user <<>> --
  myProjectsIdUser: {
    title: 'My Page',
    projectAdmin: 'Project Admin',
    companyAdmin: 'Company Admin',
    user: 'Users',
    total: '{count} users',
    addUser: 'Add user',
    lineInfo: 'Line Info',
    addLine: 'Add Line',
    permission: 'Add Permission',
    line: 'Line',
    removeUser: 'Remove User',
    remove: 'Remove',
    removeUserMsg: 'Remove {name}({username}) from users?',
    breadcrumb: {
      project: 'Project',
      user: 'User',
    },
    fm: {
      addUserSuc: 'Added users(s).',
      removeUserSuc: 'Removed user.',
    },
    // 추가
    training: 'Traininig',
    monitoring: 'Monitoring',
  },
  // /my/projects/_id/user/manager <<>> --
  myProjectsIdUserManager: {
    title: 'My Page',
    projectAdmin: 'Project Admin',
    companyAdmin: 'Company Admin',
    user: 'Users',
    total: '{count} admins',
    moreAdmin: 'Add Admin',
    removeAmin: 'Remove Admin',
    remove: 'Remove',
    removeUserMsg: 'Remove {name}({username})from admins?',
    addAdmin: 'Add Admin',
    admin: 'Admin',
    breadcrumb: {
      project: 'Project',
      user: 'Admin',
    },
    fm: {
      addAdminSuc: 'Added project admin.',
      removeAdminSuc: 'Removed project admin.',
    },
  },
  // /my/user  <<>> --
  myUser: {
    user: 'Users',
    createUser: 'Add Users',
    total: 'Total {count} users',
    filter: {
      name: 'Project Name',
      department: 'Department',
      condition: 'Filter',
    },
    role: {
      type: 'Type',
      system: 'System Admin',
      service: 'Service Admin',
      company: 'Company Admin',
      user: 'User',
    },
    fields: {
      name: 'Name',
      company: 'Company',
      roldId: 'Type',
      department: 'Department',
      engaged: 'Participating Project(s)',
      createdAt: 'Created at',
    },
    breadcrumb: {
      users: 'User List',
    },
    // 추가
    count: ' {count}',
  },
  // /my/user/create <<>> --
  myUserCreate: {
    user: 'User',
    info: 'User Info',
    type: 'User Type',
    admin: 'Admin',
    common: 'General Admin',
    company: 'Company',
    chooseCompany: 'Please select company.',
    selectCompany: 'Select company',
    project: 'Project',
    total: '{count} items',
    selectProject: 'Select project',
    lineInfo: 'Line info',
    addLine: 'Add Line',
    addPermission: 'Add Permission',
    line: 'Line',
    id: 'ID',
    inputId: 'Please enter an ID.',
    checkDuplicate: 'Check Duplicate',
    validId: 'ID available to use.',
    invalidId: 'ID already registered.',
    name: 'Name',
    inputName: 'Please enter a name.',
    department: 'Department',
    selectDepartment: 'Please enter a department.',
    done: 'Done',
    selectedCompany: 'Select Company',
    filter: {
      company: 'Company Name',
      condition: 'Filter',
    },
    fields: {
      name: 'Company Name',
      projectCnt: 'Project Count',
      lineCnt: 'Line Count',
      managerCnt: 'Admin Count',
      userCnt: 'User Count',
      createdAt: 'Created at',
    },
    projectFields: {
      title: 'Project Name',
      code: 'Project Code',
      company: 'Company',
      user: 'User',
      line: 'Line',
      createdAt: 'Created at',
    },
    breadcrumb: {
      user: 'User',
      userCreate: 'Add User',
    },
    fm: {
      inputId: 'Please enter an ID over 4 characters.',
      chooseCompany: 'Please select a company.',
      chooseProject: 'Please select a project.',
      choosePermission: 'Please select at least one permission per project.',
      checkIdDuplicate: 'Please check duplicate ID.',
      duplicateId: 'Cannot use duplicate ID.',
      createPw: 'Please create a password.',
      inputName: 'Please enter a name.',
      inputDepartment: 'Please enter a department',
    },
  },
  // /my/user/_id  <<>> --
  myUserId: {
    title: 'User',
    id: 'ID',
    name: 'Name',
    company: 'Company',
    department: 'Department',
    edit: 'Edit Info',
    projectList: 'Project List',
    setDefault: 'Set Default Project',
    default: 'Default Project',
    admin: 'Admin',
    user: 'User',
    permission: 'Permission',
    editPermission: 'Edit Permission',
    noEngagedProject: 'Not participating in any project.',
    list: 'To List',
    removeUser: 'Remove User',
    remove: 'Remove',
    removeUserMsg: 'Remove{username} user?',
    breadcrumb: {
      user: 'User',
    },
    fm: {
      removeSuccess: 'Successfully removed the user.',
    },
  },
  // /my/user/_id/edit  <<>> --
  myUserIdEdit: {
    title: 'User',
    info: 'Info',
    id: 'ID',
    name: 'Name',
    department: 'Department',
    departmentName: 'Deparment Name',
    pw: 'Password',
    newPw: 'New Password',
    done: 'Updated',
    breadcrumb: {
      user: 'User',
      edit: 'Edit Info',
    },
  },
  // /cs  <<>> --
  cs: {
    lgCenter: 'LG PRI AI platform customer center',
    center: 'Customer Center',
    lgAsk: 'If you have any questions while using out system, please contact us using the method below.',
    ask: 'If you have any questions, please let us know at any time',
    inquiryWindow: 'Inquiry Example',
    inquiryDesc: 'Please send your request via email and we will respond as soon as possible.',
    generalInquiry: 'Register General Inquiry',
    withinTwo: 'We will reply within 2 business days.',
    tips: 'Inquiry Email Format',
    tipsDesc1: 'Please leave the name/ affiliation/ contact information',
    tipsDesc2: 'Please write a detailed inquiry about the name of the platform, project, and issue that occured',
    ex1: 'ex',
    ex2: 'Platform  : MAVIN Cloud',
    ex3: 'Project : DD_냉장고_라벨검사_상부',
    tipsDesc3: 'Attaching a screen image will be more helpful to understand the issue.',
    example: 'Inquiry Email Example',
    title: 'Title',
    company: '[Affiliation]',
    inquiryCS: 'CS inquiry',
    tipsDesc4: 'Selected item is invisible due to labeling merge',
    content: 'Content',
    contentEx1: 'Hong gil-dong / Mfg.AI Solution Team/ 010-1234-1234',
    contentEx2: 'Platform  : MAVIN Cloud',
    contentEx3:
      'I selected the data list to merge on the data processing creation scene, but the slected data list is not visible.',
    contentEx4: 'Project : ',
    contentEx5: 'Selected data to merge',
    emergency: 'Register Emergency Inquiry',
    operation: '10AM ~ 06PM (business day)',
    process: 'Process Procedure',
    inquiry: 'Inquiry',
    inquiryType: 'Register general inquiry / Register emergency inquiry',
    confirmation: 'Inquiry confirmation and Guidance',
    confirmDesc:
      'We will classify and respond to inquiries according to the classification criteria below within 2 business days from the date of inquiry receipt',
    simpleInquiry: 'Simple Usage Inquiry',
    simpleCase: 'In case of inquiries requiring brief guidance due to lack of understanding of how to use',
    computerInquiry: 'Computational Related Inquiry',
    computerCase:
      'Inquiry is received becasue data needs to be modified or deleted, but cannot be processed on the system due to reasons such as validation',
    defectsInquiry: 'Defect Repair Request',
    defectsCase: 'If the inquiry is due to a defect in the system',
    featureInquiry: 'Feature Modification Request',
    featureCase: 'If the inquiry can be resolved by improving usability or performance',
    criteraAnswers: 'Guide to Classification Criteria and Forward Reply',
    classification: 'Guide to Classification Criteria',
    classificationDesc: 'Reply after process within 2 business day from notified date',
    classificationDesc2: 'Process after informing of defect repair schedule',
    classificationDesc3: 'Inform individually after completing repairements',
    classificationDesc4: 'Modify during the next version update',
    classificationDesc5: 'Inform individually after deploying revised updated version',
  },
  // /intro  <<>> --
  intro: {
    mavin:
      'MAVIN-Cloud is a GUI-based AI image training/testing platform that enables general users to use AI algorithms without coding or help of AI experts',
    desc1:
      'MAVIN-Cloud promotes fluid AI model development process such as data management, data labeling, AI model training and testing, ',
    desc2: 'in order to accelerate DX in manufacturing industry by reducing AI model development and deployment time.',
    desc3:
      'We are collaborating with LGE PRI Electronics Manufacturing Technology Center to facilitate MAVIN-Cloud in inspection processes for countless products globally.',
    desc4: 'On MAVIN-Cloud, you can upload data, label, train and test AI model without any coding.',
    desc5:
      'Since it is a cloud service, there is no need for you to configure GPU or other settings in order to start training an AI model.',
    feature: 'MAVIN-Cloud main features and flow',
    advanced: 'MAVIN-Cloud 2.0 new features',
    contact:
      'If you have questions or requests regarding MAVIN-Cloud, please forward them to below email address. We will be in touch with you as soon as possible.',
    feature1: 'Upload data accordingly (for training or for testing)',
    feature2: 'Label image data for training and testing',
    feature3: 'Select an algorithm from an algorithm pool updated by AI experts (constantly updated)',
    feature4: 'Verify predictions made by trained model, and check result',
    advancedTitle1: 'AI labeling feature',
    advanced1:
      'Easily label large amounts of data by using semi-auto labeling feature, which infers from a pretrained model.',
    advancedTitle2: 'Merge labeling and re-training models',
    advanced2:
      "Re-train models with pretrained model's parameter info and new dataset. By using labeling merge feature, you can keep aggregating new data into one dataset.",
    advancedTitle3: 'AI model performance comparison feature',
    advanced3:
      'You can view not only a single performance test, but also performance comparison between different models trained with same data.',
  },
  // /admin
  admin: {
    service: 'Service Administrator',
    statistics: 'Comprehensive Statistics',
    //변경 추가
    companyCnt: 'Total Company : ',
    count: '',
    projectCnt: 'Total Project : ',
    //
    userCnt: 'Total User : ',
    people: '',
    analysis: 'Usage Status Analysis',
    userTop10: 'Top 10 Company with Most Access',
    userTrend: 'User Access Trend',
    projectTrend: 'Project Action Trend',
    currentHistory: 'Recent Action History',
    detail: 'View more',
    username: '',
    verb: 'has',
    data: '',
    labeling: 'the data',
    processed: 'processed',
    training: '',
    learning: 'training',
    perfomed: 'perfomed',
    inference: '',
    evaluated: 'evaluated',
    performance: 'the performance',
    compare: '',
    comEvaluated: 'evaluated',
    compartive: 'the comparative performance',
    breadcrumb: {
      dashboard: 'Dashboard',
    },
  },
  // /admin/history
  adminHistory: {
    service: 'Service Administrator',
    filter: {
      company: 'Company',
      projectName: 'Project Name',
      all: 'All',
      labeling: 'Data Processing',
      training: 'Training',
      inference: 'Individual Inference',
      compare: 'Comparative Inference',
      condition: 'Filter',
    },
    username: '',
    verb: 'has',
    data: '',
    labeling: 'the data',
    processed: 'processed',
    training: '',
    learning: 'training',
    perfomed: 'perfomed',
    inference: '',
    evaluated: 'evaluated',
    performance: 'the performance',
    compare: '',
    comEvaluated: 'evaluated',
    compartive: 'the comparative performance',
    breadcrumb: {
      dashboard: 'Dashboard',
      history: 'History',
    },
  },
  // /admin/algorithm  <<>> --
  adminAlgorithm: {
    title: 'AI Algorithm',
    createAlgo: 'Register Algorithm',
    total: 'There are a Total of {count} Algorithms.',
    filter: {
      name: 'Algorithm Name',
      condition: 'Filter',
    },
    removeAlgo: 'Remove Algorithm',
    remove: 'Remove',
    removeAlgoMsg: 'Do you want to removed a selected algorithm?',
    fields: {
      name: 'Algorithm Name',
      type: 'Type',
      trainParams: 'Train Parameter',
      inferParams: 'Inference Parameter',
      createdAt: 'Registered Date',
    },
    breadcrumb: {
      list: 'Algorithm List',
    },
    workOptions: {
      type: 'Work Type',
    },
    fm: {
      removeAlgo: 'Algorithm has been removed.',
      changeDesc: 'Description of the algorithm has been changed.',
    },
    // 추가
    etc: '{name} except {count}',
    count: ' {count}',
  },
  // /admin/algorithm/create --
  admingAlgorithmCreate: {
    title: 'AI Algorithm',
    info: 'Algorithm Information',
    name: 'Algorithm Name',
    type: 'Algorithm Type',
    work: 'Work Type',
    scope: 'Scope to apply Algorithm',
    total: 'Entire Project',
    specific: 'Specific Project',
    // 추가
    algoProject: 'Algorithm Applied Project ',
    count: '{count}',
    //
    selectProject: 'Select project',
    chooseProject: 'Select project to apply the Algorithm',
    algoDesc: 'Algorithm Description',
    exInfo: 'Description Document',
    algoInfo: 'Algorithm Description Document',
    params: 'Parameter',
    trainParams: 'Train Parameter',
    inferParams: 'Inference Parameter',
    aug: 'Augmentation Setting',
    addAlgo: 'Register Algorithm',
    breadcrumb: {
      algorithm: 'Algorithm',
      create: 'Register',
    },
    algorithmOptions: {
      val1: 'AI Labeling',
      val2: 'Training ',
      val3: 'Data Augmentation',
    },
    fm: {
      addAlgoSuc: 'Algorithm has been registered successfully.',
      inputName: 'Enter the name of the Algorithm.',
      duplicateName: 'There is a duplicated algorithm name. Please enter different name.',
      chooseType: 'Please select a algorithm type.',
      chooseWork: 'Please select a type of work.',
      chooseScope: 'Please select the range to apply the algorithm.',
      chooseProject: 'Please select a project to apply the algorithm.',
      inputValidParams: 'Please enter the Default Value, Min, and Max values of the parameters correctly.',
      inputMust: 'Please enter the required values for the parameters.',
    },
  },
  // /admin/algorithm/_id/  <<>> --
  adminAlgorithmId: {
    title: 'AI Algorithm',
    name: 'Algorithm Name',
    type: 'Algorithm Type',
    work: 'Work Type',
    createdAt: 'Created Date',
    scope: 'Scope to apply Algorithm',
    all: 'Entire Project',
    specific: 'Specific Project',
    project: 'Algorithm applied Project',
    algoDesc: 'Algorithm Description',
    algoFile: 'Algorithm Description Document',
    trainParams: 'Train Parameter',
    inferParams: 'Inference Parameter',
    list: 'To List',
    removeAlgo: 'Remove Algorithm',
    edit: 'Edit Information',
    remove: 'Remove',
    removeAlgoMsg: 'Do you want to remove algorithm {name}?',
    fields: {
      name: 'Parameter Name',
      key: 'Parameter Key',
    },
    breadcrumb: {
      algorithm: 'Algorithm',
    },
    options: {
      type: 'Choose Work Type',
      val1: 'AI Labeling',
      val2: 'Train',
      val3: 'Augmentation',
    },
    fm: {
      incorrectInfo: 'Algorithm Information is incorrect.',
      removeSuccess: 'The algorithm has been deleted.',
    },
  },
  // /admin/algorithm/_id/edit  <<>> --
  adminAlgorithmIdEdit: {
    title: 'AI Algorithm',
    info: 'Algorithm Information',
    name: 'Algorithm Name',
    type: 'Algorithm Type',
    work: 'Work Type',
    scope: 'Scope to apply Algorithm',
    all: 'Entire Project',
    specific: 'Specific Project',
    // 추가
    project: 'Algorithm applied Project ',
    count: '{count}',
    //
    selectedProject: 'Select project',
    chooseProject: 'Select project to apply the algorithm.',
    algoDesc: 'Algorithm description',
    attached: 'Attachment',
    selectedFile: 'Currently Selected File',
    remove: 'Femove',
    algorithmInfo: 'Algorithm Description Document',
    params: 'Parameter',
    trainParams: 'Train Parameter',
    inferParams: 'Inference Parameter',
    aug: 'Augmentation Setting',
    done: 'Updated ',
    breadcrumb: {
      algorithm: 'Algorithm',
      edit: 'Edit',
    },
    algorithmOptions: {
      val1: 'AI Labeling',
      val2: 'Training',
      val3: 'Data Augmentation',
    },
    fm: {
      changeAlgorithm: 'Algorithm has been changed.',
      inputName: 'Please enter the name of the algorithm.',
      duplicateName: 'There is a duplicated algorithm name. Please enter different name.',
      chooseType: 'Please select a algorithm type.',
      chooseScope: 'Please select the range to apply the algorithm.',
      chooseProject: 'Please select a project to apply the algorithm.',
      inputValidParams: 'Please enter the Default Value, Min, and Max values of the parameters correctly.',
      inputMust: 'Please enter the required values for the parameters.',
    },
  },
  // /admin/company --
  adminCompany: {
    title: 'Company',
    createCompany: 'Register Company',
    total: 'There are a Total of {count} Companies.',
    filter: {
      name: 'Company Name',
      condition: 'Filter',
    },
    fields: {
      name: 'Company Name',
      projectCnt: 'Number of Projects',
      lineCnt: 'Number of Lines',
      managerCnt: 'Number of Administrator',
      userCnt: 'Number of Participants',
      createdAt: 'Registered Date',
    },
    breadcrumb: {
      list: 'Company List',
    },
    // 추가
    count: '{count} items',
    people: '{people} people',
  },
  // /admin/company/create --
  adminCompanyCreate: {
    company: 'Company',
    info: 'Company Information',
    name: 'Company Name',
    // 추가
    admin: 'Administrators : ',
    count: ' {count}',
    //
    addAdmin: 'Add Administrator',
    adminType: 'Administrator',
    createAdmin: 'Register Administrator',
    addAdminMsg: 'Add an Administrator',
    done: 'Registeration Complete',
    breadcrumb: {
      company: 'Company',
      companyCreate: 'Register Company',
    },
    fm: {
      duplicateAdmin: 'Company already in the list.',
      inputName: 'Please enter the name of the company.',
      duplicateName: 'There is a duplicated company name. Please enter different name.',
      addCompanySuccess: 'Company has been added.',
    },
  },
  // /admin/company/_id --
  adminCompanyId: {
    company: 'Company',
    name: 'Company Name',
    edit: 'Edit',
    createdAt: 'Registered Date',
    updatedAt: 'Edit Date',
    projectList: 'Project List',
    addProject: 'Register Project',
    userCnt: 'Participants : {count}',
    editInfo: 'Edit Project Information',
    managerUser: 'Manage Users',
    noProject: 'There are no Registered Projects',
    // 추가
    adminCnt: 'Administrators ',
    count: '{count}',
    //
    addAdmin: 'Add Administrator',
    noAdmin: 'There are no Administrator',
    list: 'To List',
    removeCompany: 'Remove Company ',
    createAdmin: 'Register Administrator',
    admin: 'Administrator',
    removeAdmin: 'Remove Administrator',
    remove: 'Remove',
    removeAdminMsg: "Do you want to delete the administrator '{name}({username})' from customer '{companyName}'?",
    removeCompany: 'Remove Company ',
    removeCompanyMsg: 'Do you want to delete the company {company}?',
    fm: {
      error: 'An error occured.',
      removeSuccess: 'Administrator has been deleted.',
      inputName: 'Please enter the name of the company.',
      changeName: 'Please change the name of the company.',
      duplicateName: 'There is a duplicated company name. Please enter different name.',
      changeNameSuc: 'The name of the company has been changed.',
      removeCompanySuc: 'The cusomter has been removed.',
    },
    breadcrumb: {
      company: 'Company',
    },
  },
  // /admin/notice  <<>>> --
  adminNotice: {
    notice: 'Notice',
    createdNotice: 'Register Notice',
    all: 'Notice for All',
    specific: 'Notice for Each Company',
    removeNotice: 'Remove Notice',
    remove: 'Remove',
    removeNoticeMsg: 'Do you wish to remove the selected notice?',
    fields: {
      title: 'Title',
      updatedAt: 'Updated Date',
    },
    breadcrumb: {
      list: 'Notice List',
    },
    fm: {
      removeSuccess: 'Notice has been removed.',
    },
  },
  // /admin/notice/create <<>> --
  adminNoticeCreate: {
    management: 'Notice Management',
    title: 'Title',
    noticeTitle: 'Notice Title',
    content: 'Content',
    noticeContent: 'Content',
    attached: 'Attachment',
    noticeAttached: 'Notice Attachment',
    done: 'Completed',
    breadcrumb: {
      notice: 'Notice',
      all: 'Register Notice for All',
    },
    fm: {
      inputTitle: 'Please enter a title for the notice.',
      inputContent: 'Please enter the content of the notice.',
      uploadFail: 'Failed to upload the file.',
      addNoticeSuccess: 'Notice has been created.',
    },
  },
  // /admin/notice/_id  <<>> --
  adminNoticeId: {
    management: 'Notice Management',
    title: 'Title',
    content: 'Content',
    attached: 'Attachment',
    list: 'To List',
    remove: 'Remove',
    edit: 'Edit',
    removeNotice: 'Remove Notice',
    remove: 'Remove',
    removeNoticeMsg: 'Do you want to remove the notice?',
    breadcrumb: {
      notice: 'Notice',
    },
    fm: {
      removeSuccess: 'Notice has been removed.',
    },
  },
  // /admin/notice/_id/edit  <<>> --
  adminNoticeIdEdit: {
    management: 'Notice Management',
    title: 'Title',
    noticeTitle: 'Notice Title',
    content: 'Content',
    noticeContent: 'Notice Content',
    attached: 'Attachment',
    file: 'Current File',
    remove: 'Remove ',
    noticeAttached: 'Notice Attachment',
    done: 'Modified',
    breadcrumb: {
      notice: 'Notice',
      edit: 'Edit',
    },
    fm: {
      changeNotice: 'Notice has been changed.',
      inputTitle: 'Please enter a title for the notice.',
      inputContent: 'Please enter the content of the notice.',
      uploadFail: 'Failed to upload the file.',
    },
  },
  // /admin/notice/company  <<>> --
  adminNoticeCompany: {
    notice: 'Notice',
    createNotice: 'Register Notice',
    filter: {
      all: 'Notice for All',
      specific: 'Notice for Each Company',
      name: 'Company Name',
      condition: 'Filter',
    },
    removeNotice: 'Remove Notice',
    remove: 'Remove',
    removeNoticeMsg: 'Do you want to remove the notice?',
    fields: {
      company: 'Company ',
      title: 'Title',
      updatedAt: 'Updated Date',
    },
    breadcrumb: {
      list: 'Notice List',
      specific: 'Notice for Each Company',
    },
    fm: {
      removeSuccess: 'Notice has been removed.',
    },
  },
  // /admin/notice/company/create  <<>> --
  adminNoticeCompanyCreate: {
    management: 'Notice Management',
    company: 'Company',
    selectedCompany: 'Select Company',
    selectCompany: 'Please select a company',
    title: 'Title',
    noticeTitle: 'Notice Title',
    content: 'Content',
    noticeContent: 'Notice Content',
    attached: 'Attachment',
    noticeAttached: 'Notice Attachment',
    done: 'Completed',
    breadcrumb: {
      notice: 'Notice',
      specific: 'Notice for Each Company',
      created: 'Register',
    },
    fm: {
      uploadFail: 'Failed to upload the file.',
      createSuccess: 'Notice has been created.',
      chooseCompany: 'Choose at least one company.',
      inputTitle: 'Please enter a title for the Notice.',
      inputContent: 'Please enter the content of the Notice.',
    },
  },
  // /admin/notice/company/_id  <<>> --
  adminNoticeCompanyId: {
    management: 'Notice Management',
    company: 'Company',
    title: 'Title',
    content: 'Content',
    attached: 'Attachment',
    list: 'To List',
    remove: 'Remove',
    edit: 'Edit',
    removeNotice: 'Remove Notice',
    removeNoticeMsg: 'Do you want to remove the notice?',
    breadcrumb: {
      notice: 'Notice for Each Company',
    },
    fm: {
      removeSuccess: 'Notice has been removed.',
    },
  },
  // /admin/notice/company/_id/edit  <<>> --
  adminNoticeCompanyIdEdit: {
    management: 'Notice Management',
    company: 'Company',
    selectedCompany: 'Select company',
    selectCompany: 'Please select a company',
    title: 'Title',
    noticeTitle: 'Notice Title',
    content: 'Content',
    noticeContent: 'Notice Content',
    attached: 'Attachment',
    file: 'Current File',
    remove: 'Remove',
    noticeAttached: 'Notice Attachment',
    done: 'Modified',
    fields: {
      name: 'Company Name',
      projectCnt: 'Number of Projects',
      lineCnt: 'Number of Lines',
      managerCnt: 'Number of Administrators',
      userCnt: 'Number of Participants',
      createdAt: 'Registered Date',
    },
    breadcrumb: {
      specific: 'Notice for Each Company',
      edit: 'Edit',
    },
    fm: {
      editSuccess: 'Notice has been changed.',
      uploadFail: 'Failed to upload the file.',
      chooseCompany: 'Choose at least one company.',
      inputTitle: 'Please enter a title for the notice.',
      inputContent: 'Please enter the content of the notice.',
    },
  },
  // /admin/project --
  adminProject: {
    project: 'Project',
    createProject: 'Register Project',
    total: 'There are a total of {count} Projects.',
    filter: {
      company: 'Company Name',
      name: 'Project Name',
      code: 'Project Code',
      condition: 'Filter',
    },
    fields: {
      title: 'Project Name',
      code: 'Project Code',
      company: 'Company Name',
      userCnt: 'Number of Participants',
      lineCnt: 'Number of Lines',
      updatedAt: 'Recently Updated Date',
    },
    breadcrumb: {
      list: 'Project List',
    },
    // 추가
    count: '{count}',
    people: '{people} people',
  },
  // /admin/project/create  <<>> --
  adminProjectCreate: {
    createProject: 'Register Project',
    info: 'Company Information',
    company: 'Company',
    chooseCompany: 'Please choose a company',
    selectCompany: 'Select company',
    newCompany: 'Register new company',
    projectInfo: 'Project Information',
    projectTitle: 'Project Name',
    projectCode: 'Project Code',
    checkDuplicate: 'Check duplication',
    validCode: 'This project code can be registered.',
    invalidCode: 'Project code cannot be modified after registration.',
    invalidCode2: "Only upper and lower case letters, numbers, '-', and '_' can be used",
    projectDesc: 'Project Description',
    image: 'Product Image',
    moreImage: 'Add Images',
    default: 'Default',
    setDefault: 'Set as Default',
    addImage: 'Please add images',
    lineInfo: 'Line Information',
    moreLine: 'Add Line',
    lineName: 'Line Name',
    lineCode: 'Line Code',
    productName: 'Product Name',
    processName: 'Process name',
    invalidCodeMsg:
      "Only upper and lower case letters, '-', and '_' can be used for line code, and unavailable if there's a duplicate code.",
    addLine: 'Please add Lines',
    done: 'Registeration Complete',
    projectLimitPolicy: 'Project Limit Policy',
    usingPeriod: 'Period of Use',
    imageUploadCount: 'Image Upload Count',
    learnCount: 'Learn Count',
    breadcrumb: {
      project: 'Project',
      create: 'Register Project',
    },
    fm: {
      inputCode: 'Please enter a project code of 4 characters or more.',
      codeEror: 'Project codes starting with {code} cannot be used.',
      error: 'An error occured.',
      errorMsg: 'Error message : ',
      createSuccess: 'Project registeration complete.',
      duplicateLineName: 'There is a duplicated line name.',
      duplicateLineCode: 'There is a duplicated line code.',
      chooseCompany: 'Please choose a company. ',
      inputProject: 'Please enter a name of the project.',
      checkDuplicate: 'Please check for the project code duplication.',
      inputLineName: 'Please enter a name of the line.',
      inputLineCode: 'Please enter a code of the line.',
      validateLine: "The line code can only be entered from 0 to 9, upper and lower case letters, '-' or '_'.",
      inputMoreCode: 'Please enter at least 4 characters for the line code.',
      inputProductName: 'Please enter a name of the product.',
      inputProcess: 'Please enter a name of the process.',
      validateInteger: 'Only numbers are allowed for Image Upload Count and Learn Count.',
      validateDate: 'Please enter the usage period.',
    },
  },
  // /admin/project/_id --
  adminProjectId: {
    project: 'Project',
    info: 'Project Information',
    company: 'Company Name',
    title: 'Project Name',
    code: 'Project Code',
    createdAt: 'Registered Date',
    updatedAt: 'Updated Date',
    desc: 'Project description',
    noDesc: 'There is no Project description.',
    image: 'Product Image',
    default: 'Default',
    noImage: 'There are no images',
    lineInfo: 'Line Information',
    noLineInfo: 'There are no line information.',
    userInfo: 'User Information',
    // 추가
    admin: 'Administrator ',
    user: 'Participants ',
    count: '{count}',
    //
    detail: 'View more',
    list: 'To List',
    removeProject: 'Remove Project',
    edit: 'Edit Information',
    remove: 'Remove',
    removeProjectMsg: 'Do you want to remove a project?',
    fields: {
      name: 'Line Name',
      code: 'Line Code',
      produceName: 'Product',
      processName: 'Process',
    },
    breadcrumb: {
      project: 'Project',
    },
    fm: {
      removeSuccess: 'Project has been removed.',
    },
  },
  // /admin/project/_id/edit  <<>> --
  adminProjectIdEdit: {
    project: 'Project',
    companyInfo: 'Company Information',
    companyName: 'Company Name',
    info: 'Project Information',
    title: 'Project Name',
    code: 'Project Code',
    desc: 'Project Description',
    image: 'Product Image',
    moreImage: 'Add Images',
    default: 'Default',
    setDefault: 'Set as Default',
    addImage: 'Please add images.',
    lineInfo: 'Line Information.',
    moreLine: 'Add Line',
    lineName: 'Line Name',
    lineCode: 'Line Code',
    productName: 'Product Name',
    processName: 'Process Name',
    invalidCodeMsg:
      "Only upper and lower case letters, '-', and '_' can be used for line code, and unavailable if there's a duplicate code.",
    addLine: 'Please add a line.',
    done: 'Modified',
    breadcrumb: {
      project: 'Project',
      edit: 'Edit Information',
    },
    fm: {
      uploadFail: 'Failed to upload a image.',
      editSuccess: 'Project has been modified.',
      duplicateLineName: 'There is a duplicated line name.',
      duplicateLineCode: 'There is a duplicated line code.',
      inputProject: 'Please enter a project name.',
      inputLineName: 'Please enter a line name.',
      inputLineCode: 'Please enter a line code.',
      validateLine: "The line code can only be entered from 0 to 9, upper and lower case letters, '-' or '_'.",
      inputMoreCode: 'Please enter at least 4 characters for the line code.',
      inputProductName: 'Please enter a product name.',
      inputProcess: 'Please enter a process name.',
    },
  },
  // /admin/project/_id/user  <<>> --
  adminProjectIdUser: {
    project: 'Project',
    projectAdmin: 'Project Administrator',
    companyAdmim: 'company Administrator',
    user: 'Participants',
    total: 'There are a total of {count} users',
    createUser: 'Register Participants',
    lineInfo: 'Line Information',
    moreLine: 'Add Line',
    morePermission: 'Add Permission',
    line: 'Line',
    removeUser: 'Remove Participants',
    remove: 'Remove',
    removeUserMsg: 'Do you want to remove {name}({username}) from the participants?',
    breadcrumb: {
      project: 'Project',
      user: 'Participants',
    },
    fm: {
      cratedSuccess: 'Added participants.',
      removeSuccess: 'Removed participatns.',
    },
  },
  // /admin/project/_id/user/manager  <<>> --
  adminProjectIdUserManager: {
    project: 'Project ',
    projectAdmin: 'Project Administrator',
    companyAdmim: 'Company Administrator',
    user: 'Participants',
    total: 'There are a total of {count} company managers',
    moreAdmin: 'Add Company Administrator',
    createAdmin: 'Register Administrator',
    admin: 'Administrator',
    removeAdmin: 'Remove Administrator',
    remove: 'Remove',
    removeAdminMsg: "Do you want to delete the Administrator '{name}({username})' from company '{company}'?",
    breadcrumb: {
      project: 'Project',
      admin: 'Administrator',
    },
    fm: {
      createSuccess: 'Added company Administrator.',
      removeSuccess: 'Removed company Administrator.',
    },
  },
  // /admin/user --
  adminUser: {
    user: 'User',
    createUser: 'Register User',
    resetRequest: 'Request for password reset',
    total: 'There are a total of {count} users',
    filter: {
      company: 'Company Name',
      project: 'Project Name',
      department: 'Department',
      condition: 'Filter',
    },
    fields: {
      name: 'Name',
      company: 'Company',
      role: 'Role',
      department: 'Department',
      project: 'Affiliation Project',
      createdAt: 'Created Date',
    },
    breadcrumb: {
      list: 'User List',
    },
    role: {
      type: 'Type',
      system: 'System Administrator',
      service: 'Service Administrator',
      company: 'Company Administrator',
      user: 'user',
    },
    // 추가
    count: ' {count}',
  },
  // /admin/user/create --
  adminUserCreate: {
    user: 'User',
    info: 'User information',
    userType: 'User Type',
    admin: 'Administrator',
    general: 'General User',
    company: 'Company',
    chooseCompany: 'Please choose a company',
    selectCompany: 'Select company ',
    newCompany: 'Register new company',
    // 추가
    project: 'Projects ',
    count: '{count}',
    //
    selectProject: 'Select Project',
    lineInfo: 'Line Information',
    moreLine: 'Add Line',
    morePermission: 'Add Permission',
    line: 'Line',
    chooseProject: 'Please select a Project',
    id: 'ID',
    inputId: 'Please enter a ID',
    checkDuplicate: 'Check Duplication',
    validId: 'ID is available.',
    invalidId: 'ID already in use.',
    name: 'Name',
    inputName: 'Please enter a Name',
    department: 'Department',
    inputDepartment: 'Please enter a department.',
    expiredAt: 'Account Expiration Date',
    done: 'Registeration Complete',
    breadcrumb: {
      user: 'User',
      createUser: 'Register User',
    },
    fm: {
      inputMoreId: 'Please enter a ID of 4 characters or more.',
      chooseCompany: 'Please choose a company.',
      chooseProject: 'Please choose a project.',
      choosePermission: 'Please select one or more permissions per project.',
      checkDuplicate: 'Please check for the ID duplication.',
      duplicatedId: 'Duplicated ID cannot be used.',
      inputPw: 'Please create a password.',
      inputName: 'Please enter a name.',
      inputDepartment: 'Please enter a department.',
      inputTrial: 'Please enter a expiration date for trial user.',
    },
  },
  // /admin/user/_id  <<>> --
  adminUserId: {
    user: 'User',
    id: 'ID',
    name: 'Name',
    company: 'Company Name',
    department: 'Department',
    createdAt: 'Registered Date',
    edit: 'Modified Information',
    list: 'Project List',
    default: 'Default Project Setting',
    defaultProject: 'Default Project ',
    admin: 'Administrator',
    user: 'Participants',
    permission: 'Permission',
    done: 'Modify Permission',
    noProject: 'There are no Afiiliated Project.',
    list: 'To List',
    removeUser: 'Remove User',
    remove: 'Remove',
    removeUserMsg: 'Do you want to remove user {username}?',
    breadcrumb: {
      user: 'User',
    },
    fm: {
      removeSuccess: 'A User has been removed.',
    },
  },
  // /admin/user/_id/edit <<>> --
  adminUserIdEdit: {
    user: 'User ',
    info: 'Information',
    id: 'ID',
    name: 'Name',
    department: 'Department',
    expiredAt: 'Account Expiration Date',
    pw: 'Password',
    newPw: 'New Password',
    done: 'Modified',
    breadcrumb: {
      user: 'User ',
      edit: 'Edit Information',
    },
    fm: {
      editSuccess: 'Successfully edited user information.',
      inputName: 'Please enter a name.',
      inputDepartment: 'Please enter a department.',
    },
  },
  // /admin/user/reset  <<>> --
  adminUserReset: {
    user: 'User',
    resetPw: 'Password Reset Request',
    unprocessedMsg: 'There are a total of {count} unprocessed change requests.',
    filter: {
      status: 'Process Status',
      unprocessed: 'Unprocessed',
      done: 'Modified',
      invalid: 'Invalid',
      condition: 'Filter',
    },
    deletedCompany: 'Deleted Company',
    deletedUser: 'Deleted User',
    fields: {
      company: 'Company Name',
      createdAt: 'Request Date',
      updatedAt: 'Processed Date',
      status: 'Process Status',
    },
    breadcrumb: {
      list: 'User List',
      reset: 'Password Reset Request',
    },
  },
  // /admin/user/reset/_id   <<>> --
  adminUserResetId: {
    user: 'User',
    info: 'Requested Information',
    id: 'ID',
    status: 'Process Status',
    unprocessed: 'Unprocessed',
    done: 'Modified',
    invalid: 'Invalid',
    idInfo: 'ID Information',
    targetId: 'Target ID',
    name: 'Name',
    company: 'Company',
    type: 'Type',
    department: 'Department',
    pw: 'Password',
    newPw: 'New Password',
    done: 'Modified',
    list: 'To List',
    role: {
      system: 'System Administrator',
      service: 'Service Administrator',
      company: 'Company Administrator',
      user: 'User',
    },
    breadcrumb: {
      user: 'User',
      reset: 'Password Reset Request',
    },
    fm: {
      success: 'Process status has changed.',
      resetSuccess: 'Password for {searchId} has ben reset.',
      inputPw: 'Please enter a password.',
    },
  },
  // /monitoring <<>>
  // /monitoring/model-history  <<>> --
  monitoringModelHistory: {
    history: 'Model History',
    fields: {
      createdAt: 'Created at',
      name: 'Model Name',
      input: 'Training data directory',
      rate: 'Training Performance',
      class: 'OK/NG Types',
    },
    breadcrumb: {
      history: 'History',
    },
  },
  // /monitoring/inspection <<>> --
  monitoringInspection: {
    inspection: 'Performance Verification',
    option: 'Verification Option',
    filter: {
      all: 'All',
      before: 'Before Verification',
      done: 'Verification Complete',
      condition: 'Filter',
    },
    extraction: {
      random: 'Auto (random sampling)',
      active: 'Auto (active learning)',
      manual: 'Manual',
    },
    status: {
      done: 'Verification Complete',
      before: 'Before Verification',
    },
    nowWorking: 'Currently working',
    myWorking: 'Working',
    available: 'Available',
    inspect: 'Verify',
    result: 'Result',
    send: 'Send',
    sendData: 'Send Verification Data',
    sendDataMsg: 'Send {count} Verification data?',
    removeInspection: 'Remove performance verification',
    remove: 'Remove',
    removeInspectionMsg: 'Remove selected performance Verification?',
    fields: {
      status: 'Verified',
      model: 'Applied Model',
      type: 'Task Type',
      extraction: 'Extraction Type',
      date: 'Date',
      cnt: 'product count / Verification count',
      user: 'Verifier',
      performance: 'Verification performance',
      busy: 'Use Status',
      inspection: 'Inspect',
      result: 'Verification Result',
      send: 'Send',
      remove: 'Remove',
    },
    breadcrumb: {
      list: 'Verification Result',
    },
    usage: 'Usage',
    fm: {
      busyMsg: '{username} is working',
      removeSuccess: 'Removed',
      sendSuccess: 'Sent.',
    },
  },
  // /monitoring/inspection/option  <<>> --
  monitoringInspectionOption: {
    inspection: 'Performance Verification',
    option: 'Verification option',
    sampling: 'Add sampling',
    noOption: 'No set option',
    inactive: 'Inactive',
    method: 'Method',
    period: 'Sampling Period',
    threshold: 'Threshold',
    rate: 'Sampling Rate',
    samplingTime: 'Sampling Time',
    samplingMsg: 'Sampled at 00:00 AM',
    updateOption: 'Apply Option',
    periodOptions: {
      first: 'first',
      second: 'second',
      third: 'third',
      fourth: 'fourth',
      fifth: 'fifth',
      sixth: 'sixth',
      seventh: 'seventh',
    },
    fm: {
      inputThreshold: 'Please enter 0~1 for threshold.',
      inputRate: 'Please enter 0~100 for sampling rate.',
      changeOption: 'Verification option updated.',
    },
    breadcrumb: {
      inspection: 'Performance Verification',
      option: 'Verification option setting',
    },
  },
  // /monitoring/inspection/report --
  monitoringInspectionResult: {
    report: 'Performance Report',
    target: 'Target',
    period: 'Period',
    date: 'Date',
    result: 'View result',
    trend: 'Accuracy trend',
    checkResult: 'View evaluation result',
    resultCnt: 'verification result applied / target images {count}',
    fieldModel: 'Line applied model',
    ngRate: 'NG rate',
    typeCnt: 'Count per Class Type',
    accuracyRate: 'Overall Accuracy',
    classPrecision: 'Class Precision',
    classRecall: 'Class Recall',
    classResult: 'Class Prediction Result',
    accuracy: 'Accuracy',
    classMAP: 'Class mAP',
    editImageCnt: 'Modified images',
    judgementImage: 'Prediction image',
    inspectionImage: 'Verification image',
    resultList: 'Prediction List',
    fields: {
      name: 'Class',
      predictionCnt: 'Prediction Count',
      annotaionCnt: 'verification Count',
    },
    filename: 'File Name',
    breadcrumb: {
      report: 'View by Date',
    },
    positive: 'positive',
    negative: 'negative',
    // 추가
    count: ' {count}',
  },
  // /monitoring/inspection/sampling --
  monitoringInspectionSampling: {
    inspection: 'Performance Verification',
    option: 'Verification Option',
    sampling: 'Add sampling',
    period: 'Period',
    time: 'Time',
    samplingCnt: 'Sampling Count',
    cntWarning: 'Enter between 100~1000.',
    createSampling: 'Create Sampling',
    breadcrumb: {
      inspection: 'Performance Verification',
      sampling: 'Add Sampling',
    },
    fm: {
      createSuccess: 'Successfully created sampling.',
    },
  },
  // /monitoring/inspection/_id --
  monitoringInspectionId: {
    result: 'Performance Verification Result',
    checkResult: 'Inspection Result',
    resultCnt: 'Model Predictions / target images {count}',
    fieldModel: 'Line applied model',
    ngRate: 'NG rate',
    typeCnt: 'count per class type',
    inspect: 'Verify',
    list: 'To List',
    fields: {
      name: 'Class',
      predictionCnt: 'Prediction count',
      annotaionCnt: 'verification count',
    },
    positive: 'positive',
    negative: 'negative',
    over: 'false negative',
    breadcrumb: {
      inspection: 'Performance Verification ',
      result: 'Result',
    },
  },
  // /monitoring/inspection/_id/cf  <<>> --
  // /monitoring/inspection/_id/od <<>> --
  monitoringInspectionIdCfOd: {
    inspection: 'Performance Verification',
    info: 'Work Info',
    type: 'work Type',
    modelAlgo: 'Model / Algorithm',
    createdAt: 'Created At',
    save: 'Save Verification Result',
    timeout: 'Timeout',
    timeoutMsg: 'Work Terminating in 1 minute. Do you want to Extend?',
    breadcrumb: {
      inspection: 'Performance Verification',
      monitoring: 'Verify',
    },
    min: 'minute(s)',
    sec: 'second(s)',
    fm: {
      saveSuccess: 'Successfully Saved Verification Result.',
    },
  },
  // /monitoring/inspection/_id/result/cf --
  // /monitoring/inspection/_id/result/od --
  monitoringInspectionIdResultCfOd: {
    title: 'Performance Verification Result',
    checkResult: 'Performance Verification Result',
    resultCnt: 'Verification Result Applied / Target Images {count}',
    fieldModel: 'Line Applied Model',
    ngRate: 'NG Rate',
    typeCnt: 'Count Per Class Type',
    performance: 'Model Performance',
    result: 'Verification Result',
    targetResult: 'Model Prediction Result',
    accuracyRate: 'Overall Accuracy',
    classPrecision: 'Class Precision',
    classRecall: 'Class Recall',
    classAccuracy: 'Class Accuracy',
    classResult: 'Class Prediction',
    inspect: 'Verify',
    list: 'To List',
    fields: {
      name: 'Class',
      predictionCnt: 'Prediction Count',
      annotaionCnt: 'Verification Count',
    },
    positive: 'positive',
    negative: 'negative',
    cnt: 'false negative',
    breadcrumb: {
      inspection: 'Performance Verification',
      result: 'Result',
    },
    createdAt: 'Created At',
    period: 'Period',
    accuracy: 'Accuracy',
    classMAP: 'Class mAP',
    ngFields: {
      name: 'File Name',
    },
  },
  // /monitoring/line <<>> --
  monitoringLine: {
    line: 'Line Status',
    serialNum: 'Serial Number',
    nowTime: 'Current Time',
    status: 'Prediction Status',
    transference: 'Transference',
    transferenceMsg: '{count} NG out of {total}',
    resultList: 'Prediction List',
    checkError: 'View NG',
    lastUpdatedAt: 'Last Updated',
    fields: {
      serialNum: 'Serial Number',
      result: 'Prediction',
    },
    breadcrumb: {
      line: 'Line Monitoring',
    },
  },
  // /monitoring/line/report --
  monitoringLineReport: {
    report: 'Inspection Report',
    byPeriod: 'Filter by Period',
    byCharacter: 'Filter by Character',
    period: 'Target Period',
    date: 'Date',
    time: 'Time',
    checkResult: 'View result',
    errorInfo: 'NG info',
    // 추가
    byProductCnt: 'By product count',
    six: '6 Hours',
    twelve: '12 Hours',
    day: '1 Day',
    //
    totalCnt: 'Total Inspection Count',
    totalCntInfo: ' items ({direction}/ Total {totalCount} images)',
    errorCnt: 'NG Count',
    errorCntInfo: ' case (based on total product direction number)',
    avgTransference: 'Daily Average Transference Per Product',
    maxTransference: 'Daily Maximum Transference Per Product',
    minTransference: 'Daily Minimum Transference Per Product',
    trend: 'Trend By Period',
    loading: 'Loading',
    periodError: 'NG By Period',
    typeErrorCnt: 'NG Count Per Class',
    aiModel: 'AI Model',
    errorImage: 'NG Image By Class',
    fm: {
      diffError: 'Cannot Query over 30 days.',
    },
    chart: {
      error: 'NG Rate',
      cnt: 'Count',
    },
    ko: 'Korean',
    hour: 'hour(s)',
    min: 'minute(s)',
    sec: 'second(s)',
    increase: 'Increase',
    decrease: 'Decrease',
    selected: 'Selected',
    noSelected: 'No Selected Time',
    close: 'Close',
    breadcrumb: {
      report: 'Filter By Period',
    },
    current: 'Current',
  },
  // /monitoring/line/report/search <<>> --
  monitoringLineReportSearch: {
    report: 'Inspection Report',
    byPeriod: 'Filter By Period',
    byCharacter: 'Filter By Character',
    serialNum: 'Serial Number',
    checkResult: 'Check Result',
    createdAt: 'Created At',
    origin: 'Original Image',
    judgement: 'Prediction Image',
    fm: {
      searchResultError: 'No Product With the String.',
      inputSerialNum: 'Please Enter Serial Number to Search.',
    },
    // 추가
    breadcrumb: 'Filter By Character',
  },
  // 컴포넌트 modal-model-history-detail <<>> --
  modalModelHistoryDetail: {
    period: 'Applicable Period',
    createdAt: 'Train Date',
    trainingPerformance: 'Train Performance',
    trainingCnt: 'Train Count',
    inferenceDate: 'Inference Date',
    inferPerfomance: 'Inference Performance',
    inferenceCnt: 'Inference Count',
    algorithm: 'Algorithm',
    edit: 'Edit',
    close: 'Close',
    detail: 'Model History Detail',
    fm: {
      done: 'Description has been changed.',
    },
  },
  // 컴포넌트 modal-confusion-matrix-result  <<>> --
  modalConfusionMatrixResult: {
    total: 'Total {count}',
    close: 'Close',
  },
  // 컴포넌트 markup-classification-guide
  markupClassificationGuide: {},
  // 컴포넌트 modal-monitoring-result <<>> --
  modalMonitoringResult: {
    result: 'Predicted Result',
    origin: 'Original Image',
    judgement: 'Predicted Images',
    close: 'Close',
    direction1: 'direction1',
    direction2: 'direction2',
  },
  // 컴포넌트 modal-line-type-report <<>> --
  modalLineTypeReport: {
    errorImage: 'NG Images By Type',
    period: 'Period',
    name: 'Model Name',
    algorithm: 'Algorithm',
    type: 'Type',
    selectType: 'Select A Type',
    totalImage: '({count} images)',
    origin: 'Original Image',
    judgement: 'Predicted Images',
    resultList: 'List of Predicted Results',
    count: '',
    totalCnt: ' (detected : {prediction})',
    image: 'View Images',
    close: 'Close',
    fields: {
      name: 'File Name',
    },
  },
  // 컴포넌트 modal-line-period-report --
  modalLinePeriodReport: {
    errorImage: 'NG Images Within Period',
    period: 'Period',
    origin: 'Original Image',
    judgement: 'Predicted Images',
    resultList: 'List of Predicted Results',
    image: 'View Images',
    close: 'Close',
    fields: {
      serialNum: 'Serial Number',
      judgement: 'Prediction',
    },
    // 추가
    count: ' {count}',
  },
  // 컴포넌트 modal-user-add <<>> --
  modalUserAdd: {
    type: 'Participants Type',
    new: 'New User',
    old: 'Existing User',
    trial: 'Trial or Not',
    default: 'General',
    id: 'ID',
    inputId: 'Please Enter an ID',
    checkDuplicate: 'Check Duplication',
    validId: 'ID is Available',
    invalidId: 'ID Already in Use',
    name: 'Name',
    inputName: 'Please Enter a Aame',
    department: 'Department',
    inputDepartment: 'Please Enter a Department',
    expiredAt: 'Account Expiration Date',
    permission: 'Permission',
    addMsg: 'Register',
    selectedUser: 'Select User',
    chooseUser: 'Please Select a User',
    selectedData: 'Select Dataset',
    cancel: 'Cancel',
    add: 'Add',
    duplicateIdMsg1: '{username} is Already in Use.',
    duplicateIdMsg2: 'Do you want to register this ID as a User?',
    filter: {
      name: 'Name',
      department: 'Department',
      condition: 'Filter',
    },
    title: 'Register Participants',
    fields: {
      name: 'Name',
      department: 'Department',
      createdAt: 'Registered Date',
    },
    fm: {
      inputValidId: 'Please Enter a ID of 4 Characters or More.',
      duplicateUser: 'ID Already in Use. Please Enter a New ID.',
      inputId: 'Please Enter an ID.',
      checkDuplicate: 'Check ID Duplication.',
      createPw: 'Please Create a Password.',
      inputName: 'Please Enter a Name.',
      inputDepartment: 'Please Enter a Department.',
      inputTrial: 'Please Enter an Acoount Expiration Date.',
      choosePermission: 'Please Check for one or more Permission.',
      chooseUser: 'Please Choose a User.',
    },
    count: '{count}',
  },
  // 컴포넌트 modal-manager-add <<>> --
  modalManagerAdd: {
    id: 'ID',
    inputId: 'Please Enter an ID',
    checkDuplicate: 'Check Duplication',
    validId: 'ID is Available',
    invalidId: 'ID Already in Use',
    name: 'Name',
    inputName: 'Please Enter a Name',
    department: 'Department',
    inputDepartment: 'Please Enter a Department',
    addMsg: 'Register',
    duplicateIdMsg: '{username} is Already in Use. Do you want to register this ID as a User?',
    cancel: 'Cancel',
    add: 'Add',
    invalidIdMsg: '{username} is Not Available. Please Enter a different ID.',
    title: 'Register Administrator',
    admin: 'Administrator',
    fm: {
      inputValidId: 'Please Enter a ID of 4 characters or more.',
      invalidRoleId: 'This Account cannot be registered as an Administrator.',
      invalidId: 'This ID is Unavailable.',
      inputId: 'Please Enter an ID.',
      checkDuplicate: 'Check ID Duplication.',
      createPw: 'Please Create a Password.',
      inputName: 'Please Enter a Name.',
      inputDepartment: 'Please Enter a Department.',
    },
    // 추가
    duplicateIdMsg1: '{username} has already been registered.',
    duplicateIdMsg2: 'Do you wish to register the ID as a User?',
    duplicateIdMsg3: 'Do you wish to register the ID as an Administrator?',
  },
  // 컴포넌트 modal-multiple-select-datas <<>> --
  modalMultipleSelectDatas: {
    done: 'Selected',
    sample: 'Sample Images',
  },
  // 컴포넌트 modal-project-select  <<>> --
  modalProjectSelect: {
    filter: {
      company: 'Company Name',
      name: 'Project Name',
      code: 'Project Code',
      condition: 'Filter',
    },
    done: 'Selected',
    title: 'Select Project',
    fields: {
      title: 'Project Name',
      code: 'Project Code',
      company: 'Company',
      user: 'Participants',
      line: 'Line',
      createdAt: 'Registered Date',
    },
    count: '{count}',
    people: '{count}people',
  },
  // 컴포넌트 modal-user-permission-edit  <<>> --
  modalUserPermissonEdit: {
    title: 'Modify Permission',
    permission: 'Permission',
    close: 'Close',
    edit: 'Edit',
  },
  // 컴포넌트 algorithm-parameter-create-form  <<>> --
  algorithmParameterCreateForm: {
    totalCnt: 'Total {count}',
    name: 'Parameter Name',
    key: 'Parameter Key',
    desc: 'Description',
    addParams: 'Please Add a Parameter',
    add: 'Add',
  },
  // 컴포넌트 modal-change-company-name  <<>> --
  modalChangeCompanyName: {
    title: 'Change Company Name',
    name: 'Company Name',
    inputName: 'Please Enter a Company Name',
    cancel: 'Cancel',
    edit: 'Edit',
  },
  // 컴포넌트 modal-company-select  <<>> --
  modalCompanySelect: {
    filter: {
      name: 'Company Name',
      condition: 'Filter',
    },
    done: 'Selected',
    title: 'Select Company',
    fields: {
      name: 'Company Name',
      projectCnt: 'Number of Projects',
      linceCnt: 'Number of Lines',
      managerCnt: 'Number of Administrators',
      userCnt: 'Number of Participants',
      createdAt: 'Registered Date',
    },
  },
  // 컴포넌트 modal-default-project-setting <<>> --
  modalDefaultProjectSetting: {
    title: 'Default Project Setting',
    sub: 'Go to Specified Project When Logging in',
    admin: 'Administrator',
    user: 'Participants',
    permission: 'Permission',
    noProject: 'There are No Projects.',
    close: 'Close',
    done: 'Selected',
  },
  // 컴포넌트 modal-change-user-name <<>> --
  modalChangeUserName: {
    title: 'Modify User Name',
    name: 'Name',
    inputName: 'Please Enter a User Name',
    cancel: 'Cancel',
    edit: 'Edit',
  },
  // 컴포넌트 modal-learning-compare-add <<>> --
  modalLearningCompareAdd: {
    selectedModel: 'Select Target Model',
    chooseModel: 'Please Select a Target Model',
    classInfo: 'Class Information',
    inferParams: 'Inference Parameter Detailed Information',
    memo: 'Description',
    infer: 'Start Inference',
    addModel: 'Add Target Model',
    fm: {
      incorrectClass: 'Class Information (order, name, Ok or not) does not match.',
      chooseModel: 'Please Select a Target Model.',
      addModel: 'A Model has been added.',
    },
  },
  // 컴포넌트 train-class-info <<>> --
  trainClassInfo: {
    inputClass: 'Enter Class Name',
    addClass: 'Add Class',
    maxClass: 'Up to 34 Classes can be set.',
    existedClass: 'Class Name Already Exists.',
    removeClass: 'Remove Class',
    remove: 'Remove',
    removeClassMsg1: 'If You delete this class, All Class Labeling Work will be Deleted.',
    removeClassMsg2: 'Do you want to remove this class?',
  },
  // 컴포넌트 workspace-folder-display <<>> --
  workspaceFolderDisplay: {
    unclassified: 'Unclassified',
    total: 'Total',
  },
  // 컴포넌트 annotation-cf-image-card <<>> --
  annotationCfImageCard: {
    aug: 'Augmentation',
  },
  // 컴포넌트 canvas-classificate-workspace --
  canvasClassificateWorkspace: {
    hide: 'Hide Basic Information.',
    show: 'Show Basic Information.',
    selectedImageCnt: '{count} Selected Images',
    total: 'Select Entire Images.',
    fileName: 'File Name',
    unclassified: 'Unclassified',
    removeImage: 'Remove Image',
    copyFileName: 'Copy File Name',
    noImageMsg: 'There are No Images',
    imageArr: 'Image Array',
    firstRow: 'Row 1',
    secondRow: 'Row 2',
    thirdRow: 'Row 3',
    fourthRow: 'Row 4',
    classification: 'Classify Class',
    add: 'Add',
    total: 'Total',
    inputClass: 'Please Enter a Class Name',
    inspectStatus: 'Verification Status',
    changeHistory: 'Change History',
    noHistory: 'There is No Change History.',
    editHistory: 'Modification Status',
    checkingImage: 'Checking Images',
    savingData: 'Saving Data',
    loadingData: 'Loading Data',
    remove: 'remove',
    // 추가
    confirmSelectedRemove: 'Do you want to delete selected images?',
    images: '{count} Images',
    //
    confirmRemove: 'Do you want to delete the {count} selected images?',
    checkImage: 'View Image',
    fields: {
      name: 'Class Name',
      predictions: 'Prediction Result',
      inspection: 'Verification Result',
    },
    imageFields: {
      name: 'File Name',
      predictions: 'Prediction Result',
      inspection: 'Verification Result',
    },
    fm: {
      moreClass: 'Please Add two or more Class Information.',
      addClass: 'Please Add Class Information.',
      checkedOk: 'At least One Class that has been Checked as OK is required.',
      uncheckedOk: 'At least One Class that has been Unchecked as OK is required.',
      removeWarning: 'You cannot delete images During Performance Verification.',
      categoryMinImage: 'At least One Image is necessary for each Class.',
    },
  },
  //컴포넌트 modal-class-edit <<>> --
  modalClassEdit: {
    title: 'Edit Class Information',
    close: 'Close',
    edit: 'Edit',
    duplicateWarning: 'Duplicated Class is Unavailable.',
  },
  // 컴포넌트 modal-relearning-select <<>> --
  modalRelearningSelect: {
    modelName: 'Model Name',
    condition: 'Filter',
    selectDone: 'Selected',
    title: 'Select Training Model',
    fields: {
      type: 'Work Type',
      modelName: 'Model Name',
      algorithms: 'Algorithm',
      createdAt: 'Date',
    },
    algorithm: 'Algorithm',
  },
  // 컴포넌트 modal-confusion-matrix-image <<>> --
  modalConfusionMatrixImage: {
    origin: 'Original Image',
    result: 'Result Image',
    list: 'Image List',
    totalCnt: 'Total {count}',
    checkImage: 'View Image',
    noImage: 'There are No Images to show in this case.',
    close: 'Close',
    fields: {
      fileName: 'File Name',
    },
  },
  // 컴포넌트 modal-compare-image <<>> --
  modalCompareImage: {
    checkImage: 'View Image',
    fileName: 'File Name',
    origin: 'Original Image',
    result: 'Result Image',
    close: 'Close',
  },
  // 컴포넌트 train-class-learning-info <<>> --
  trainClassLearningInfo: {
    ratio: 'Sample Ratio',
  },
  // 컴포넌트 markup-result-none  <<>> --
  markupResultNone: {
    title: 'There are No Data.',
  },
  // 컴포넌트 annotation-objectdetection <<>> --
  annotationObjectdetection: {
    full: 'View All',
    wide: 'View Workspace Larger',
    fold: 'Fold Workspace',
    unfold: 'Expand Workspace',
    zoomIn: '[Ctrl] + Mouse Wheel, Num Keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + Mouse Wheel, Num Keypad [-], [Ctrl] + [-]',
    fixZoom: 'Fixed Scale',
    remove: 'Remove',
    resultImage: 'Prediction Result Image',
    enlarge: 'Zoom In',
    classInfo: 'Class Information',
    add: 'Add',
    merge: 'Merge',
    className: 'Class Name',
    inputClass: 'Enter a Class Name',
    changeClass: 'Modify Class Name',
    dataList: 'Data List',
    fileName: 'File Name',
    search: 'Search',
    unconfirmed: 'Unconfirmed Data',
    modiStatus: 'Modification Status',
    noImage: 'There are No Images.',
    removeImage: 'Remove Image',
    removeImageMsg1: 'Do you want to remove selected {image} images?',
    removeImageMsg2: 'Deleted Images cannot be Recovered.',
    removeImageMsg3: 'Do you want to remove {count} Selected Images?',
    filter: {
      all: 'All',
      edited: 'Edit',
      unconfirmed: 'Unconfirmed',
    },
    categoryFields: {
      name: 'Class Name',
    },
    imageFields: {
      imageName: 'File Name',
    },
    fm: {
      limitClass: 'Up to 34 classes can be set.',
      duplicateClass: 'Duplicated Class Name already exists.',
      chooseRemoveImage: 'Please Select Image to Remove.',
      removeWarning: 'You cannot delete images During Performance Verification.',
      imageWarning: 'Cannot load images.',
      // 추가
      selectClass: 'Please Select A Class.',
    },
    // 추가
    moreData: 'Load More Data',
    npyLoadError: 'The npy file was not found.',
    onnxLoadError: 'The onnx file was not found.',
  },
  // 컴포넌트 annotation-Segmentation <<>> --
  annotationSegmentation: {
    full: 'View All',
    wide: 'View Workspace Larger',
    fold: 'Fold Workspace',
    unfold: 'Expand Workspace',
    zoomIn: '[Ctrl] + Mouse Wheel, Num Keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + Mouse Wheel, Num Keypad [-], [Ctrl] + [-]',
    fixZoom: 'Fixed Scale',
    remove: 'Remove',
    resultImage: 'Prediction Result Image',
    enlarge: 'Zoom In',
    classInfo: 'Class Information',
    add: 'Add',
    merge: 'Merge',
    className: 'Class Name',
    inputClass: 'Enter a Class Name',
    changeClass: 'Modify Class Name',
    dataList: 'Data List',
    fileName: 'File Name',
    search: 'Search',
    unconfirmed: 'Unconfirmed Data',
    modiStatus: 'Modification Status',
    noImage: 'There are No Images.',
    removeImage: 'Remove Image',
    removeImageMsg1: 'Do you want to remove selected {image} images?',
    removeImageMsg2: 'Deleted Images cannot be Recovered.',
    removeImageMsg3: 'Do you want to remove {count} Selected Images?',
    filter: {
      all: 'All',
      edited: 'Edit',
      unconfirmed: 'Unconfirmed',
    },
    categoryFields: {
      name: 'Class Name',
    },
    imageFields: {
      imageName: 'File Name',
    },
    fm: {
      limitClass: 'Up to 34 classes can be set.',
      duplicateClass: 'Duplicated Class Name already exists.',
      chooseRemoveImage: 'Please Select Image to Remove.',
      removeWarning: 'You cannot delete images During Performance Verification.',
      imageWarning: 'Cannot load images.',
      // 추가
      selectClass: 'Please Select A Class.',
    },
    // 추가
    moreData: 'Load More Data',
  },
  // 컴포넌트 modal-fabric-canvas-zoom <<>> --
  moadlFabricCanvasZoom: {
    title: 'View Image',
    fileName: 'File Name',
    zoomIn: '[Ctrl] + Mouse Wheel, Num Keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + Mouse Wheel, Num Keypad [-], [Ctrl] + [-]',
    close: 'close',
  },
  // 컴포넌트 markup-load-labeling <<>> --
  markupLoadLabeling: {
    loading: 'Loading',
  },
  // 컴포넌트 annotaion-classification <<>> --
  annotationClassification: {
    close: 'Hide Basic Information',
    show: 'Show Basic Information',
    selectedImageCnt: '{count} Selected Images',
    totalImageCnt: 'Select Entire Images.',
    fileName: 'File Name',
    unclassified: 'Unclassified',
    removeImage: 'Remove Image',
    duplicateFileName: 'Copy File Name',
    noImage: 'There are No Images.',
    onlyAugData: 'View Only Augmented Data.',
    imageArr: 'Image Array',
    rowNumb: 'Row {number}',
    firstRow: 'Row 1',
    secRow: 'Row 2',
    thrdRow: 'Row 3',
    fthRow: 'Row 4',
    imageInfoVisible: 'Image Information Display',
    className: 'Class Name',
    isAugmentation: 'Augmentation',
    isEdit: 'Edit',
    classScore: 'Class Score',
    classification: 'Classify Class',
    add: 'Add',
    merge: 'Merge',
    remove: 'Remove',
    all: 'Total',
    totalCnt: '{count}',
    inputClass: 'Please Enter a Class Name.',
    inspectStatus: 'Verification Status',
    changeHistory: 'Change History ',
    noHistory: 'There is No Change History.',
    editHistory: 'Modification Status',
    removeImage: 'Remove Image',
    remove: 'Remove',
    confirmMsg1: 'Do you want to remove {count} Selected Images?',
    confirmMsg2: 'Deleted Images cannot be Recovered.',
    checkImage: 'View Image',
    fields: {
      className: 'Class Name',
      judgement: 'Prediction Result',
      inspection: 'Verification Result',
    },
    modifiedFields: {
      fileName: 'File Name',
      judgement: 'Prediction Result',
      inspection: 'Verification Result',
    },
    fm: {
      classLimitMsg: 'Up to 34 Classes can be Set.',
      duplicateClass: 'Duplicated Class Name already Exists.',
      removeWarning: 'You cannot delete images During Performance Verification.',
    },
    // 추가
    confirmSelected: 'Do you wish to delete the selected images?',
    moreData: 'Load More Data',
    selectedTh: 'Selected Threshold',
  },
  // 컴포넌트 annotation-basic-info  <<>> --
  annotationBasicInfo: {
    title: 'Work Information',
    inspectionTitle: 'Inspection Name',
    workType: 'Work Type',
    check: 'View',
    modelAlgorithms: 'Model / Algorithm',
    inspectedAt: 'Inspection Date',
    duration: 'Inspection Time',
    detail: 'Detailed Setting',
    checkDetail: 'View Detailed Setting',
    workName: 'Work Name',
    inputData: 'Usage Data Directory ',
    outputData: 'Result Data Directory ',
    createdAt: 'Created Date',
    updatedAt: 'Modified Date',
    preModel: 'Pre-trained Model',
    algorithmName: 'Algorithm Name',
    detailDesc: 'Detailed Setting Description',
    inputWorkName: 'Original Work Name',
    classParamsInfo: 'Augmentation Parameter Information By Class',
    mergeTarget: 'Merge Target',
    fm: {
      changeDesc: 'Description has been Changed.',
    },
  },
  // 컴포넌트 annotation-cf-guide  <<>>  --
  annotationCfGuide: {
    guide: 'Convenient User Guide',
    all: 'Select All ',
    ctrlA: 'Hold Down Ctrl + A',
    multiple: 'Select Multiple ',
    ctrlLeft: 'Hold Down Ctrl + Left Click.',
    scope: 'Select Scope',
    shftLeft: 'Hold Down Shift + Left Click',
    labeling: 'Classify Labeling',
    rightInput: 'Right-Click and Enter the Number of Folder',
    removeImage: 'Remove Image',
    rightRemove: 'Right-Click and Push Delete or Backspace.',
    undo: 'Undo',
    ctrlZ: 'Hold Down Ctrl + Z',
    cmmA: 'Hold Down Command + A',
    cmmLeft: 'Hold Down Coommand + Left Click',
  },
  // 컴포넌트 workspace-folder-display : 미분류, 전체

  // 컴포넌트 modal-processing-external-labeling
  modalExternalLabelingUpload: {
    cancel: 'Cancel',
    type: 'External Labeling Type',
    algorithmType: 'Algorithm Type',
    workName: 'Work Name',
    uploadDataset: 'Upload Original Images',
    existingDataset: 'Use Existing Dataset',
    uploadBbox: 'Upload Bbox Information',
    selectFile: 'Select File',
    notDuplicated: 'The work name is available.',
    duplicated: 'The work name is already registered.',
    inputWorkName: 'Please enter the work name.',
    inputDatasetName: 'Please enter the dataset name.',
    datasetDuplicated: 'Dataset name already exists.',
    bboxNameDuplicated: 'Duplicate bbox filenames exist.',
    imageDatasetValidate: "Only '.png' and '.jpg' extensions are allowed for the image dataset.",
    mismatchedName: 'The Bbox file name does not match the original image file name.',
    extensionValidate: 'The extension of the bbox file differs from the external labeling type.',
    classFileValidate: 'Only .txt files can be uploaded.',
    success: 'External labeling task has been created.',
    hover: {
      explain: 'Use datasets uploaded in the data management tab.',
    },
    placeholder: {
      workName: 'Enter work name.',
      datasetName: 'Enter dataset name.',
      imageDatasetUpload: 'Upload image dataset.',
      imageValidateFile:
        'If there are Korean characters or special characters (except _ ) in the file name, they will be omitted during upload.',
      bboxValidateFile:
        'If there is a bbox file name that does not match the original image file name, upload will be impossible.',
      classUpload: 'Upload the Class name information txt file.',
      BboxUpload: 'Upload the Bbox information file.',
    },
  },
  // 컴포넌트 modal-processing-class-merge <<>> --
  modalProcessingClassMerge: {
    inputMerge: 'Merge Class Input',
    resultName: 'Merge Result Class Name',
    inputResult: 'Please Enter Merge Result Class Name',
    add: 'Add',
    selectedMerge: 'Select Class to Merge (select {count})',
    cancel: 'Cancel',
    merge: 'Merge',
    fm: {
      noClass: 'There is No Class to Merge.',
      inputMergeClass: 'Please Enter the Class Name to be added to the Class to be Merged.',
      duplicateClass: 'This Class has already been added to the class to be merged.',
      notExistedClass: 'This Class is not registered.',
      inputMerge: 'Please Enter Name of the Class to Merge.',
      chooseMoreClass: 'Please Select two or more Classes to Merge.',
      noDuplicatedClass: 'Duplicated Category Name cannot be Registered.',
    },
  },
  // 컴포넌트 modal-processing-class-remove <<>> --
  modalProcessingClassRemove: {
    removeClass: 'Remove Class',
    remove: 'Remove',
    confirmMsg: 'Do you want to remove the selected class?',
    desc1: '(Files of Celeted Classes are moved to Unclassified.)',
    desc2: 'All Bboxes of the Deleted Classes are deleted.',
    fm: {
      choose: 'Please Select a Class to Remove.',
    },
  },
  // 컴포넌트 modal-setting-detail <<>> --
  modalSettingDetail: {
    detail: 'Detailed Setting Information',
    trainParams: 'Train Parameter',
    inferParams: 'Inferecne Parameter',
    aug: 'Augmentation Setting',
    close: 'Close',
  },
  // 컴포넌트 modal-processing-augmentation-setting-detail  <<>> --
  modalProcessingAugmentationSettingDetail: {
    detail: 'Cetailed Setting Information',
    close: 'Close',
  },
  // 컴포넌트 algorithm-parameter-input-form <<>> --
  algorithmParameterInputForm: {
    desc1: 'When Using Target Region, Width and Height Sizes of All Images in the dataset must be the Same.',
    desc2: 'If Not, Input Error Occurs.',
    fmWarning: 'There is No Description for {item}.',
  },
  // 컴포넌트 annotation-target-region <<>>
  annotationTargetRegion: {
    full: 'View All',
    wide: 'View Workspace Larger',
    fold: 'Fold Workspace',
    unfold: 'Expand Workspace',
    zoomIn: '[Ctrl] + Mouse Wheel, Num Keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + Mouse Wheel, Num Keypad [-], [Ctrl] + [-]',
    fixZoom: 'Fixed Ratio',
    classInfo: 'Class Information',
    regionInfo: 'Region Information',
    dataList: 'Data List',
    regionFields: {
      position: 'Coordinate Information',
    },
    imageFields: {
      imageName: 'File Name',
    },
    fm: {
      imageWarning: 'Unable to Load Images.',
      targetWarning: 'Up to 10 Target Region can be added.',
      // 추가
      selectClass: 'Please Select a Class.',
      duplicatedClass: 'Duplicated Class Name already exists.',
    },
    // 추가
    add: 'Add',
    delete: 'Remove',
    className: 'Class Name',
    inputClass: 'Please enter a Class Name',
    changeName: 'Modify Class Name',
    deleteClass: 'Remove Class',
    classMsg: 'Do you want to remove the selected class?',
    classMsg2: 'Patch and Bbox of deleted class will be deleted.',
  },
  // 컴포넌트 annotation-od-current-info-display <<>> --
  annotationCurrentInfoDisplay: {
    close: 'Close Basic Information',
    show: 'Show Basic Information ',
    size: 'Size',
    selectedClass: 'Class Being Selected',
    selectedBbox: 'Bbox Being Selected',
  },
  // 컴포넌트 modal-dataset-select <<>> --
  modalDatasetSelect: {
    imageCnt: '{num} Images',
    updatedAt: 'Latest Modified Date',
    cancel: 'Cancel',
    select: 'Select',
    title: 'Select Dataset',
    noData: 'There is No Data.',
  },
  // 컴포넌트 modal-processing-datas <<>> --
  modalProcessingData: {
    title: 'Select Data',
    checkCond: 'Filter',
    type: 'Type',
    usage: 'Purpose',
    cancel: 'Cancel',
    done: 'Done',
    fields: {
      title: 'Work Name',
      processType: 'Preprocess Type',
      workType: 'Work Type',
      usageType: 'Purpose',
      createdAt: 'Created At',
    },
  },
  // 컴포넌트 modal-learning-select <<>> --
  modalLearningSelect: {
    modelName: 'Model Name',
    checkCond: 'Filter',
    type: 'Type',
    done: 'Selected',
    title: 'Select Model',
    algorithms: 'Algorithm',
    fields: {
      createdAt: 'Created At',
      learningEndTime: 'Training End Date',
      name: 'Model Name',
      algorithmTitle: 'Algorithm',
    },
  },
  // 컴포넌트 modal-processing-sample-image <<>> --
  modalProcessingSampleImages: {
    title: 'Sample Image',
    close: 'Close',
  },
  // 컴포넌트 modal-processing-control <<>> --
  modalProcessingControl: {
    desc1: 'Selected Dataset has been used in Training or Testing.',
    desc2: 'If you want to Edit, Select "Copy Labeling".',
    desc3: 'Or If you want to View, Select "View Labeling".',
    desc4: 'Selected Dataset has been saved already.',
    copyLabeling: 'Copy Labeling',
    showLabeling: 'View Labeling',
  },
  // 컴포넌트 modal-processing-copy <<>> --
  modalProcessingCopy: {
    title: 'Copy Labeling',
    workName: 'Work Name',
    folderName: 'Save Directory',
    projectName: 'Save Project',
    chooseProject: 'Select Project.',
    inputFolderName: 'Please Enter Folder Name',
    inputWorkName: 'Please Enter Work Name',
    inputProject: 'Select a Project.',
    copyAnotherProject: 'Copy to another project',
    sameWorkName: 'Same as Work Name',
    copyError: 'An error occurred while copying image data.',
    desc1: 'If you Copy Labeling, Added/Deleted Data in original directory will be synced.',
    desc2: "Newly Added Data will be labelled as 'Unclassified'.",
    confirmCopy: 'Do you want to Continue Copying?',
    cancel: 'Cancel',
    copy: 'Copy',
    copyDone: 'Copying has started.',
    copyFail: 'Failed to create a copy operation.',
    overWriteDataset: 'Confirm Dataset',
    duplicateDataset: 'There are duplicate datasets.  ({currentIndex}/{totalCount})',
    overWrite: 'OverWrite',
    skip: 'Skip',
    allOverWrite: 'All OverWrite',
    allSkip: 'All Skip',
  },
  // 컴포넌트 modal-processing-merge <<>> --
  modalProcessingMerge: {
    title: 'Merge Labeling',
    workName: 'Work Name',
    resultFolderName: 'Save Directory',
    sameWorkName: 'Save as Sork Name',
    folderName: 'Folder Name',
    textarea: 'Description',
    selectedData: 'Selected Data',
    chooseMerge: 'Select Data to Merge',
    checkCond: 'Filter',
    type: 'Type',
    cancel: 'Cancel',
    runLabeling: 'Merge Labeling',
    fields: {
      title: 'Work Name',
      processTypeId: 'Preprocess Type',
      usageTypeId: 'Purpose',
      createdAt: 'Created At',
    },
    workType: 'Work Type',
    nameValidator: {
      title: 'Save Directory',
      msg: "Enter 0~9, lower/upper-case alphabet, '_' or '-'.",
    },
    fm: {
      startMerge: 'Merging Labeling',
      mergeFail: 'Failed to create a merge operation.',
      chooseName: 'Please Enter Work Name.',
      chooseMerge: 'Please Select more than 2 Dataset to Merge',
      inputResultName: 'Please Enter Save Directory.',
    },
    duplicateClassNameMsg: 'If the class name is duplicated, it will be overwritten with information from the top data',
  },
  // 컴포넌트 modal-description <<>> --
  modal: {
    close: 'Close',
    edit: 'Edit',
  },
  // 컴포넌트 modal-dataset-folder-rename <<>> --
  modalDatasetFolderRaname: {
    title: 'Rename Folder',
    folderName: 'Folder Name',
    placeholder: 'Please Enter Folder Name',
    rename: 'Rename',
    fm: {
      duplicate: 'Duplicate Folder Name Found. Please Enter a Different Folder Name',
      rename: 'Please Enter a New Folder Name.',
    },
  },
  // 컴포넌트 modal-dataset-download-request-list  <<>> --
  modalDatasetDownloadRequestList: {
    title: 'Download Request List',
    cancelAlert: 'Closing the window does not cancel the download request.',
    autoSearch: 'Auto Search',
    removeAfter: 'Delete History after {date}',
    requesting: 'Requesting',
    root: 'Directory',
    fileCount: 'Number of Files',
    resultNone: 'There are No Download Requests.',
    close: 'Close',
    // 추가
    dataset: 'Dataset',
    result: 'Labeling Result',
    // 추가
    workName: 'Work Name',
  },
  // 컴포넌트 modal-image-zoom <<>> --
  modalImageZoom: {
    fileName: 'File Name',
    zoomIn: '[Ctrl] + Mouse Wheel, Num Keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + Mouse Wheel, Num Keypad [-], [Ctrl] + [-]',
    close: 'Close',
    checkImage: 'View Image',
    bboxList: 'Bbox List',
  },
  // layouts-admin
  // - layout-global-navigation-bar  <<>> --
  layoutGlobalNavigationBar: {
    uTitle: 'U+ AI Vision Inspection',
    user: '{username}',
    intro: 'MAVIN-Cloud',
    alert: 'Notification',
    noAlert: 'No Notification',
    allAlert: 'See All Notification',
    my: 'My Page',
    notice: 'Notice',
    download: 'Download',
    status: 'Usage Status',
    center: 'Customer Service',
    login: 'Login',
    logout: 'Logout',
    logoutMsg: 'Do you want to logout?',
    shortName: {
      labeling: 'Preprocessing',
      modeling: 'Model',
      compare: 'Comparison',
      training: 'Training',
      infernece: 'Performance',
    },
    longName: {
      labeling: 'Data Preprocessing',
      modeling: 'Add Model Comparison',
      compare: 'Performance Comparison Test',
      training: 'Training',
      infernece: 'Performance Test',
    },
    // noticication: {
    done: '> Work Completed.',
    process: '> Work Processed.',
    // },
    hour: 'hour(s)',
    min: 'minute(s)',
    sec: 'second(s)',
    take: 'spent',
    inputProject: 'Please enter project name.',
  },
  // - layout-admin-side-navigation-bar  <<>> --
  layoutAdminSideNavigationBar: {
    admin: 'Dashboard',
    company: 'Manage Company',
    project: 'Manage Projects',
    user: 'Manage Users',
    algorithm: 'Manage Algorithms',
    notice: 'Manage Notice',
  },
  // layouts-default
  // - layout-side-navigation-bar  <<>> --
  layoutSideNavigationBar: {
    menu: {
      my: 'My Info',
      changePw: 'Change Password',
      projects: 'Manage Projects',
      usageProjects: 'Usage by project',
      user: 'Manage Users',
      notifications: 'Notification History',
    },
  },
  // - layout-copyright  <<>> --
  layoutCopyright: {
    title: 'LGE PRI mfg. AI Solution Team',
    copyright: 'Copyright © 2022 MAVIN Cloud All rights reserved',
    privacy: 'Privacy Notice',
  },
  // layouts-fluid
  // - cs-iframe  <<>> --
  csIframe: {
    center: 'Customer Service',
  },
  // layouts-monitoring
  // privacyPolicy 개인정보처리방침 --
  privacyPolicy: {
    title: 'LG전자 개인정보 처리방침',
    subTitle:
      'LG전자는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」  및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.',
    article1: {
      title: '제1조(개인정보의 처리 목적)',
      sub1: 'LG전자는 MAVIN-Cloud 서비스 홈페이지(',
      sub2: ') 회원제 운영에 있어 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.',
      part1: '1. 홈페이지 회원가입 및 관리',
      desc1:
        '회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지 목적으로 개인정보를 처리합니다.',
      part2: '2. 민원사무 처리',
      desc2:
        '민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.',
      part3: '3. 재화 또는 서비스 제공',
      desc3: '서비스 제공, 계약서·청구서 발송, 요금결제·정산을 목적으로 개인정보를 처리합니다.',
      part4: '4. 서비스 이용 통계',
      desc4: '접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.',
    },
    article2: {
      title: '제2조(개인정보의 처리 및 보유 기간)',
      sub1: 'LG전자는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리· 보유합니다.',
      sub2: 'LG전자는 MAVIN-Cloud서비스 이용계약에서 정한 계약기간이 만료된 후 3개월간 개인정보를 보유합니다. 단, 관계법령에 의해 보존할 경우 그 의무기간 동안 별도 보관합니다.',
    },
    article3: {
      title: '제3조(처리하는 개인정보의 항목)',
      sub1: 'LG전자는 이름, 로그인ID, 비밀번호, 이메일, 회사명, 부서, 접속로그, 서비스 이용기록을 처리합니다.',
    },
    article4: {
      title: '제4조(개인정보의 파기절차 및 파기방법)',
      sub1: 'LG전자는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.',
      sub2: '정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.',
      sub3: '개인정보 파기의 절차 및 방법은 다음과 같습니다.',
      part1: '1. 파기절차',
      desc1:
        ' LG전자는 파기 사유가 발생한 개인정보를 선정하고, LG전자의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.',
      part2: '2. 파기방법',
      desc2:
        'LG전자는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄 하거나 소각하여 파기합니다.',
    },
    article5: {
      title: '제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)',
      sub1: '정보주체는 LG전자에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.',
      sub2: '제1항에 따른 권리 행사는LG전자에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 LG전자는 이에 대해 지체 없이 조치하겠습니다.',
      sub3: '제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.',
      sub4: '개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.',
      sub5: '개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.',
      sub6: 'LG전자는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.',
    },
    article6: {
      title: '제 6조(개인정보의 위탁에 관한 사항)',
      sub1: 'LG전자는 서비스 이행을 위해 개인정보 처리업무를 아래와 같이 외부 전문 업체에 위탁하여 운영하고 있습니다. 위탁 계약 시 개인정보가 안전하게 보호될 수 있도록 필요한 서약을 규정해 관련 법규를 준수하고 이를 감독하고 있습니다. 위탁하는 업무의 변경이 발생할 경우 웹사이트의 공지사항을 통해 안내 드리겠습니다.',
      part1: '위탁업체',
      desc1: '위탁업무 내용',
      part2: '㈜클라우드메이트',
      desc2: '서비스 및 인프라 운영관리, 서비스 인프라 개발 및 고도화, 서비스 모니터링',
    },
    article7: {
      title: '제7조(개인정보의 안전성 확보조치에 관한 사항)',
      sub1: 'LG전자는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.',
      part1: '1. 내부관리계획의 수립 및 시행',
      desc1: '개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.',
      part2: '2. 개인정보 취급 직원의 최소화 및 교육',
      desc2:
        '개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.',
      part3: '3. 정기적인 자체 감사 실시',
      desc3: '개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.',
      part4: '4. 개인정보에 대한 접근 제한',
      desc4:
        '개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.',
      part5: '5. 접속기록의 보관 및 위변조 방지',
      desc5:
        '개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다. ',
      part6: '6. 개인정보의 암호화',
      desc6:
        '이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.',
      part7: '7. 해킹 등에 대비한 기술적 대책',
      desc7:
        'LG전자는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.',
      part8: '8. 비인가자에 대한 출입 통제',
      desc8:
        '개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.',
      part9: ' 9. 문서보안을 위한 잠금장치 사용',
      desc9: '개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.',
    },
    article8: {
      title: '제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항',
      sub1: 'LG전자는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.',
    },
    article9: {
      title: '제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)',
      sub1: 'LG전자는 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.',
    },
    article10: {
      title: '제10조 (개인정보 보호책임자에 관한 사항)',
      sub1: 'LG전자는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.',
      part1: '	개인정보 보호책임자 : LG전자 정보보호담당',
      part2: '	개인정보 운영책임자 : PRI 제조AI솔루션팀장',
      part3: '	전화 번호 :  031-8054-2132',
      part4: '	이메일 주소 : priaiplatform.cs@lge.com',
      sub2: '정보주체께서는 LG전자의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다. LG전자는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.',
    },
    article11: {
      title: '제11조(개인정보의 열람청구를 접수·처리하는 부서)',
      sub1: '정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. LG전자는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.',
      part1: '▶ 개인정보 열람청구 접수·처리 부서',
      part2: '•	부서명 : PRI 제조AI솔루션팀',
      part3: '•	연락처 : 031-8054-2132, priaiplatform.cs@lge.com',
    },
    article12: {
      title: '제12조(정보주체의 권익침해에 대한 구제방법)',
      sub1: '정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.',
      part1: '1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)',
      desc1: '2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)',
      part2: '3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)',
      desc2: '4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)',
      part3:
        'LG전자는 정보주체의 개인정보자기결정권을 보장하고, 개인정보 침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.  ',
      desc3: ' 개인정보보호 관련 고객 상담 및 신고 ',
      part4: '	부서명 : PRI 제조AI솔루션팀',
      desc4: '	연락처 : 031-8054-2132, priaiplatform.cs@lge.com',
      part5:
        '「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.',
      desc5: ' 중앙행정심판위원회 : (국번없이) 110(www.simpan.go.kr)    ',
    },
    article13: {
      title: '제13조(개인정보 처리방침 변경)',
      sub1: '이 개인정보처리방침은 2023년 5월 1부터 적용됩니다.',
      part1: '이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.',
      desc1: '- 해당 없음',
    },
  },
  // 추가요청
  // modal-password-alarm <<>> --
  modalPasswordAlarm: {
    delay: 'Change After 3 Months',
    now: 'Change Now',
  },
  // workspace-title  <<>> --
  workspaceTitle: {
    title: 'Line Inspection Name',
  },
  //모달 공통
  close: 'Close',
  confirm: 'Confirm',
  cancel: 'Cancel',
  // modal-dataset-upload --
  modalDatasetUpload: {
    title: 'Upload',
    purpose: 'Data Purpose',
    processing: 'Data Preprocessing',
    inference: 'Single Performance Evaluation',
    type: 'Dataset Type',
    new: 'New',
    existing: 'Existing Dataset',
    name: 'Dataset Name',
    inputName: 'Please enter Name.',
    chooseDataset: 'Please select a dataset.',
    selectDataset: 'Select Dataset',
    classification: 'Class Classification',
    checking: 'When the Dataset is for Classfication or Anomaly Detection',
    success: '{count} Uploaded',
    fail: '{count} Failed',
    loadingOld: 'Load Existing Dataset Classes',
    uploading: 'Upload File',
    fileCnt: '{count}/{max}',
    uploadFile: 'Please upload a file.',
    validateFile:
      'If the file name contains Korean or special characters (except _),  those characters will be omitted and uploaded.',
    selectClass: 'Select Class',
    addNewClass: 'This folder does not have any classes. Please add a new class',
    duplicateFoldername: 'Folder Name Duplicate',
    duplicateFoldernameMsg: 'There is a duplicate folder name. Do you wish to proceed uploading to the folder?',
    overLimit: 'Exceeded Maximum Number of Uploads',
    trialMsg: 'For Trial account, you can only upload up to 3000 images at a time.',
    limitMsg: 'You cannot upload more images because the maximum upload limit is {limit} images.',
    limitAlert: 'The current number of images you can upload: {count}.',
    cancel: 'Cancel',
    upload: 'Upload',
    fm: {
      chooseType: 'Please select a type.',
      duplicateClass: 'Duplicate class name exists.',
      inputDataset: 'Please enter a dataset name.',
      chooseDataset: 'Please select a dataset.',
      inputClass: 'Please enter a class name.',
      chooseFile: 'Please select a file to upload.',
      includeNew: 'Please select a file to upload to new classes.',
      // 추가
      invalid: '{name} cannot be used as a folder name.',
      classChecked: 'Only files with names containing English upper/lower case letters, -, _ can be selected.',
    },
  },
  // train-dataset-class-input  --
  trainDatasetClassInput: {
    selectFile: 'Select File',
    inputClass: 'Please enter a class name.',
    add: 'Add',
    validateFile:
      'If the file name contains Korean or special characters (except _),  those characters will be omitted and uploaded.',
  },
  // modal-processing-ai-labeling --
  modalProcessingAiLabeling: {
    title: 'Rerun AI Labeling One-Click Process',
    basicInfo: 'Rerun Info',
    workName: 'Work Name',
    resultFolderName: 'Labeling Result Storage Folder Name',
    sameWorkName: 'Same as the Work Name',
    folderName: 'Folder Name',
    manualLabeling: 'Rerun The Manual Labeling',
    manualDesc: 'You can redo the stage 1 - Manual Labeling by adding or deleting data',
    algorithmReset: 'Reset Algorithm Information',
    algorithmDesc: 'You can reset the information of the training model to be generated.',
    selectAlgorithm: 'Select the Algorithm',
    checkAlgoInfo: 'View Algorithm Description',
    detail: 'Detailed Setting',
    trainParams: 'Train Parameters',
    inferParams: 'Inference Parameters',
    augParams: 'Augmentation Setting',
    desc: 'Description',
    cancel: 'Cancel',
    run: 'Run AI Labeling',
    chooseAlgorithm: 'Please Select the Algorithm',
    fm: {
      selectData: 'Please select a data processing case for which you want to rerun the One-Click Process.',
      title: 'Labeling Result Storage Folder Name',
      success: 'Data merging has been started.',
      inputWorkName: 'Please enter the name of work.',
      inputFolderName: 'Please enter a name of labeling result storage folder.',
      invalidName: 'Folder Name is invalid. Please enter a different folder name.',
      duplicateFolderName: 'Folder Name already exists. Please enter a different Folder Name.',
      noAlgorithmImage: 'There are no algorithm description image.',
    },
  },
  // annotation-cf-ai-labeling-untrained-result --
  annotationCfAiLabelingUntrainedResult: {
    title: 'AI Labeling Work result',
    resultSample: 'Labeling Result Sample',
    scatter: 'Data Scatterplot',
    full: 'View All ',
    scatterFull: 'View All Data Scatterplot',
    scatterWide: 'Enlarge Data Scatterplot',
  },
  // input-password
  inputPassword: {
    label: 'Password',
    placeholder: 'Please enter password.',
    new: 'Create Password',
  },
  // annotation-od-ai-result-thumblist
  annotationOdAiResultThumb: {
    hidden: 'Close Collection View',
    show: 'Show Collection View',
    gather: 'Data Image Collection View',
    image: 'View Image',
  },
  // admin/statistics/train
  adminStatisticsTrain: {
    filter: 'Filter',
    total: 'Total Case',
    count: '{count} case',
    model: 'Train Model',
    progressLearnCnt: 'Learning Progress',
    progressInferCnt: 'Inference Progress',
    watingLearnCnt: 'Learning Waits',
    watingInferCnt: 'Inference Waits',
    learningList: 'Learning Progress List',
    learningWaiting: 'Learning Waiting List',
    inferenceList: 'Inference Progress List',
    inferenceWaiting: 'Inference Waiting List',
    training: 'Train',
    typeProcessing: 'Processing Type',
    typeWork: 'Work Type',
    workCnt: 'Number of Works',
    algorithm: 'Algorithm',
    trainingCnt: 'Number of Trainings',
    avgTime: 'Average Time Spent',
    inferCnt: 'Number of Evaluation Progresses',
    breadcrumb: {
      statistics: 'View Statistics',
      history: 'View Usage History',
      trend: 'View Monthly Trend',
    },
    targetOpt: {
      total: 'Total',
      pri: 'Only PRI',
    },
    usage: 'Usage Status',
    untrained: 'Unsupervised',
    trained: 'Supervised',
    single: 'Performance Evaluation',
    comparative: 'Comparative Performance Evaluation',
  },
  myStatistics: {
    service: 'System Administrator',
    history: 'View Usage History',
    trend: 'View Monthly Trend',
    dash: 'Dashboard',
    status: 'Resource Monitoring',
    autoRefresh: 'Auto Refresh',
    learningProgress: 'In Progress',
    learningWaiting: 'Waiting',
    resource: 'Resource Monitoring',
  },
  // resource-monitoring
  resourceMonitoring: {
    info: 'Resource Monitoring',
    breadcrumb: 'Resource Monitoring',
    autoRefresh: 'Auto Refresh',
    learningProgress: 'In Progress',
    learningWaiting: 'Waiting',
    count: '{count} case',
    progressLearnCnt: 'Learning Progress',
    progressInferCnt: 'Inference Progress',
    watingLearnCnt: 'Learning Waits',
    watingInferCnt: 'Inference Waits',
    learningList: 'Learning Progress List',
    learningWaitingList: 'Learning Waiting List',
    inferenceList: 'Inference Progress List',
    inferenceWaitingList: 'Inference Waiting List',
    fields: {
      startTime: 'Start Time',
      createdAt: 'Request Time',
      updateUserName: 'Requestor',
      workName: 'Work Name',
      progress: 'Progress',
      learnDuration: 'Progress Time',
      waitDuration: 'Waiting Time',
      algorithm: 'Algorithm',
      workTypeName: 'Work Type Name',
      projectName: 'Project Name',
    },
  },
  // annotation-od-ai-labeling-untrained-result
  annotationOdAiLabelingUntrainedResult: {
    result: 'Results of Extracting Key Points from the Standard Image',
    full: 'View All',
    wide: 'View Larger',
  },
  // modal-manual-dataset
  modalManualDataset: {
    title: 'Images for Manual Labeling',
    selectedImages: 'Selected {image}images',
    selectAll: 'Select Entire Images',
    unclassified: 'Unclassified',
    none: 'There are No Images.',
    cancel: 'Cancel',
    select: 'Add',
    noData: 'There are No Data.',
    fm: {
      selectImage: 'Please select images to add.',
    },
  },
  // annotation-cf-ai-labeling-result-sample-card
  annotationCfAiLabelingResultSampleCard: {
    image: 'View Image',
  },
  // annotation-board
  annotationBoard: {
    unfold: 'Expand Class/Data Area',
    hide: 'Hide Class/Data Area',
  },
  // annotation-data-display
  annotationDataDisplay: {
    hide: 'Close Basic Information',
    show: 'Show Basic Information',
    size: 'Size',
    selectedClass: 'Class Being Selected',
    currentData: 'Currently working data file name',
    wh: 'width x height',
  },
  // annotation-info-display
  annotationInfoDisplay: {
    class: 'Class Informataion',
    list: 'Data List',
  },
  // annotation-toolbar
  annotationToolbar: {
    select: 'Select',
    tool: 'Selected Tool',
    draw: 'Draw',
    dia: 'Shape Tool',
    selectBrush: 'Select Brush',
    brush: 'Brush',
    zoom: 'Zoom',
  },
  // canvas-fabric-workspace
  canvasFabricWorkspace: {
    deleteSelected: 'Delete Selected Content',
    zoomIn: '[Ctrl] + mouse wheel, num keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + mouse wheel, num keypad [-], [Ctrl] + [-]',
    ratio: 'Fatio',
    fix: 'Fixed',
    wide: 'View workspace larger',
    full: 'View all',
    resultImage: 'Prediction Result Image',
    enlarge: 'Enlarge',
    edit: 'Edit',
    unchecked: 'Unchecked Data',
    fileName: 'File Name',
    search: 'Search',
    removeSelected: 'Remove Selected',
    editStatus: 'Edit Status',
    checkingImage: 'Checking the image',
    savingData: 'Saving Data',
    loadingData: 'Loading Data',
    deleteImage: 'Delete Image',
    delete: 'Delete',
    deleteImageConfirm: 'Do you want to delete the image?',
    deleteImageConfirm2: 'Do you want to delete the selected {images} images?',
    categoryFields: {
      className: 'Class Name',
    },
    radioFilters: {
      all: 'Total',
      edited: 'Edited',
      incomplete: 'Unchecked',
    },
    anotFields: {
      fineName: 'File Name',
    },
    fm: {
      loadingError: 'Cannot Load images.',
      addClass: 'Please Add Class Information.',
      selectToDelete: 'Please Select Image to Remove.',
    },
  },
  // input-date-range-picker
  inputDateRangePicker: {
    select: 'Select View Period',
    pw: 'Password',
  },
  // markup-image-view
  markupImageView: {
    zoom: '[Ctrl] + Mouse Wheel',
  },
  // markup-prediction-view
  markupPredictionView: {
    zoomIn: '[Ctrl] + mouse wheel, num keypad [+], [Ctrl] + [+]',
    zoomOut: '[Ctrl] + mouse wheel, num keypad [-], [Ctrl] + [-]',
  },
  // markup-simple-company-view, markup-simple-datas-view
  markupSimpleCompanyView: {
    total: '{name} except {rest}',
    items: '',
  },
  // modal-password-reset
  modalPasswordReset: {
    title: 'Did you forget your password?',
    desc: 'You can change your password by requesting password reset via the above email.',
    close: 'Close',
    fm: {
      inputId: 'Please enter an ID.',
      existed: 'The user has already requested a password reset. Please contact your administrator.',
    },
  },
  // notices
  notices: {
    title: 'Notice',
    all: 'Notice for All',
    company: 'Notice for Each Company',
    department: 'Notice for Each Organization',
    fields: {
      title: 'Title',
      updatedAt: 'Updated Date',
    },
    attached: 'Attachment',
    list: 'To List',
    download: 'Download',
    downloadMc1: 'Download MAVIN 1.0',
    downloadMc2: 'Download MAVIN 2.0',
    support: 'support',
    support2Yolo: 'YOLO support for MAVIN 2.0',
    manual: 'Manual',
    support2Yolo8Env: 'YOLOv8 support',
  },
  // use-history
  useHistory: {
    breadcrumb: 'Use History',
    title: 'Use History',
    history: 'Usage Status',
    trend: 'Monthly Trend',
    processType: 'Process Type',
    workType: 'Work Type',
    newLabeling: 'New Labeling',
    mergeLabeling: 'Merge Labeling',
    aiLabelingTrained: 'AI Labeling\nTrained',
    aiLabelingUntrained: 'AI Labeling\nUntrained',
    augmentedLabeling: 'Augmented Labeling',
    externalLabeling: 'External Labeling',
    count: 'items',
  },
  // result-class-info-tooltip
  resultClassInfo: {
    className: 'Class Name',
    imageName: 'Image Name',
    outlier: 'Outlier',
    median: 'Median',
    noData: 'There is No Data.',
  },
};
