import { fabric } from 'fabric';
import { Tensor } from 'onnxruntime-web';
import { onnxMaskToImage, traceOnnxMaskToSVG } from '../utils/maskUtils';
import { throttle } from 'lodash';
import Vue from 'vue';
fabric.filterBackend = fabric.initFilterBackend();

(function () {
  /** ERASER_START */
  var __setBgOverlayColor = fabric.StaticCanvas.prototype.__setBgOverlayColor;
  var ___setBgOverlay = fabric.StaticCanvas.prototype.__setBgOverlay;
  var __setSVGBgOverlayColor = fabric.StaticCanvas.prototype._setSVGBgOverlayColor;
  fabric.util.object.extend(fabric.StaticCanvas.prototype, {
    backgroundColor: undefined,
    overlayColor: undefined,
    /**
     * Create Rect that holds the color to support erasing
     * patches {@link CommonMethods#_initGradient}
     * @private
     * @param {'bakground'|'overlay'} property
     * @param {(String|fabric.Pattern|fabric.Rect)} color Color or pattern or rect (in case of erasing)
     * @param {Function} callback Callback to invoke when color is set
     * @param {Object} options
     * @return {fabric.Canvas} instance
     * @chainable true
     */
    __setBgOverlayColor: function (property, color, callback, options) {
      if (color && color.isType && color.isType('rect')) {
        // color is already an object
        this[property] = color;
        color.set(options);
        callback && callback(this[property]);
      } else {
        var _this = this;
        var cb = function () {
          _this[property] = new fabric.Rect(
            fabric.util.object.extend(
              {
                width: _this.width,
                height: _this.height,
                fill: _this[property],
              },
              options,
            ),
          );
          callback && callback(_this[property]);
        };
        __setBgOverlayColor.call(this, property, color, cb);
        //  invoke cb in case of gradient
        //  see {@link CommonMethods#_initGradient}
        if (color && color.colorStops && !(color instanceof fabric.Gradient)) {
          cb();
        }
      }

      return this;
    },

    setBackgroundColor: function (backgroundColor, callback, options) {
      return this.__setBgOverlayColor('backgroundColor', backgroundColor, callback, options);
    },

    setOverlayColor: function (overlayColor, callback, options) {
      return this.__setBgOverlayColor('overlayColor', overlayColor, callback, options);
    },

    /**
     * patch serialization - from json
     * background/overlay properties could be objects if parsed by this mixin or could be legacy values
     * @private
     * @param {String} property Property to set (backgroundImage, overlayImage, backgroundColor, overlayColor)
     * @param {(Object|String)} value Value to set
     * @param {Object} loaded Set loaded property to true if property is set
     * @param {Object} callback Callback function to invoke after property is set
     */
    __setBgOverlay: function (property, value, loaded, callback) {
      var _this = this;

      if (
        (property === 'backgroundColor' || property === 'overlayColor') &&
        value &&
        typeof value === 'object' &&
        value.type === 'rect'
      ) {
        fabric.util.enlivenObjects([value], function (enlivedObject) {
          _this[property] = enlivedObject[0];
          loaded[property] = true;
          callback && callback();
        });
      } else {
        ___setBgOverlay.call(this, property, value, loaded, callback);
      }
    },

    /**
     * patch serialization - to svg
     * background/overlay properties could be objects if parsed by this mixin or could be legacy values
     * @private
     */
    _setSVGBgOverlayColor: function (markup, property, reviver) {
      var filler = this[property + 'Color'];
      if (filler && filler.isType && filler.isType('rect')) {
        var excludeFromExport = filler.excludeFromExport || (this[property] && this[property].excludeFromExport);
        if (filler && !excludeFromExport && filler.toSVG) {
          markup.push(filler.toSVG(reviver));
        }
      } else {
        __setSVGBgOverlayColor.call(this, markup, property, reviver);
      }
    },

    /**
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     * @param {string} property 'background' or 'overlay'
     */
    _renderBackgroundOrOverlay: function (ctx, property) {
      var fill = this[property + 'Color'],
        object = this[property + 'Image'],
        v = this.viewportTransform,
        needsVpt = this[property + 'Vpt'];
      if (!fill && !object) {
        return;
      }
      if (fill || object) {
        ctx.save();
        if (needsVpt) {
          ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
        }
        fill && fill.render(ctx);
        object && object.render(ctx);
        ctx.restore();
      }
    },
  });

  var _toObject = fabric.Object.prototype.toObject;
  var __createBaseSVGMarkup = fabric.Object.prototype._createBaseSVGMarkup;
  fabric.util.object.extend(fabric.Object.prototype, {
    /**
     * Indicates whether this object can be erased by {@link fabric.EraserBrush}
     * The `deep` option introduces fine grained control over a group's `erasable` property.
     * When set to `deep` the eraser will erase nested objects if they are erasable, leaving the group and the other objects untouched.
     * When set to `true` the eraser will erase the entire group. Once the group changes the eraser is propagated to its children for proper functionality.
     * When set to `false` the eraser will leave all objects including the group untouched.
     * @type boolean | 'deep'
     * @default true
     */
    erasable: false,

    /**
     *
     * @returns {fabric.Group | null}
     */
    getEraser: function () {
      return this.clipPath && this.clipPath.eraser ? this.clipPath : null;
    },

    /**
     * Returns an object representation of an instance
     * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
     * @return {Object} Object representation of an instance
     */
    toObject: function (additionalProperties) {
      return _toObject.call(this, ['erasable'].concat(additionalProperties));
    },

    /**
     * use <mask> to achieve erasing for svg
     * credit: https://travishorn.com/removing-parts-of-shapes-in-svg-b539a89e5649
     * @param {Function} reviver
     * @returns {string} markup
     */
    eraserToSVG: function (options) {
      var eraser = this.getEraser();
      if (eraser) {
        var fill = eraser._objects[0].fill;
        eraser._objects[0].fill = 'white';
        eraser.clipPathId = 'CLIPPATH_' + fabric.Object.__uid++;
        var commons = [
          'id="' + eraser.clipPathId + '"',
          /*options.additionalTransform ? ' transform="' + options.additionalTransform + '" ' : ''*/
        ].join(' ');
        var objectMarkup = ['<defs>', '<mask ' + commons + ' >', eraser.toSVG(options.reviver), '</mask>', '</defs>'];
        eraser._objects[0].fill = fill;
        return objectMarkup.join('\n');
      }
      return '';
    },

    /**
     * use <mask> to achieve erasing for svg, override <clipPath>
     * @param {string[]} objectMarkup
     * @param {Object} options
     * @returns
     */
    _createBaseSVGMarkup: function (objectMarkup, options) {
      var eraser = this.getEraser();
      if (eraser) {
        var eraserMarkup = this.eraserToSVG(options);
        this.clipPath = null;
        var markup = __createBaseSVGMarkup.call(this, objectMarkup, options);
        this.clipPath = eraser;
        return [eraserMarkup, markup.replace('>', 'mask="url(#' + eraser.clipPathId + ')" >')].join('\n');
      } else {
        return __createBaseSVGMarkup.call(this, objectMarkup, options);
      }
    },
  });

  var __restoreObjectsState = fabric.Group.prototype._restoreObjectsState;
  var _groupToObject = fabric.Group.prototype.toObject;
  fabric.util.object.extend(fabric.Group.prototype, {
    /**
     * Applies the eraser of the group to the given object
     * @param {fabric.Object} object an object that is part of this group
     */
    applyEraserToObject: function (object) {
      var transform = this.calcTransformMatrix();
      var eraser = this.getEraser();
      if (!eraser) {
        return;
      }
      eraser.getObjects('path').forEach(function (path) {
        if (object.intersectsWithObject(path)) {
          var t = path.calcTransformMatrix();
          path.clone(function (_path) {
            var originalTransform = fabric.util.multiplyTransformMatrices(transform, t);
            fabric.util.applyTransformToObject(_path, originalTransform);
            fabric.EraserBrush.prototype._addPathToObjectEraser.call(fabric.EraserBrush.prototype, object, _path);
          });
        }
      });
    },

    /**
     * Applies the group's eraser to its objects
     */
    applyEraserToObjects: function () {
      var _this = this;
      if (this.erasable === true && this.getEraser()) {
        this.forEachObject(function (object) {
          _this.applyEraserToObject(object);
        });
        delete this.clipPath;
      }
    },

    /**
     * Propagate the group's eraser to its objects, crucial for proper functionality of the eraser within the group and nested objects.
     * @private
     */
    _restoreObjectsState: function () {
      this.applyEraserToObjects();
      return __restoreObjectsState.call(this);
    },

    /**
     * Returns an object representation of an instance
     * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
     * @return {Object} Object representation of an instance
     */
    toObject: function (additionalProperties) {
      return _groupToObject.call(this, ['eraser'].concat(additionalProperties));
    },
  });

  fabric.util.object.extend(fabric.Canvas.prototype, {
    /**
     * Used by {@link #renderAll}
     * @returns boolean
     */
    isErasing: function () {
      return (
        this.isDrawingMode &&
        this.freeDrawingBrush &&
        this.freeDrawingBrush.type === 'eraser' &&
        this.freeDrawingBrush._isErasing
      );
    },

    /**
     * While erasing, the brush is in charge of rendering the canvas
     * It uses both layers to achieve diserd erasing effect
     *
     * @returns fabric.Canvas
     */
    renderAll: function () {
      if (this.contextTopDirty && !this._groupSelector && !this.isDrawingMode) {
        this.clearContext(this.contextTop);
        this.contextTopDirty = false;
      }
      // while erasing the brush is in charge of rendering the canvas so we return
      if (this.isErasing()) {
        this.freeDrawingBrush._render();
        return;
      }
      if (this.hasLostContext) {
        this.renderTopLayer(this.contextTop);
      }
      var canvasToDrawOn = this.contextContainer;
      this.renderCanvas(canvasToDrawOn, this._chooseObjectsToRender());
      return this;
    },
  });

  /**
   * EraserBrush class
   * Supports selective erasing meaning that only erasable objects are affected by the eraser brush.
   * In order to support selective erasing all non erasable objects are rendered on the main/bottom ctx
   * while the entire canvas is rendered on the top ctx.
   * Canvas bakground/overlay image/color are handled as well.
   * When erasing occurs, the path clips the top ctx and reveals the bottom ctx.
   * This achieves the desired effect of seeming to erase only erasable objects.
   * After erasing is done the created path is added to all intersected objects' `clipPath` property.
   *
   *
   * @class fabric.EraserBrush
   * @extends fabric.PencilBrush
   */
  fabric.EraserBrush = fabric.util.createClass(
    fabric.PencilBrush,
    /** @lends fabric.EraserBrush.prototype */ {
      type: 'eraser',

      /**
       * Indicates that the ctx is ready and rendering can begin.
       * Used to prevent a race condition caused by {@link fabric.EraserBrush#onMouseMove} firing before {@link fabric.EraserBrush#onMouseDown} has completed
       *
       * @private
       */
      _ready: false,

      /**
       * @private
       */
      _drawOverlayOnTop: false,

      /**
       * @private
       */
      _isErasing: false,

      initialize: function (canvas) {
        this.callSuper('initialize', canvas);
        this._renderBound = this._render.bind(this);
        this.render = this.render.bind(this);
      },

      /**
       * Used to hide a drawable from the rendering process
       * @param {fabric.Object} object
       */
      hideObject: function (object) {
        if (object) {
          object._originalOpacity = object.opacity;
          object.set({ opacity: 0 });
        }
      },

      /**
       * Restores hiding an object
       * {@link fabric.EraserBrush#hideObject}
       * @param {fabric.Object} object
       */
      restoreObjectVisibility: function (object) {
        if (object && object._originalOpacity) {
          object.set({ opacity: object._originalOpacity });
          object._originalOpacity = undefined;
        }
      },

      /**
       *
       * @private
       * @param {fabric.Object} object
       * @returns boolean
       */
      _isErasable: function (object) {
        return object.erasable !== false;
      },

      /**
       * Drawing Logic For background drawables: (`backgroundImage`, `backgroundColor`)
       * 1. if erasable = true:
       *    we need to hide the drawable on the bottom ctx so when the brush is erasing it will clip the top ctx and reveal white space underneath
       * 2. if erasable = false:
       *    we need to draw the drawable only on the bottom ctx so the brush won't affect it
       * @param {'bottom' | 'top' | 'overlay'} layer
       */
      prepareCanvasBackgroundForLayer: function (layer) {
        if (layer === 'overlay') {
          return;
        }
        var canvas = this.canvas;
        var image = canvas.get('backgroundImage');
        var color = canvas.get('backgroundColor');
        var erasablesOnLayer = layer === 'top';
        if (image && this._isErasable(image) === !erasablesOnLayer) {
          this.hideObject(image);
        }
        if (color && this._isErasable(color) === !erasablesOnLayer) {
          this.hideObject(color);
        }
      },

      /**
       * Drawing Logic For overlay drawables (`overlayImage`, `overlayColor`)
       * We must draw on top ctx to be on top of visible canvas
       * 1. if erasable = true:
       *    we need to draw the drawable on the top ctx as a normal object
       * 2. if erasable = false:
       *    we need to draw the drawable on top of the brush,
       *    this means we need to repaint for every stroke
       *
       * @param {'bottom' | 'top' | 'overlay'} layer
       * @returns boolean render overlay above brush
       */
      prepareCanvasOverlayForLayer: function (layer) {
        var canvas = this.canvas;
        var image = canvas.get('overlayImage');
        var color = canvas.get('overlayColor');
        if (layer === 'bottom') {
          this.hideObject(image);
          this.hideObject(color);
          return false;
        }
        var erasablesOnLayer = layer === 'top';
        var renderOverlayOnTop = (image && !this._isErasable(image)) || (color && !this._isErasable(color));
        if (image && this._isErasable(image) === !erasablesOnLayer) {
          this.hideObject(image);
        }
        if (color && this._isErasable(color) === !erasablesOnLayer) {
          this.hideObject(color);
        }
        return renderOverlayOnTop;
      },

      /**
       * @private
       */
      restoreCanvasDrawables: function () {
        var canvas = this.canvas;
        this.restoreObjectVisibility(canvas.get('backgroundImage'));
        this.restoreObjectVisibility(canvas.get('backgroundColor'));
        this.restoreObjectVisibility(canvas.get('overlayImage'));
        this.restoreObjectVisibility(canvas.get('overlayColor'));
      },

      /**
       * @private
       * This is designed to support erasing a group with both erasable and non-erasable objects.
       * Iterates over collections to allow nested selective erasing.
       * Used by {@link fabric.EraserBrush#prepareCanvasObjectsForLayer}
       * to prepare the bottom layer by hiding erasable nested objects
       *
       * @param {fabric.Collection} collection
       */
      prepareCollectionTraversal: function (collection) {
        var _this = this;
        collection.forEachObject(function (obj) {
          if (obj.forEachObject && obj.erasable === 'deep') {
            _this.prepareCollectionTraversal(obj);
          } else if (obj.erasable) {
            _this.hideObject(obj);
          }
        });
      },

      /**
       * @private
       * Used by {@link fabric.EraserBrush#prepareCanvasObjectsForLayer}
       * to reverse the action of {@link fabric.EraserBrush#prepareCollectionTraversal}
       *
       * @param {fabric.Collection} collection
       */
      restoreCollectionTraversal: function (collection) {
        var _this = this;
        collection.forEachObject(function (obj) {
          if (obj.forEachObject && obj.erasable === 'deep') {
            _this.restoreCollectionTraversal(obj);
          } else {
            _this.restoreObjectVisibility(obj);
          }
        });
      },

      /**
       * @private
       * This is designed to support erasing a group with both erasable and non-erasable objects.
       *
       * @param {'bottom' | 'top' | 'overlay'} layer
       */
      prepareCanvasObjectsForLayer: function (layer) {
        if (layer !== 'bottom') {
          return;
        }
        this.prepareCollectionTraversal(this.canvas);
      },

      /**
       * @private
       * @param {'bottom' | 'top' | 'overlay'} layer
       */
      restoreCanvasObjectsFromLayer: function (layer) {
        if (layer !== 'bottom') {
          return;
        }
        this.restoreCollectionTraversal(this.canvas);
      },

      /**
       * @private
       * @param {'bottom' | 'top' | 'overlay'} layer
       * @returns boolean render overlay above brush
       */
      prepareCanvasForLayer: function (layer) {
        this.prepareCanvasBackgroundForLayer(layer);
        this.prepareCanvasObjectsForLayer(layer);
        return this.prepareCanvasOverlayForLayer(layer);
      },

      /**
       * @private
       * @param {'bottom' | 'top' | 'overlay'} layer
       */
      restoreCanvasFromLayer: function (layer) {
        this.restoreCanvasDrawables();
        this.restoreCanvasObjectsFromLayer(layer);
      },

      /**
       * Render all non-erasable objects on bottom layer with the exception of overlays to avoid being clipped by the brush.
       * Groups are rendered for nested selective erasing, non-erasable objects are visible while erasable objects are not.
       */
      renderBottomLayer: function () {
        var canvas = this.canvas;
        this.prepareCanvasForLayer('bottom');
        canvas.renderCanvas(
          canvas.getContext(),
          canvas.getObjects().filter(function (obj) {
            return !obj.erasable || obj.isType('group');
          }),
        );
        this.restoreCanvasFromLayer('bottom');
      },

      /**
       * 1. Render all objects on top layer, erasable and non-erasable
       *    This is important for cases such as overlapping objects, the background object erasable and the foreground object not erasable.
       * 2. Render the brush
       */
      renderTopLayer: function () {
        var canvas = this.canvas;
        this._drawOverlayOnTop = this.prepareCanvasForLayer('top');
        canvas.renderCanvas(canvas.contextTop, canvas.getObjects());
        this.callSuper('_render');
        this.restoreCanvasFromLayer('top');
      },

      /**
       * Render all non-erasable overlays on top of the brush so that they won't get erased
       */
      renderOverlay: function () {
        this.prepareCanvasForLayer('overlay');
        var canvas = this.canvas;
        var ctx = canvas.contextTop;
        this._saveAndTransform(ctx);
        canvas._renderOverlay(ctx);
        ctx.restore();
        this.restoreCanvasFromLayer('overlay');
      },

      /**
       * @extends @class fabric.BaseBrush
       * @param {CanvasRenderingContext2D} ctx
       */
      _saveAndTransform: function (ctx) {
        this.callSuper('_saveAndTransform', ctx);
        ctx.globalCompositeOperation = 'destination-out';
      },

      /**
       * We indicate {@link fabric.PencilBrush} to repaint itself if necessary
       * @returns
       */
      needsFullRender: function () {
        return this.callSuper('needsFullRender') || this._drawOverlayOnTop;
      },

      /**
       *
       * @param {fabric.Point} pointer
       * @param {fabric.IEvent} options
       * @returns
       */
      onMouseDown: function (pointer, options) {
        if (!this.canvas._isMainEvent(options.e)) {
          return;
        }
        this._prepareForDrawing(pointer);
        // capture coordinates immediately
        // this allows to draw dots (when movement never occurs)
        this._captureDrawingPath(pointer);

        this._isErasing = true;
        this.canvas.fire('erasing:start');
        this._ready = true;
        this._render();
      },

      /**
       * Rendering is done in 4 steps:
       * 1. Draw all non-erasable objects on bottom ctx with the exception of overlays {@link fabric.EraserBrush#renderBottomLayer}
       * 2. Draw all objects on top ctx including erasable drawables {@link fabric.EraserBrush#renderTopLayer}
       * 3. Draw eraser {@link fabric.PencilBrush#_render} at {@link fabric.EraserBrush#renderTopLayer}
       * 4. Draw non-erasable overlays {@link fabric.EraserBrush#renderOverlay}
       *
       * @param {fabric.Canvas} canvas
       */
      _render: function () {
        if (!this._ready) {
          return;
        }
        this.isRendering = 1;
        this.renderBottomLayer();
        this.renderTopLayer();
        this.renderOverlay();
        this.isRendering = 0;
      },

      /**
       * @public
       */
      render: function () {
        if (this._isErasing) {
          if (this.isRendering) {
            this.isRendering = fabric.util.requestAnimFrame(this._renderBound);
          } else {
            this._render();
          }
          return true;
        }
        return false;
      },

      /**
       * Adds path to existing clipPath of object
       *
       * @param {fabric.Object} obj
       * @param {fabric.Path} path
       */
      _addPathToObjectEraser: function (obj, path) {
        var clipObject;
        var _this = this;
        //  object is collection, i.e group
        if (obj.forEachObject && obj.erasable === 'deep') {
          obj.forEachObject(function (_obj) {
            if (_obj.erasable) {
              _this._addPathToObjectEraser(_obj, path);
            }
          });
          return;
        }
        if (!obj.getEraser()) {
          var size = obj._getNonTransformedDimensions();
          var rect = new fabric.Rect({
            width: size.x,
            height: size.y,
            clipPath: obj.clipPath,
            originX: 'center',
            originY: 'center',
          });
          clipObject = new fabric.Group([rect], {
            eraser: true,
          });
        } else {
          clipObject = obj.clipPath;
        }

        path.clone(function (path) {
          path.globalCompositeOperation = 'destination-out';
          // http://fabricjs.com/using-transformations
          var desiredTransform = fabric.util.multiplyTransformMatrices(
            fabric.util.invertTransform(obj.calcTransformMatrix()),
            path.calcTransformMatrix(),
          );
          fabric.util.applyTransformToObject(path, desiredTransform);
          clipObject.addWithUpdate(path);
          obj.set({
            clipPath: clipObject,
            dirty: true,
          });
        });
      },

      /**
       * Add the eraser path to canvas drawables' clip paths
       *
       * @param {fabric.Canvas} source
       * @param {fabric.Canvas} path
       * @returns {Object} canvas drawables that were erased by the path
       */
      applyEraserToCanvas: function (path) {
        var canvas = this.canvas;
        var drawables = {};
        ['backgroundImage', 'backgroundColor', 'overlayImage', 'overlayColor'].forEach(function (prop) {
          var drawable = canvas[prop];
          if (drawable && drawable.erasable) {
            this._addPathToObjectEraser(drawable, path);
            drawables[prop] = drawable;
          }
        }, this);
        return drawables;
      },

      /**
       * On mouseup after drawing the path on contextTop canvas
       * we use the points captured to create an new fabric path object
       * and add it to every intersected erasable object.
       */
      _finalizeAndAddPath: function () {
        var ctx = this.canvas.contextTop,
          canvas = this.canvas;
        ctx.closePath();
        if (this.decimate) {
          this._points = this.decimatePoints(this._points, this.decimate);
        }

        // clear
        canvas.clearContext(canvas.contextTop);
        this._isErasing = false;

        var pathData =
          this._points && this._points.length > 1
            ? this.convertPointsToSVGPath(this._points).join('')
            : 'M 0 0 Q 0 0 0 0 L 0 0';
        if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
          canvas.fire('erasing:end');
          // do not create 0 width/height paths, as they are
          // rendered inconsistently across browsers
          // Firefox 4, for example, renders a dot,
          // whereas Chrome 10 renders nothing
          canvas.requestRenderAll();
          return;
        }

        var path = this.createPath(pathData);
        canvas.fire('before:path:created', { path: path });

        // finalize erasing
        var drawables = this.applyEraserToCanvas(path);
        var _this = this;
        var targets = [];
        canvas.forEachObject(function (obj) {
          // if (obj.erasable && obj.intersectsWithObject(path)) {
          if (obj.erasable) {
            _this._addPathToObjectEraser(obj, path);
            targets.push(obj);
          }
        });

        canvas.fire('erasing:end', {
          path: path,
          targets: targets,
          drawables: drawables,
        });

        canvas.requestRenderAll();
        path.setCoords();
        this._resetShadow();

        // fire event 'path' created
        canvas.fire('path:created', { path: path });
      },
    },
  );

  /** ERASER_END */

  /** MAGIC WAND START */
  fabric.MagicWand = fabric.util.createClass(
    /** @lends fabric.MagicWand.prototype */ {
      type: 'magicWand',

      colorThreshold: 15,
      blurRadius: 0,
      simplifyTolerant: 5,
      simplifyCount: 30,
      hatchLength: 4,
      hatchOffset: 0,

      imageInfo: null,
      cacheInd: null,
      mask: null,
      oldMask: null,
      downPoint: null,
      allowDraw: false,
      addMode: false,
      currentThreshold: 0,
      curZoom: null,

      hatchTick: function () {
        this.hatchOffset = (this.hatchOffset + 1) % (this.hatchLength * 2);
        this.drawBorder(true);
      },
      initialize: function (canvas) {
        this.callSuper('initialize', canvas);
        canvas.backgroundImage._element.setAttribute('crossOrigin', '');

        const context = canvas.getContext();
        const context_upper = canvas.upperCanvasEl.getContext('2d');

        this.imageInfo = {
          width: canvas.width,
          height: canvas.height,
          context: context,
          context_upper: context_upper,
        };

        this.mask = null;
        this.imageInfo.data = context.getImageData(0, 0, this.imageInfo.width, this.imageInfo.height);

        this.currentThreshold = this.colorThreshold;
        this.curZoom = canvas.getZoom();

        setInterval(
          function () {
            this.hatchTick();
          }.bind(this),
          300,
        );
      },
      resetCanvas: function (canvas) {
        canvas.backgroundImage._element.setAttribute('crossOrigin', '');
        const context = canvas.getContext('2d');
        const context_upper = canvas.upperCanvasEl.getContext('2d');

        this.imageInfo = {
          width: Math.round(canvas.width),
          height: Math.round(canvas.height),
          context: context,
          context_upper: context_upper,
        };

        this.imageInfo.data = context.getImageData(0, 0, this.imageInfo.width, this.imageInfo.height);
        this.mask = null;

        // this.downPoint = {
        //   x: Math.round(this.downPoint.x * (1 + (canvas.getZoom() - this.curZoom))),
        //   y: Math.round(this.downPoint.y * (1 + (canvas.getZoom() - this.curZoom))),
        // };
        // this.drawMask(this.downPoint.x, this.downPoint.y);
      },
      onRadiusChange: function (radius) {
        if (radius) {
          this.blurRadius = radius;
        }
      },

      getMousePosition: function (e) {
        const x = Math.round((e.clientX || e.pageX) - e.offsetX);
        const y = Math.round((e.clientY || e.pageY) - e.offsetY);
        return { x: x, y: y };
      },

      onMouseDown: function (e) {
        if (e.button == 0) {
          this.allowDraw = true;
          this.addMode = e.ctrlKey;
          this.downPoint = {
            x: e.offsetX,
            y: e.offsetY,
          };

          this.drawMask(this.downPoint.x, this.downPoint.y);
        } else {
          this.allowDraw = false;
          this.addMode = false;
          this.oldMask = null;
        }
      },
      onMouseMove: function (e) {
        if (this.allowDraw) {
          var p = {
            x: e.offsetX,
            y: e.offsetY,
          };
          if (p.x != this.downPoint.x || p.y != this.downPoint.y) {
            var dx = p.x - this.downPoint.x,
              dy = p.y - this.downPoint.y,
              len = Math.sqrt(dx * dx + dy * dy),
              adx = Math.abs(dx),
              ady = Math.abs(dy),
              sign = adx > ady ? dx / adx : dy / ady;
            sign = sign < 0 ? sign / 5 : sign / 3;
            var thres = Math.min(Math.max(this.colorThreshold + Math.floor(sign * len), 1), 255);
            //var thres = Math.min(colorThreshold + Math.floor(len / 3), 255);
            if (thres != this.currentThreshold) {
              this.currentThreshold = thres;
              this.drawMask(this.downPoint.x, this.downPoint.y);
            }
          }
        }
      },
      onMouseUp: function (e) {
        this.allowDraw = false;
        this.addMode = false;
        this.oldMask = null;
        this.currentThreshold = this.colorThreshold;
      },
      drawMask: function (x, y) {
        if (!this.imageInfo) return;
        var image = {
          data: this.imageInfo.data.data,
          width: Math.round(this.imageInfo.width),
          height: Math.round(this.imageInfo.height),
          bytes: 4,
        };

        if (this.addMode && !this.oldMask) {
          this.oldMask = this.mask;
        }

        let old = this.oldMask ? this.oldMask.data : null;
        this.mask = MagicWand.floodFill(image, x, y, this.currentThreshold, old, true);
        if (this.mask) this.mask = MagicWand.gaussBlurOnlyBorder(this.mask, this.blurRadius, old);

        if (this.addMode && this.oldMask) {
          this.mask = this.mask ? this.concatMasks(this.mask, this.oldMask) : this.oldMask;
        }

        this.drawBorder();
      },

      drawBorder: function (noBorder) {
        if (!this.mask) return;

        var x,
          y,
          i,
          j,
          k,
          w = this.imageInfo.width,
          h = this.imageInfo.height,
          ctx = this.imageInfo.context_upper,
          imgData = ctx.createImageData(w, h),
          res = imgData.data;

        if (!noBorder) this.cacheInd = MagicWand.getBorderIndices(this.mask);

        ctx.clearRect(0, 0, w, h);

        var len = this.cacheInd.length;
        for (j = 0; j < len; j++) {
          i = this.cacheInd[j];
          x = i % w; // calc x by index
          y = (i - x) / w; // calc y by index
          k = (y * w + x) * 4;
          if ((x + y + this.hatchOffset) % (this.hatchLength * 2) < this.hatchLength) {
            // detect hatch color
            res[k + 3] = 255; // black, change only alpha
          } else {
            res[k] = 255; // white
            res[k + 1] = 255;
            res[k + 2] = 255;
            res[k + 3] = 255;
          }
        }

        ctx.putImageData(imgData, 0, 0);
      },
      paint: function (color, canvas) {
        if (!this.mask) return;

        const rgba = color.slice(5, -1).replaceAll(' ', '');
        const rgbaArr = rgba.split(',');
        const convertRgba = rgbaArr.map((color, index) => {
          if (index === 3) return Math.round(color * 255);
          else return Number(color);
        });

        var x,
          y,
          data = this.mask.data,
          bounds = this.mask.bounds,
          maskW = this.mask.width,
          w = Math.round(this.imageInfo.width),
          h = Math.round(this.imageInfo.height),
          ctx = this.imageInfo.context_upper,
          imgData = ctx.createImageData(w, h),
          res = imgData.data;

        for (y = bounds.minY; y <= bounds.maxY; y++) {
          for (x = bounds.minX; x <= bounds.maxX; x++) {
            if (data[y * maskW + x] == 0) continue;
            const k = (y * w + x) * 4;
            res[k] = convertRgba[0];
            res[k + 1] = convertRgba[1];
            res[k + 2] = convertRgba[2];
            res[k + 3] = convertRgba[3];
          }
        }

        this.mask = null;

        ctx.putImageData(imgData, 0, 0);
        const dataURL = ctx.canvas.toDataURL();

        const originImageSize = canvas.width / canvas.getZoom();
        const imageScale = originImageSize / canvas.width;

        fabric.Image.fromURL(
          dataURL,
          function (img) {
            img.scale(imageScale);
            // 생성된 fabric.Image 객체를 캔버스에 추가
            canvas.add(img);
            canvas.setWidth(canvas.getWidth());
            canvas.setHeight(canvas.getHeight()).renderAll();
          },
          { erasable: true, category: { ...canvas.activeCategory }, evented: false },
        );
      },
      //hexToRgb: function (hex, alpha) {
      //  var int = parseInt(hex, 16);
      //  var r = (int >> 16) & 255;
      //  var g = (int >> 8) & 255;
      //  var b = int & 255;

      //  return [r, g, b, Math.round(alpha * 255)];
      //},
      concatMasks: function (mask, old) {
        let data1 = old.data,
          data2 = mask.data,
          w1 = old.width,
          w2 = mask.width,
          b1 = old.bounds,
          b2 = mask.bounds,
          b = {
            // bounds for new mask
            minX: Math.min(b1.minX, b2.minX),
            minY: Math.min(b1.minY, b2.minY),
            maxX: Math.max(b1.maxX, b2.maxX),
            maxY: Math.max(b1.maxY, b2.maxY),
          },
          w = old.width, // size for new mask
          h = old.height,
          i,
          j,
          k,
          k1,
          k2,
          len;
        let result = new Uint8Array(w * h);

        // copy all old mask
        len = b1.maxX - b1.minX + 1;
        i = b1.minY * w + b1.minX;
        k1 = b1.minY * w1 + b1.minX;
        k2 = b1.maxY * w1 + b1.minX + 1;
        // walk through rows (Y)
        for (k = k1; k < k2; k += w1) {
          result.set(data1.subarray(k, k + len), i); // copy row
          i += w;
        }

        // copy new mask (only "black" pixels)
        len = b2.maxX - b2.minX + 1;
        i = b2.minY * w + b2.minX;
        k1 = b2.minY * w2 + b2.minX;
        k2 = b2.maxY * w2 + b2.minX + 1;
        // walk through rows (Y)
        for (k = k1; k < k2; k += w2) {
          // walk through cols (X)
          for (j = 0; j < len; j++) {
            if (data2[k + j] === 1) result[i + j] = 1;
          }
          i += w;
        }

        return {
          data: result,
          width: w,
          height: h,
          bounds: b,
        };
      },
    },
  );
  /** MAGIC WAND END */
  fabric.Object.prototype.exportMonochrome = function (callback) {
    const obj = this;
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    newCanvas.width = obj.canvas.get('width');
    newCanvas.height = obj.canvas.get('height');

    const image = new Image();
    image.src = this.toDataURL();
    image.onload = function () {
      context.drawImage(image, obj.left, obj.top);

      const imageData = context.getImageData(0, 0, newCanvas.width, newCanvas.height);
      for (let i = 0; i < imageData.data.length; i += 4) {
        let count = imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2];
        /*
         * background : black 0
         * brush : white 255
         * */
        let colour = 0;
        if (count > 0) colour = 255;

        imageData.data[i] = colour;
        imageData.data[i + 1] = colour;
        imageData.data[i + 2] = colour;
        imageData.data[i + 3] = 255;
      }
      context.putImageData(imageData, 0, 0);

      // callback(newCanvas);
      callback(newCanvas.toDataURL('image/png'));
    };
  };
  fabric.Object.prototype.exportMonochromeBinary = function (callback) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.canvas.width;
    canvas.height = this.canvas.height;

    const image = new Image();
    const obj = this;
    image.onload = function () {
      context.drawImage(image, obj.left, obj.top);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const binaryResult = [];
      let j = 0;
      for (let i = 0; i < imageData.data.length; i += 4) {
        let colour = 0;
        if (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2] > 0) colour = 1;

        if (!binaryResult[j]) binaryResult.push(new Array());
        binaryResult[j].push(colour);
        if (binaryResult[j].length === imageData.width) {
          j++;
        }
      }
      callback(binaryResult);
    };
    image.src = this.toDataURL();
  };

  fabric.Canvas.prototype.getAnnotations = function () {
    return this.getObjects().filter((obj) => {
      return !obj.isCursor && obj.get('type') !== 'group';
    });
  };

  fabric.Canvas.prototype.selectedTool = {
    type: null,
    polygon: {
      //roofPoints: [],
      //lines: [],
      //lineCount: 0,
      activeLine: null,
      activeShape: null,
      lineArray: [],
      pointArray: [],
    },
    circle: {
      isDrawing: false,
      ref: null,
    },
    rect: {
      isDrawing: false,
      ref: null,
    },
    ellipse: {
      isDrawing: false,
      ref: null,
    },
    magicWand: {
      isDrawing: false,
      ref: null,
    },
  };

  fabric.Canvas.prototype.setReadOnly = function (flag) {
    this.readOnly = flag;
  };
  fabric.Canvas.prototype.setStrokeColor = function (strokeColor) {
    this.strokeColor = strokeColor;
  };

  let boundingMaskCount = 0;
  fabric.Canvas.prototype.initDrawTool = function (
    cursorCanvas,
    fill,
    strokeColor,
    strokeWidth,
    brushCursorSize,
    brushColor,
  ) {
    const canvas = this;

    this.fill = fill;
    this.strokeWidth = strokeWidth;
    this.strokeColor = strokeColor;
    this.selectedTool = {
      type: null,
      isDrawing: false,
      ref: null,
      polygon: {
        //roofPoints: [],
        //lines: [],
        //lineCount: 0,
        activeLine: null,
        activeShape: null,
        lineArray: [],
        pointArray: [],
      },
    };

    const newStrokeWidth = Math.min(this.getWidth(), this.getHeight()) * 0.003;
    if (cursorCanvas) {
      cursorCanvas.mouseCursor = new fabric.Circle({
        // isCursor: true,
        left: -500,
        top: -500,
        radius: brushCursorSize / 2,
        fill: brushColor,
        stroke: 'black',
        strokeWidth: newStrokeWidth,
        originX: 'center',
        originY: 'center',
      });
      cursorCanvas.add(cursorCanvas.mouseCursor);
    }
    const cursorGuide_horizontal = new fabric.Polyline(
      [
        { x: 0, y: 0 },
        { x: this.width * 2, y: 0 },
      ],
      {
        isCursor: true,
        left: -this.width,
        top: 0,
        fill: '',
        stroke: 'black',
        selectable: false,
      },
    );
    const cursorGuide_vertical = new fabric.Polyline(
      [
        { x: 0, y: 0 },
        { x: 0, y: this.height * 2 },
      ],
      {
        isCursor: true,
        left: 0,
        top: -this.height,
        fill: '',
        stroke: 'black',
        selectable: false,
      },
    );
    this.cursorGuide = new fabric.Group([cursorGuide_horizontal, cursorGuide_vertical], {
      isCursor: true,
      left: this.width * 3,
      top: this.height * 3,
      hoverCursor: 'default',
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
    });
    this.add(this.cursorGuide);

    const mouseOutEvent = function () {
      if (canvas.cursorGuide) {
        const currentActiveObj = canvas.getActiveObject();
        if (currentActiveObj && currentActiveObj.isCursor) canvas.discardActiveObject();
        //mouse out cursor remove
        canvas.cursorGuide
          .set({
            left: canvas.width / canvas.getZoom(),
            top: canvas.height / canvas.getZoom(),
          })
          .setCoords()
          .canvas.renderAll();
      }
      if (cursorCanvas) {
        cursorCanvas.mouseCursor.set({ left: -500, top: -500 }).setCoords().canvas.renderAll();
      }
    };

    // 마우스 누를 때 커서가이드 없어짐
    const mouseDownEvent = function (options) {
      if (canvas.cursorGuide) {
        //mouse out cursor remove
        canvas.cursorGuide
          .set({
            left: canvas.width / canvas.getZoom(),
            top: canvas.height / canvas.getZoom(),
          })
          .setCoords()
          .canvas.renderAll();
      }

      if (canvas.readOnly) {
        return;
      }
      // polygon

      if (canvas.selectedTool.type === 'polygon') {
        //if (canvas.selectedTool.isDrawing) {
        //if (canvas.isDrawingMode) { // 이부분 필요한지 봐야함
        //canvas.selectedTool.isDrawing = true;
        canvas.selectedTool.polygon.isDrawing = true;
        canvas.activeCategory.hasLabeling = true;
        if (
          options.target &&
          options.target.id !== undefined &&
          options.target.id === canvas.selectedTool.polygon.pointArray[0].id
        ) {
          // when click on the first point
          this.polygon.generatePolygon(canvas.selectedTool.polygon.pointArray);
          this.polygon.editPolygon();
        } else {
          this.polygon.addPoint(options);
        }
        //}
      }

      if (canvas.selectedTool.type === 'rectangle') {
        canvas.selectedTool.isDrawing = true;
        const point = canvas.getPointer(options.e);

        const rect = new fabric.Rect({
          left: point.x,
          top: point.y,
          width: 0,
          height: 0,
          stroke: canvas.strokeColor,
          strokeWidth: canvas.strokeWidth,
          fill: canvas.fill,
          strokeUniform: true,
          selectable: false,
          hoverCursor: 'default',
          noScaleCache: false,
        });
        canvas.add(rect);
        canvas.selectedTool.ref = rect;
      }
      if (canvas.selectedTool.type === 'SAMrectangle') {
        canvas.selectedTool.isDrawing = true;
        const point = canvas.getPointer(options.e);

        const rect = new fabric.Rect({
          left: point.x,
          top: point.y,
          width: 0,
          height: 0,
          stroke: 'white',
          strokeWidth: canvas.strokeWidth,
          fill: canvas.fill,
          strokeUniform: true,
          selectable: false,
          hoverCursor: 'default',
        });
        canvas.add(rect);
        canvas.selectedTool.ref = rect;
      }

      if (canvas.selectedTool.type === 'eraser') {
        canvas.categories.forEach((category) => {
          category.hasLabeling = true;
        });
      }

      if (canvas.selectedTool.type === 'path' || canvas.selectedTool.type === 'sam') {
        canvas.activeCategory.hasLabeling = true;
      }

      if (canvas.selectedTool.type === 'path') {
        canvas.selectedTool.isDrawing = true;
      }

      if (canvas.selectedTool.type === 'sam') {
        const isContextMenu = options.type === 'contextmenu';
        // console.log('samClick', options);
        // SAM Click
        // type = 1: onClick, 2 : onContextMenu
        const type = isContextMenu ? 0 : 1;
        let x = isContextMenu ? options.offsetX : options.pointer.x;
        let y = isContextMenu ? options.offsetY : options.pointer.y;

        // SAM :: Todo Scale

        const originImageSize = canvas.width / canvas.getZoom();
        const imageScale = originImageSize / canvas.width;
        x *= imageScale;
        y *= imageScale;
        const click = { x, y, type };
        if (click) {
          const categoryMaskData = { ...canvas.activeCategory.maskData };
          if (categoryMaskData && categoryMaskData.clicks) {
            categoryMaskData.clicks.push(click);
          } else {
            canvas.activeCategory.maskData = {
              clicks: [{ ...click }],
            };
          }
          // console.log('canvas.activeCategory', canvas.activeCategory);
          // setMaskData(tempData);
          // console.log(categoryMaskData.clicks);
        }

        canvas.runONNX();
      }

      if (canvas.selectedTool.type === 'magicWand') {
        canvas.selectedTool.isDrawing = true;
        const point = canvas.getPointer(options.e);
        canvas.magicWandClass.onMouseDown(options.e, point);
      }

      // setClicks(clicks ? [click, ...clicks] : [click]);
      // console.log('sam');
    };
    const throttledFunction = throttle((isMove) => {
      this.boundingMaskCount++;
      canvas.runONNXForBoundingBox(isMove);
    }, 700);

    const runONNXWithThrottle = (isMove) => {
      throttledFunction(isMove);
    };

    const mouseMoveEvent = function (options) {
      //mouse move cursor move
      const zoom = canvas.getZoom();
      const pointer = canvas.getPointer(options.e);
      if (canvas.cursorGuide) {
        canvas.cursorGuide
          .set({
            top: pointer.y - canvas.height / zoom,
            left: pointer.x - canvas.width / zoom,
          })
          .setCoords()
          .canvas.renderAll();
      }

      // 브러쉬일 땐, 클릭 안했을 때도 마우스 커서가 보이게
      if (canvas.selectedTool.type === 'path') {
        // 커서가이드 안보이게 설정 (우선 top, left 0 으로 숨기는데 추후 remove 하는 방법이 있을 것으로 생각됨)
        canvas.cursorGuide
          .set({
            top: 0,
            left: 0,
          })
          .setCoords()
          .canvas.renderAll();

        // 브러쉬 커서
        cursorCanvas.mouseCursor
          .set({
            top: pointer.y,
            left: pointer.x,
          })
          .setCoords()
          .canvas.renderAll();
      }

      if (canvas.selectedTool.type === 'eraser') {
        // 커서가이드 안보이게 설정
        canvas.cursorGuide
          .set({
            top: 0,
            left: 0,
          })
          .setCoords()
          .canvas.renderAll();

        // 브러쉬 커서
        cursorCanvas.mouseCursor
          .set({
            top: pointer.y,
            left: pointer.x,
          })
          .setCoords()
          .canvas.renderAll();
      }

      // polygon
      if (canvas.selectedTool.type === 'polygon') {
        if (canvas.selectedTool.polygon.isDrawing) {
          if (canvas.selectedTool.polygon.activeLine && canvas.selectedTool.polygon.activeLine.class === 'line') {
            const pointer = canvas.getPointer(options.e);
            canvas.selectedTool.polygon.activeLine.set({
              x2: pointer.x,
              y2: pointer.y,
            });
            const points = canvas.selectedTool.polygon.activeShape.get('points');
            points[canvas.selectedTool.polygon.pointArray.length] = {
              x: pointer.x,
              y: pointer.y,
            };
            canvas.selectedTool.polygon.activeShape.set({
              points,
            });
          }
          canvas.renderAll();
        }
      }
      // 마우스 클릭한 채로 움직였을 때
      if (canvas.selectedTool.isDrawing) {
        const ref = canvas.selectedTool.ref;

        //if (
        //  canvas.selectedTool.type === 'polygon' &&
        //  canvas.selectedTool.polygon.lines[0] !== null &&
        //  canvas.selectedTool.polygon.lines[0] !== undefined
        //) {
        //  const point = canvas.getPointer(options.e);
        //  canvas.selectedTool.polygon.lines[canvas.selectedTool.polygon.lineCount - 1].set({
        //    x2: point.x,
        //    y2: point.y,
        //  });
        //  canvas.renderAll();
        //} else
        if (canvas.selectedTool.type === 'magicWand') {
          canvas.magicWandClass.onMouseMove(options.e);
        } else {
          const canvasCoordsTl = canvas.vptCoords.tl;
          const canvasCoordsBr = canvas.vptCoords.br;
          const pointer = canvas.getPointer(options.e);
          if (canvasCoordsBr.x < pointer.x) pointer.x = canvasCoordsBr.x - ref.strokeWidth;
          else if (canvasCoordsTl.x > pointer.x) pointer.x = canvasCoordsTl.x + ref.strokeWidth;

          if (canvasCoordsBr.y < pointer.y) pointer.y = canvasCoordsBr.y - ref.strokeWidth;
          else if (canvasCoordsTl.y > pointer.y) pointer.y = canvasCoordsTl.y + ref.strokeWidth;

          if (canvas.selectedTool.type === 'circle') {
            let radius = Math.abs(ref.left - pointer.x) / 2;
            if (radius > ref.strokeWidth) {
              radius -= ref.strokeWidth / 2;
            }
            canvas.selectedTool.ref.set('radius', radius);

            if (ref.left > pointer.x) {
              ref.set({ originX: 'right' });
            } else {
              ref.set({ originX: 'left' });
            }
            if (ref.top > pointer.y) {
              ref.set({ originY: 'bottom' });
            } else {
              ref.set({ originY: 'top' });
            }
            canvas.renderAll();
          } else if (canvas.selectedTool.type === 'ellipse') {
            let rx = Math.abs(ref.left - pointer.x) / 2;
            let ry = Math.abs(ref.top - pointer.y) / 2;
            if (rx > ref.strokeWidth) {
              rx -= ref.strokeWidth / 2;
            }
            if (ry > ref.strokeWidth) {
              ry -= ref.strokeWidth / 2;
            }
            ref.set({ rx: rx, ry: ry });

            if (ref.left > pointer.x) {
              ref.set({ originX: 'right' });
            } else {
              ref.set({ originX: 'left' });
            }
            if (ref.top > pointer.y) {
              ref.set({ originY: 'bottom' });
            } else {
              ref.set({ originY: 'top' });
            }

            canvas.renderAll();
          } else if (canvas.selectedTool.type === 'SAMrectangle') {
            const width = Math.abs(pointer.x - ref.left);
            const height = Math.abs(pointer.y - ref.top);
            ref.set('width', width).set('height', height);

            if (ref.left > pointer.x) {
              ref.set({ originX: 'right' });
            } else {
              ref.set({ originX: 'left' });
            }
            if (ref.top > pointer.y) {
              ref.set({ originY: 'bottom' });
            } else {
              ref.set({ originY: 'top' });
            }

            const boundingBox = { x: ref.left, y: ref.top, width: pointer.x, height: pointer.y, type: 2 };
            canvas.activeCategory.maskData = {
              boundingBox: [boundingBox],
            };
            // 한번 바운딩박스 그릴때 3번까지만 마스크를 보여준다.
            if (this.boundingMaskCount < 3) runONNXWithThrottle(true);
            // canvas.runONNXForBoundingBox(true);
          } else if (canvas.selectedTool.type === 'rectangle') {
            if (canvasCoordsBr.x < pointer.x) pointer.x = canvasCoordsBr.x;
            else if (canvasCoordsTl.x > pointer.x) pointer.x = canvasCoordsTl.x;

            if (canvasCoordsBr.y < pointer.y) pointer.y = canvasCoordsBr.y;
            else if (canvasCoordsTl.y > pointer.y) pointer.y = canvasCoordsTl.y;

            const width = Math.abs(pointer.x - ref.left);
            const height = Math.abs(pointer.y - ref.top);
            ref.set('width', width).set('height', height);
            if (ref.left > pointer.x) {
              ref.set({ originX: 'right' });
            } else {
              ref.set({ originX: 'left' });
            }
            if (ref.top > pointer.y) {
              ref.set({ originY: 'bottom' });
            } else {
              ref.set({ originY: 'top' });
            }
            canvas.renderAll();
          }
        }

        // if (canvas.selectedTool.type === 'path') {
        //   console.log('brush ref, ', ref);
        // }
        // MC625 od 라벨링 버그 수정을 위해 modified 작업과 분리
        if (ref !== null && canvas.selectedTool.type !== 'rectangle') {
          canvas.fire('object:modified', { target: ref });
        }
      }
    };
    const mouseUpEvent = function (options) {
      let isRemoved = false;
      if (canvas.selectedTool.type !== '') {
        // ref.top, ref.left
        const ref = canvas.selectedTool.ref;
        // point 우측 끝점
        const point = canvas.getPointer(options.e);
        // bbox 그릴 때만, bbox가 너무 작을때 지워짐
        if (canvas.selectedTool.type === 'rectangle') {
          if (ref && point && (Math.abs(point.x - ref.get('left')) < 10 || Math.abs(point.y - ref.get('top')) < 10)) {
            isRemoved = true;
            canvas.remove(ref);
          }
        } else if (canvas.selectedTool.type === 'SAMrectangle') {
          const boundingBox = { x: ref.left, y: ref.top, width: point.x, height: point.y, type: 2 };
          canvas.activeCategory.maskData = {
            boundingBox: [boundingBox],
          };
          isRemoved = true;
          canvas.runONNXForBoundingBox(false);
          canvas.remove(ref);
        } else if (canvas.selectedTool.type === 'path') {
          /**
           * bbox 는 MouseDown 시에 ref를 지정해주지만, brush는 활성화된 객체를 식별하는 함수를 제공하지 않기 때문에,
           * 다 그려진 다음에 전체 object를 갖고와서 type이 'path'인것을 추출 한 후에, 가장 마지막 배열(가장 최근에 그려진)을 ref에 담는다.
           */
          // 캔버스에 추가된 모든 객체 가져오기
          // 캔버스에 추가된 모든 객체 중 path 타입인 객체 필터링
          let pathObjects = canvas.getObjects().filter((obj) => obj.type === 'path');
          pathObjects.forEach((obj) => {
            obj.evented = false;
            obj.hoverCursor = 'default';
          });
          canvas.selectedTool.ref = pathObjects[pathObjects.length - 1];
        } else if (canvas.selectedTool.type === 'magicWand') {
          canvas.selectedTool.isDrawing = false; // 드로잉 끝
          canvas.magicWandClass.onMouseUp();
        } else if (canvas.selectedTool.type === 'polygon') {
          //if (canvas.selectedTool.isDrawing) {
          //  canvas.drawPolygon.addPolyline(options);
          //}
          // canvas.selection = true;
        }
        canvas.selectedTool.isDrawing = false; // 드로잉 끝

        if (canvas.selectedTool.type !== 'rectangle') {
          canvas.setViewportTransform(canvas.viewportTransform); // 어떤 기능을 하는 건지 아직 식별 안됨 (20240207)
        }
      }

      if (canvas.selectedTool.ref !== null && !isRemoved) {
        // MC625 od 라벨링 버그 수정을 위해 modified 작업과 분리
        // canvas.fire('object:modified', { target: canvas.selectedTool.ref });
        if (canvas.selectedTool.type !== 'rectangle') {
          canvas.fire('object:modified', { target: canvas.selectedTool.ref });
        } else {
          canvas.fire('object:finished', { target: canvas.selectedTool.ref });
        }
      }
    };
    //const keyupEvent = function (evnt) {
    //  if (evnt.keyCode === 13 && canvas.selectedTool.type === 'polygon') {
    //    //enter >> complete polygon
    //    canvas.drawPolygon.makePolygon(canvas.selectedTool.polygon.roofPoints);
    //  }
    //};

    this.setListener = function () {
      // fabric.util.addListener(window, 'dblclick', dblClickEvent);
      this.on('mouse:out', mouseOutEvent);
      this.on('mouse:down', mouseDownEvent);
      this.on('mouse:move', mouseMoveEvent);
      this.on('mouse:up', mouseUpEvent);
      //document.addEventListener('keydown', keyupEvent);
    };
    this.unsetListener = function () {
      // fabric.util.removeListener(window, 'dblclick', dblClickEvent);
      this.off('mouse:out', mouseOutEvent);
      this.off('mouse:down', mouseDownEvent);
      this.off('mouse:move', mouseMoveEvent);
      this.off('mouse:up', mouseUpEvent);
      //document.removeEventListener('keydown', keyupEvent);
    };

    this.setListener();

    this.stopDraw = function (selectableCanvas = true) {
      this.setSelectableCanvas(selectableCanvas);

      //if (canvas.selectedTool.type === 'polygon') canvas.drawPolygon.cancel();
      canvas.selectedTool.type = '';
      canvas.selectedTool.isDrawing = false;
      canvas.selectedTool.ref = null;

      canvas.setViewportTransform(canvas.viewportTransform);
    };
    this.setSelectableCanvas = function (flag) {
      if (flag === canvas.selection) return;

      canvas.selection = flag;
      canvas.forEachObject((obj) => {
        if (obj.isCursor) {
          obj.selectable = false;
        } else {
          obj.selectable = flag;
        }

        //obj.hoverCursor = flag? "move" : "default"
      });
    };

    this.setSelectableCanvas(false);

    const setErasable = function (flag) {
      if (canvas && canvas.getAnnotations()) {
        const objs = canvas.getAnnotations();
        objs.forEach((obj) => {
          if (obj.path) obj.set('erasable', flag);
        });
      }
    };

    this.drawRectangle = {
      init: function () {
        canvas.isDrawingMode = false;
        canvas.selectedTool.type = 'rectangle';
        canvas.selectedTool.isDrawing = false;
        canvas.selectedTool.ref = null;
      },
      start: function () {
        canvas.stopDraw(false);
        this.init();
      },
    };
    this.drawSAMRectangle = {
      init: function () {
        canvas.isDrawingMode = false;
        canvas.selectedTool.type = 'SAMrectangle';
        canvas.selectedTool.isDrawing = false;
        canvas.selectedTool.ref = null;
      },
      start: function () {
        canvas.stopDraw(false);
        this.init();
      },
    };
    this.sam = {
      init: function (categories, activeCategory) {
        canvas.isDrawingMode = false;
        canvas.selectedTool.type = 'sam';
        canvas.selectedTool.isDrawing = false;
        canvas.selectedTool.ref = null;
        canvas.activeCategory = activeCategory;
        canvas.categories = categories;
      },
      start: function (categories, activeCategory) {
        // console.log('sam start');
        canvas.stopDraw(false);
        this.init(categories, activeCategory);
      },
      changedCategory(activeCategory) {
        // console.log('changedCategory');
        canvas.activeCategory = activeCategory;
      },
      setModel(model) {
        canvas.model = model;
      },
      setTensor(tensor) {
        canvas.tensor = tensor;
      },
      setModelScale(modelScale, modelScaleForClick) {
        canvas.modelScale = modelScale;
        canvas.modelScaleForClick = modelScaleForClick;
      },
      resetClicks() {
        canvas.activeCategory.maskData = [];
      },
    };
    this.drawBrush = {
      init: function (brushWidth, categoryColor) {
        canvas.selectedTool.type = 'path';
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.isDrawingMode = true;
        canvas.selectedTool.isDrawing = false;
        canvas.freeDrawingCursor = 'none';
        canvas.freeDrawingBrush.width = brushWidth;
        canvas.freeDrawingBrush.color = categoryColor;
        cursorCanvas.mouseCursor.set({ fill: categoryColor });
        setErasable(false);
      },
      start: function (brushWidth, categoryColor) {
        canvas.stopDraw(false);
        this.init(brushWidth, categoryColor);
      },
    };
    this.eraser = {
      init: function (brushWidth, categories) {
        canvas.selectedTool.type = 'eraser';
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        canvas.isDrawingMode = true;
        canvas.selectedTool.isDrawing = false;
        canvas.freeDrawingBrush.width = brushWidth;
        cursorCanvas.mouseCursor.set({ fill: 'rgba(255,255,255,1)' });
        canvas.freeDrawingBrush.globalCompositeOperation = 'destination-out';
        canvas.categories = categories;
        setErasable(true);
      },
      start: function (brushWidth, categories) {
        canvas.stopDraw(false);
        this.init(brushWidth, categories);
      },
    };
    /**
     * Click SAM, Bounding Box SAM, 임시 Bounding Box SAM 영역 마스킹
     * @param {string} type // click, box, temporaryBox(mouse up 이벤트 전 임시로 보여주는 box)
     * @param {Array} output // mask 모델 돌린 후 결과 배열
     */
    this.drawSAM = function (type, output) {
      let svgStr;
      if (type === 'click') svgStr = traceOnnxMaskToSVG(output.data, output.dims[3], output.dims[2]);
      else svgStr = traceOnnxMaskToSVG(output.data, output.dims[1], output.dims[0]);

      const activeObjects = canvas.getAnnotations();
      svgStr.forEach((svgPath) => {
        if (type !== 'box') {
          const matchingObjects = activeObjects.filter(
            (item) => item.category.id === canvas.activeCategory.id && item.type === type,
          );
          matchingObjects.forEach((obj) => {
            canvas.remove(obj);
          });
        }
        const annotItem = {
          erasable: true,
          type: type,
          category: { ...canvas.activeCategory },
          evented: false,
          fill: canvas.activeCategory.color,
        };

        let path = new fabric.Path(svgPath, annotItem); // Change the color as needed
        canvas.add(path);
      });
    };

    this.runONNX = async function () {
      try {
        if (this.model === null || this.clicks === null || this.tensor === null) return;
        else {
          // SAM 형식으로 model input 준비
          // The modelDataForClick function is from onnxModelAPI.tsx.
          if (!canvas.activeCategory) return;
          const feeds = this.modelDataForClick({
            clicks: canvas.activeCategory.maskData.clicks,
            tensor: this.tensor,
            modelScale: canvas.modelScaleForClick,
            //last_pred_mask: canvas.selectedTool.sam.predMasks,
          });

          if (feeds === undefined) return;
          // modelDataForClick()의 리턴 값인 feeds를 사용하여 SAM ONNX 모델 실행
          const results = await this.model.run(feeds);
          const output = results[this.model.outputNames[0]]; // model.outputNames[0] = 'mask' 모델을 돌린 후의 마스크 배열

          this.drawSAM('click', output);
          /**
          const svgStr = traceOnnxMaskToSVG(output.data, output.dims[3], output.dims[2]);
          const activeObjects = canvas.getAnnotations();
          svgStr.forEach((svgPath) => {
            const matchingObjects = activeObjects.filter(
              (item) => item.category.id === canvas.activeCategory.id && item.type === 'click',
            );
            console.log(matchingObjects);
            matchingObjects.forEach((obj) => {
              canvas.remove(obj);
            });
            const annotItem = {
              erasable: true,
              type: 'click',
              category: { ...canvas.activeCategory },
              evented: false,
              fill: canvas.activeCategory.color,
            };
            let path = new fabric.Path(svgPath, annotItem); // Change the color as needed
            canvas.add(path);
          });
          */
        }
      } catch (e) {
        console.log(e);
      }
    };
    // polygon
    this.polygon = {
      init: function (categories, activeCategory) {
        canvas.isDrawingMode = false;
        canvas.selectedTool.type = 'polygon';
        canvas.selectedTool.isDrawing = false;
        canvas.selectedTool.polygon = {
          activeLine: null,
          activeShape: null,
          lineArray: [],
          pointArray: [],
          isDrawing: false, // polygon 진행중인지 체크
        };
        canvas.selectedTool.ref = null;
        canvas.selection = false;
        canvas.activeCategory = activeCategory;
        canvas.categories = categories;
        //canvas.selectedTool.isDrawing = true;
      },
      start: function (categories, activeCategory) {
        canvas.stopDraw(false);
        //this.cancel();
        this.init(categories, activeCategory);
      },
      changedCategory(activeCategory) {
        // console.log('changedCategory');
        canvas.activeCategory = activeCategory;
      },

      addPoint: function (options) {
        const pointer = canvas.getPointer(options);
        const pointOption = {
          id: new Date().getTime(),
          radius: 7,
          fill: '#ffffff',
          stroke: '#333333',
          strokeWidth: 3,
          left: pointer.x,
          top: pointer.y,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          originX: 'center',
          originY: 'center',
          objectCaching: false,
        };
        const point = new fabric.Circle(pointOption);

        if (canvas.selectedTool.polygon.pointArray.length === 0) {
          // fill first point with red color
          point.set({
            fill: 'red',
            stroke: 'red',
          });
        }

        const linePoints = [pointer.x, pointer.y, pointer.x, pointer.y];
        const lineOption = {
          // 기본 line option
          strokeWidth: 2,
          fill: '#99999',
          stroke: '#99999',
          originX: 'center',
          originY: 'center',
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        };
        const line = new fabric.Line(linePoints, lineOption);
        line.class = 'line';

        if (canvas.selectedTool.polygon.activeShape) {
          // 첫번째 포인트가 생긴 이후
          const pos = canvas.getPointer(options.e);
          const points = canvas.selectedTool.polygon.activeShape.get('points');

          points.push({
            x: pos.x,
            y: pos.y,
          });
          // 기존 포인트에 추가 후 polygon 객체를 새로 만듦
          const polygon = new fabric.Polygon(points, {
            stroke: '#333333',
            strokeWidth: 1,
            fill: '#cccccc',
            opacity: 0.3,
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false,
            objectCaching: false,
          });
          // 이전 polygon 객체 제거 후 새로 추가
          canvas.remove(canvas.selectedTool.polygon.activeShape);
          canvas.add(polygon);
          canvas.selectedTool.polygon.activeShape = polygon;
          canvas.renderAll();
        } else {
          // 처음으로 포인트가 생길 때 실행
          const polyPoint = [
            {
              x: pointer.x,
              y: pointer.y,
            },
          ];
          const polygon = new fabric.Polygon(polyPoint, {
            stroke: '#333333',
            strokeWidth: 1,
            fill: '#cccccc',
            opacity: 0.3,
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false,
            objectCaching: false,
          });
          canvas.selectedTool.polygon.activeShape = polygon;
          canvas.add(polygon);
        }

        canvas.selectedTool.polygon.activeLine = line;
        canvas.selectedTool.polygon.pointArray.push(point);
        canvas.selectedTool.polygon.lineArray.push(line);

        canvas.add(line);
        canvas.add(point);
      },
      generatePolygon: function (pointArray) {
        const points = [];
        // collect points and remove them from canvas
        for (const point of pointArray) {
          points.push({
            x: point.left,
            y: point.top,
          });
          canvas.remove(point);
        }

        // remove lines from canvas
        for (const line of canvas.selectedTool.polygon.lineArray) {
          canvas.remove(line);
        }

        // remove selected Shape and Line
        canvas.remove(canvas.selectedTool.polygon.activeShape).remove(canvas.selectedTool.polygon.activeLine);

        // create polygon from collected points
        const polygon = new fabric.Polygon(points, {
          id: new Date().getTime(),
          fill: canvas.activeCategory.color,
          strokeWidth: strokeWidth,
          //stroke: canvas.activeCategory.color,
          objectCaching: false,
          moveable: false,
          selectable: false,
          erasable: false,
          category: { ...canvas.activeCategory },
          type: 'polygon',
        });
        canvas.add(polygon);

        // 값들 초기화
        canvas.selectedTool.polygon.activeLine = null;
        canvas.selectedTool.polygon.activeShape = null;
        canvas.selectedTool.polygon.lineArray = [];
        canvas.selectedTool.polygon.pointArray = [];
      },
      /**
       * define a function that can locate the controls.
       * this function will be used both for drawing and for interaction.
       */
      polygonPositionHandler: function (dim, finalMatrix, fabricObject) {
        const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
          y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
        return fabric.util.transformPoint(
          { x: x, y: y },
          fabric.util.multiplyTransformMatrices(
            fabricObject.canvas.viewportTransform,
            fabricObject.calcTransformMatrix(),
          ),
        );
      },
      /**
       * define a function that will define what the control does
       * this function will be called on every mouse move after a control has been
       * clicked and is being dragged.
       * The function receive as argument the mouse event, the current trasnform object
       * and the current position in canvas coordinate
       * transform.target is a reference to the current object being transformed,
       */
      actionHandler: function (eventData, transform, x, y) {
        var polygon = transform.target,
          currentControl = polygon.controls[polygon.__corner],
          mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
          polygonBaseSize = polygon._getNonTransformedDimensions(),
          size = polygon._getTransformedDimensions(0, 0),
          finalPointPosition = {
            x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
            y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y,
          };
        polygon.points[currentControl.pointIndex] = finalPointPosition;
        return true;
      },
      anchorWrapper: function (anchorIndex, fn) {
        return function (eventData, transform, x, y) {
          var fabricObject = transform.target,
            absolutePoint = fabric.util.transformPoint(
              {
                x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
                y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
              },
              fabricObject.calcTransformMatrix(),
            ),
            actionPerformed = fn(eventData, transform, x, y),
            newDim = fabricObject._setPositionDimensions({}),
            polygonBaseSize = fabricObject._getNonTransformedDimensions(),
            newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
            newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
          fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
          return actionPerformed;
        };
      },
      editPolygon: function () {
        let activeObject = canvas.getActiveObject();
        if (!activeObject) {
          const getObj = canvas.getObjects().filter((obj) => obj.hasOwnProperty('points'));
          activeObject = getObj[getObj.length - 1];
        }
        activeObject.edit = true;
        activeObject.objectCaching = false;

        const lastControl = activeObject.points.length - 1;
        activeObject.cornerStyle = 'circle';
        activeObject.controls = activeObject.points.reduce((acc, point, index) => {
          acc['p' + index] = new fabric.Control({
            positionHandler: this.polygonPositionHandler,
            actionHandler: this.anchorWrapper(index > 0 ? index - 1 : lastControl, this.actionHandler),
            actionName: 'modifyPolygon',
            pointIndex: index,
          });
          return acc;
        }, {});

        activeObject.hasBorders = false;
        canvas.requestRenderAll();
      },

      //cancel: function () {
      //  if (canvas.selectedTool.polygon.lines !== null && canvas.selectedTool.polygon.lines.length > 0) {
      //    canvas.selectedTool.polygon.lines.forEach(function (value) {
      //      canvas.remove(value);
      //    });
      //  }
      //  this.init();
      //},
      //  addPolyline: function (options) {
      //    const point = canvas.getPointer(options.e);
      //    if (canvas.selectedTool.polygon.roofPoints.length > 0) {
      //      if (
      //        canvas.selectedTool.polygon.roofPoints[canvas.selectedTool.polygon.roofPoints.length - 1].x === point.x &&
      //        canvas.selectedTool.polygon.roofPoints[canvas.selectedTool.polygon.roofPoints.length - 1].y === point.y
      //      ) {
      //        return;
      //      }

      //      const benchMark = canvas.getZoom() < 1 ? 2 / canvas.getZoom() : 3;
      //      if (
      //        Math.abs(canvas.selectedTool.polygon.roofPoints[0].x - point.x) <= benchMark &&
      //        Math.abs(canvas.selectedTool.polygon.roofPoints[0].y - point.y) <= benchMark
      //      ) {
      //        this.makePolygon();
      //        return;
      //      }
      //    }

      //    canvas.selectedTool.polygon.roofPoints.push({
      //      x: point.x,
      //      y: point.y,
      //    });
      //    let points = [point.x, point.y, point.x, point.y];
      //    const line = new fabric.Line(points, {
      //      strokeWidth: canvas.strokeWidth,
      //      selectable: false,
      //      stroke: canvas.strokeColor,
      //      hoverCursor: 'default',
      //    });
      //    canvas.selectedTool.polygon.lines.push(line);
      //    canvas.add(canvas.selectedTool.polygon.lines[canvas.selectedTool.polygon.lineCount]);

      //    canvas.selectedTool.polygon.lineCount++;
      //  },
      //  makePolygon: function () {
      //    if (canvas.selectedTool.polygon.roofPoints.length < 2) return;

      //    const findTopPaddingForRoof = (roofPoints) => {
      //      let result = 999999;
      //      for (let f = 0; f < canvas.selectedTool.polygon.lineCount; f++) {
      //        if (roofPoints[f].y < result) {
      //          result = roofPoints[f].y;
      //        }
      //      }
      //      return Math.abs(result);
      //    };
      //    const findLeftPaddingForRoof = (roofPoints) => {
      //      let result = 999999;
      //      for (let i = 0; i < canvas.selectedTool.polygon.lineCount; i++) {
      //        if (roofPoints[i].x < result) {
      //          result = roofPoints[i].x;
      //        }
      //      }
      //      return Math.abs(result);
      //    };

      //    const left = findLeftPaddingForRoof(canvas.selectedTool.polygon.roofPoints);
      //    const top = findTopPaddingForRoof(canvas.selectedTool.polygon.roofPoints);
      //    if (canvas.selectedTool.polygon.roofPoints.length > 0)
      //      canvas.selectedTool.polygon.roofPoints.push({
      //        x: canvas.selectedTool.polygon.roofPoints[0].x,
      //        y: canvas.selectedTool.polygon.roofPoints[0].y,
      //      });
      //    const polygon = new fabric.Polygon(canvas.selectedTool.polygon.roofPoints, {
      //      left: left,
      //      top: top,
      //      fill: brushColor,
      //      strokeWidth: strokeWidth,
      //      stroke: strokeColor,
      //      objectCaching: false,
      //      selectable: false,
      //    });

      //    canvas.drawPolygon.cancel();
      //    canvas.add(polygon);
      //    canvas.setViewportTransform(canvas.viewportTransform);
      //  },
    };

    this.runONNXForBoundingBox = async function (isMove) {
      if (!isMove) this.boundingMaskCount = 0;
      try {
        // if (this.model === null || this.clicks === null || this.tensor === null || this.modelScale === null) return;
        if (this.model === null || this.clicks === null || this.tensor === null) return;
        else {
          // SAM 형식으로 model input 준비
          // The modelData function is from onnxModelAPI.tsx.
          if (!canvas.activeCategory) return;
          // console.log('runONNX', canvas.activeCategory.maskData.clicks);
          const feeds = this.modelData({
            //clicks: canvas.activeCategory.maskData.clicks,
            boundingBox: canvas.activeCategory.maskData.boundingBox,
            tensor: this.tensor,
            modelScale: canvas.modelScale,
          });
          // console.log('feeds', feeds);
          if (feeds === undefined) return;
          // modelData()의 리턴 값인 feeds를 사용하여 SAM ONNX 모델 실행
          const results = await this.model.run(feeds);
          const output = results[this.model.outputNames[0]]; // model.outputNames[0] = 'mask' 모델을 돌린 후의 마스크 배열
          const drawType = isMove ? 'temporaryBox' : 'box';
          this.drawSAM(drawType, output);
          /** 
          // ONNX 모델에서 반환된 예상 마스크는 MaskUtils.tsx의 onnxMaskToImage()를 사용하여 HTML 이미지로 렌더링되는 배열
          const svgStr = traceOnnxMaskToSVG(output.data, output.dims[1], output.dims[0]);
          // traceOnnxMaskToSVG : onnx 모델 출력값을 단일 문자열인 SVG 데이터로 변환
          const activeObjects = canvas.getAnnotations();
          svgStr.forEach((svgPath) => {
            const matchingObjects = activeObjects.filter(
              (item) => item.category.id === canvas.activeCategory.id && item.type === 'tempSam',
            );

            matchingObjects.forEach((obj) => {
              canvas.remove(obj);
            });
            const annotItem = {
              erasable: true,
              type: isMove ? 'tempSam' : 'sam',
              category: { ...canvas.activeCategory },
              evented: false,
              fill: canvas.activeCategory.color,
            };

            let path = new fabric.Path(svgPath, annotItem); // Change the color as needed
            canvas.add(path);
          });
          */
        }
      } catch (e) {
        console.log(e);
      }
    };

    this.isFirstClick = (clicks) => {
      return (
        (clicks.length === 1 &&
          (clicks[0].clickType === clickType.POSITIVE || clicks[0].clickType === clickType.NEGATIVE)) ||
        (clicks.length === 2 &&
          clicks.every((c) => c.clickType === clickType.UPPER_LEFT || c.clickType === clickType.BOTTOM_RIGHT))
      );
    };

    this.modelData = function ({ tensor, modelScale, boundingBox }) {
      const lowResTensor = tensor;
      let pointCoords;
      let pointLabels;
      let pointCoordsTensor;
      let pointLabelsTensor;
      // Check there are input click prompts
      // if (clicks) {
      //   // console.log(clicks);
      //   let n = clicks.length;
      //   // If there is no box input, a single padding point with
      //   // label -1 and coordinates (0.0, 0.0) should be concatenated
      //   // so initialize the array to support (n + 1) points.
      //   pointCoords = new Float32Array(2 * (n + 1));
      //   pointLabels = new Float32Array(n + 1);
      //   // Add clicks and scale to what SAM expects
      //   for (let i = 0; i < n; i++) {
      //     pointCoords[2 * i] = clicks[i].x * modelScale.samScale;
      //     pointCoords[2 * i + 1] = clicks[i].y * modelScale.samScale;
      //     // console.log('modelData', pointCoords[2 * i], pointCoords[2 * i + 1]) // 현재 이미지에서의 클릭된 자표
      //     pointLabels[i] = clicks[i].clickType;
      //   }
      //   // Add in the extra point/label when only clicks and no box
      //   // The extra point is at (0, 0) with label -1
      //   pointCoords[2 * n] = 0.0;
      //   pointCoords[2 * n + 1] = 0.0;
      //   pointLabels[n] = -1;
      //   // console.log('modelData2', pointCoords[2 * n], pointCoords[2 * n + 1])
      //   // Create the tensor
      //   pointCoordsTensor = new Tensor('float32', pointCoords, [1, n + 1, 2]);
      //   pointLabelsTensor = new Tensor('float32', pointLabels, [1, n + 1]);
      // }

      let last_pred_mask = null;

      if (boundingBox) {
        let n = boundingBox.length;
        const clicksFromBox = 2;
        // If there is no box input, a single padding point with
        // label -1 and coordinates (0.0, 0.0) should be concatenated
        // so initialize the array to support (n + 1) points.
        pointCoords = new Float32Array(2 * (n + clicksFromBox));
        pointLabels = new Float32Array(n + clicksFromBox);
        const box = boundingBox[0];
        const upperLeft = { x: box.x, y: box.y };
        const bottomRight = { x: box.width, y: box.height };
        if (box.x > box.width) {
          upperLeft.x = box.width;
          bottomRight.x = box.x;
        }

        if (box.y > box.height) {
          upperLeft.y = box.height;
          bottomRight.y = box.y;
        }

        pointCoords = new Float32Array(2 * (n + clicksFromBox));
        pointLabels = new Float32Array(n + clicksFromBox);
        // pointCoords[0] = upperLeft.x / modelScale.onnxScale;
        // pointCoords[1] = upperLeft.y / modelScale.onnxScale;
        // pointLabels[0] = 2.0; // UPPER_LEFT
        // pointCoords[2] = bottomRight.x / modelScale.onnxScale;
        // pointCoords[3] = bottomRight.y / modelScale.onnxScale;
        // pointLabels[1] = 3.0; // BOTTOM_RIGHT
        pointCoords[0] = upperLeft.x;
        pointCoords[1] = upperLeft.y;
        pointLabels[0] = 2.0; // UPPER_LEFT
        pointCoords[2] = bottomRight.x;
        pointCoords[3] = bottomRight.y;
        pointLabels[1] = 3.0; // BOTTOM_RIGHT

        for (let i = 0; i < n; i++) {
          // pointCoords[2 * (i + clicksFromBox)] = boundingBox[i].x / modelScale.onnxScale;
          // pointCoords[2 * (i + clicksFromBox) + 1] = boundingBox[i].y / modelScale.onnxScale;
          pointCoords[2 * (i + clicksFromBox)] = boundingBox[i].x;
          pointCoords[2 * (i + clicksFromBox) + 1] = boundingBox[i].y;
          pointLabels[i + clicksFromBox] = boundingBox[i].type;
        }
        if (!clicksFromBox) {
          pointCoords[2 * n] = 0.0;
          pointCoords[2 * n + 1] = 0.0;
          pointLabels[n] = -1.0;
          // update n for creating the tensor
          n = n + 1;
        }
        // console.log('modelData2', pointCoords[2 * n], pointCoords[2 * n + 1])
        // Create the tensor
        pointCoordsTensor = new Tensor('float32', pointCoords, [1, n + clicksFromBox, 2]);
        pointLabelsTensor = new Tensor('float32', pointLabels, [1, n + clicksFromBox]);
      }
      const imageSizeTensor = new Tensor('float32', [modelScale.maskHeight, modelScale.maskWidth]);
      // const imageSizeTensor = new Tensor('float32', [modelScale.height, modelScale.width]);

      if (pointCoordsTensor === undefined || pointLabelsTensor === undefined) return;

      // There is no previous mask, so default to an empty tensor
      // const maskInput = new Tensor('float32', new Float32Array(256 * 256), [1, 1, 256, 256]);

      const lastPredMaskTensor =
        last_pred_mask && boundingBox && !canvas.isFirstClick(boundingBox)
          ? last_pred_mask
          : new Tensor('float32', new Float32Array(256 * 256), [1, 1, 256, 256]);

      // There is no previous mask, so default to 0
      // const hasMaskInput = new Tensor('float32', [0]);
      const hasLastPredTensor = new Tensor('float32', [
        +!!(last_pred_mask && boundingBox && !canvas.isFirstClick(boundingBox)),
      ]);
      return {
        low_res_embedding: lowResTensor,
        point_coords: pointCoordsTensor,
        point_labels: pointLabelsTensor,
        image_size: imageSizeTensor,
        last_pred_mask: lastPredMaskTensor,
        has_last_pred: hasLastPredTensor,
      };
    };

    this.modelDataForClick = function ({ clicks, tensor, modelScale }) {
      const imageEmbedding = tensor;
      let pointCoords;
      let pointLabels;
      let pointCoordsTensor;
      let pointLabelsTensor;
      // Check there are input click prompts
      if (clicks) {
        // console.log(clicks);
        let n = clicks.length;
        // If there is no box input, a single padding point with
        // label -1 and coordinates (0.0, 0.0) should be concatenated
        // so initialize the array to support (n + 1) points.
        pointCoords = new Float32Array(2 * (n + 1));
        pointLabels = new Float32Array(n + 1);
        // Add clicks and scale to what SAM expects
        for (let i = 0; i < n; i++) {
          pointCoords[2 * i] = clicks[i].x * modelScale.samScale;
          pointCoords[2 * i + 1] = clicks[i].y * modelScale.samScale;
          // console.log('modelData', pointCoords[2 * i], pointCoords[2 * i + 1]) // 현재 이미지에서의 클릭된 자표
          pointLabels[i] = clicks[i].clickType;
        }
        // Add in the extra point/label when only clicks and no box
        // The extra point is at (0, 0) with label -1
        pointCoords[2 * n] = 0.0;
        pointCoords[2 * n + 1] = 0.0;
        pointLabels[n] = -1;
        // console.log('modelData2', pointCoords[2 * n], pointCoords[2 * n + 1])
        // Create the tensor
        pointCoordsTensor = new Tensor('float32', pointCoords, [1, n + 1, 2]);
        pointLabelsTensor = new Tensor('float32', pointLabels, [1, n + 1]);
      }
      const imageSizeTensor = new Tensor('float32', [modelScale.height, modelScale.width]);

      if (pointCoordsTensor === undefined || pointLabelsTensor === undefined) return;

      // There is no previous mask, so default to an empty tensor
      const maskInput = new Tensor('float32', new Float32Array(256 * 256), [1, 1, 256, 256]);
      // There is no previous mask, so default to 0
      const hasMaskInput = new Tensor('float32', [0]);
      return {
        image_embeddings: imageEmbedding,
        point_coords: pointCoordsTensor,
        point_labels: pointLabelsTensor,
        orig_im_size: imageSizeTensor,
        mask_input: maskInput,
        has_mask_input: hasMaskInput,
      };
    };

    this.getBase64Image = function (base64, category, blendColorFilter) {
      fabric.Image.fromURL(
        base64,
        function (myImg) {
          myImg.filters.push(blendColorFilter);
          myImg.applyFilters();
          canvas.add(myImg);
        },
        { erasable: true, evented: false, category },
      );
    };
    this.magicWand = {
      init: function () {
        canvas.selectedTool.type = 'magicWand';
        canvas.isDrawingMode = false;
        canvas.selectedTool.isDrawing = false;
        canvas.magicWandClass = new fabric.MagicWand(canvas);
      },
      start: function () {
        canvas.stopDraw(false);
        this.init(canvas);
      },
      resetCanvas: function (canvas) {
        canvas.magicWandClass.resetCanvas(canvas);
      },
      onRadiusChange: function (radius) {
        canvas.magicWandClass.onRadiusChange(radius);
      },
      makeMask: function (color) {
        canvas.magicWandClass.paint(color, canvas);
      },
    };
  };
})();

Vue.use(fabric);
export default fabric;
