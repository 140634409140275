
export default {
  data() {
    return {};
  },
  computed: {
    isVisibleLnb() {
      return this.$store.getters.isShowLNB;
    },
  },
};
