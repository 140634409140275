export default async function ({ store, redirect, app }) {
  if (app.$cookies)
    if (!app.$cookies.get('x-access-mavin'))
      if (store.state.user.email)
        await store.dispatch('refreshToken').catch(async (errors) => {
          console.log(errors);
          store.dispatch('logout');
          await redirect('/');
        });
      // eslint-disable-next-line no-empty
      else {
      }
    // eslint-disable-next-line no-empty
    else {
    }
}
