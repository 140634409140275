import { render, staticRenderFns } from "./fluid.vue?vue&type=template&id=1f18884f"
import script from "./fluid.vue?vue&type=script&lang=js"
export * from "./fluid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutGlobalNavigationBar: require('/usr/src/app/client/components/layout/GlobalNavigationBar.vue').default,TransitionPage: require('/usr/src/app/client/components/TransitionPage.vue').default,CsIframe: require('/usr/src/app/client/components/CsIframe.vue').default,LayoutCopyright: require('/usr/src/app/client/components/layout/copyright.vue').default})
